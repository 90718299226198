import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { MovieDetailComponent } from './movie-detail/movie-detail.component';
import { LicencesComponent } from './licences/licences.component';
import { AccountComponent } from './account/account.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegistrationComponent } from './registration/registration.component';
import { TrialComponent } from './trial/register/trial.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResellerHomeComponent } from './reseller/reseller-home/reseller-home.component';
import { CPHomeComponent } from './cp/cp-home/cp-home.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { HomeComponent } from './home/home.component';
import { GroupVenuesComponent } from './group/group-venues/group-venues.component';
import { AdminResellersComponent } from './admin/admin-resellers/admin-resellers.component';
import { AdminCPsComponent } from './admin/admin-cps/admin-cps.component';
import { AdminAccountsComponent } from './admin/admin-accounts/admin-accounts.component';
import { AdminPlaysComponent } from './admin/admin-plays/admin-plays.component';
import { OrderListComponent } from './admin/admin-orders/order-list/order-list.component';
import { LicenceAssignComponent } from './admin/admin-orders/licence-assign/licence-assign.component';
import { ClientHomeComponent } from './client/client-home/client-home.component';
import { ClientShopComponent } from './client/client-shop/client-shop.component';
import { DashboardDetailComponent } from './dashboard/dashboard-detail/dashboard-detail.component';
import { LicenseComponent } from './dashboard/licenses/licenses.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AudioLibraryComponent } from '../app/audio-library/audio-lib.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { ProgrammeComponent } from './programme/programme.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { VideoLibraryComponent } from './video-library/video-library.component';
import {AdminControlComponent} from './admin/admin-control/admin-control.component';


const routes: Routes = [

  { path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'trial', component: TrialComponent },

  { path: 'details', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'clienthome', component: ClientHomeComponent, canActivate: [AuthGuard] },
  { path: 'shop', component: ClientShopComponent, canActivate: [AuthGuard] },
  // { path: "licences", component: LicencesComponent, canActivate: [AuthGuard] },

  { path: 'shop/products', component: ProductListComponent, canActivate: [AuthGuard] },
  { path: 'shop/product/:productId', component: ProductDetailComponent, canActivate: [AuthGuard] },
  { path: 'shop/product/:productId/movie/:movieId', component: MovieDetailComponent, canActivate: [AuthGuard] },

  { path: 'resellerhome', component: ResellerHomeComponent, canActivate: [AuthGuard] },
  { path: 'cphome', component: CPHomeComponent, canActivate: [AuthGuard] },
  // { path: "venues", component: GroupVenuesComponent, canActivate: [AuthGuard]},
  { path: 'adminhome', component: AdminHomeComponent, canActivate: [AuthGuard] },
  { path: 'adminresellers', component: AdminResellersComponent, canActivate: [AuthGuard] },
  { path: 'admincps', component: AdminCPsComponent, canActivate: [AuthGuard] },
  { path: 'adminaccounts', component: AdminAccountsComponent, canActivate: [AuthGuard] },
  { path: 'adminplays', component: AdminPlaysComponent, canActivate: [AuthGuard] },
    { path: 'adminorders', component: OrderListComponent, canActivate: [AuthGuard] },
    { path: 'admincontrol', component: AdminControlComponent, canActivate: [AuthGuard] },
    { path: 'venues', component: GroupVenuesComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'audio-library', component: AudioLibraryComponent, canActivate: [AuthGuard] },
  { path: 'video-library', component: VideoLibraryComponent, canActivate: [AuthGuard] },
  { path: 'playlists', component: PlaylistComponent, canActivate: [AuthGuard] },
  { path: 'programme', component: ProgrammeComponent, canActivate: [AuthGuard] },
  { path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: 'account' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
