import {ApplicationRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AdminService} from "../../../_services/admin.service";
import {AlertService} from "../../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Order} from "../../../_models/order";
import {Venue} from "../../../_models/venue";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {OrderLicence} from "../../../_models/order-licence";
import {OrderActionDelegate} from "../order-action.delegate";
import {Account} from "../../../_models/account";

@Component({
  selector: 'admin-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: [
    '../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
    './order-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OrderListComponent implements OnInit, OrderActionDelegate {
  public accounts : Account[];
  public resellers : Account[];
  public selectedReseller: Account;
  public selectedAccount: Account;

  public orders: Order[];
  public venues: Venue[];
  private isLoading = false;
  public pageSize = 10;
  public page = 1;
  public selectedOrder: Order;
  private highlighteddOrder: Order;
  public formGroup : FormGroup ;
  public highlightedLicenceIndex : number;
  public creatingOrder : boolean = false;

  constructor(private adminService: AdminService,
              private alert: AlertService,
              private formBuilder: FormBuilder,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.creatingOrder = false;
    this.formGroup = this.formBuilder.group({
      searchTerm: ["", [Validators.required, Validators.minLength(19), Validators.maxLength(19)]]
    });

    this.adminService.fetchAccounts(false)
      .subscribe((accounts: Account[]) => {
          this.accounts = accounts;
          this.selectedAccount = accounts[0];
          this.fetchOrders();
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });



    this.adminService.fetchResellers()
      .subscribe((resellers: Account[]) => {
          this.resellers = resellers;
          this.selectedReseller = resellers[0];
          this.fetchOrders();
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });


    this.adminService.fetchVenues()
      .subscribe((venues: Venue[]) => {
          this.venues = venues;
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });

    this.formGroup.patchValue({
      searchTerm: ""
    });
  }


  fetchOrders()
  {
    let resellerId : number = 0;
    if (this.selectedReseller != null)
      resellerId = this.selectedReseller.id;
    this.adminService.fetchOrders(resellerId)
      .subscribe((orders: Order[]) => {
          this.orders = orders;
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });

  }

  public selectOrder(order: Order) {
    // Deselect current
    if (this.selectedOrder != null && this.selectedOrder.idString === order.idString)
      this.selectedOrder = null;
    else // Select new
      this.selectedOrder = order;
    this.highlightedLicenceIndex = -1;
  }

  public formatDescription(order: Order) {
    switch (order.renditionId) {
      case 1:
        return "4K4";
        break;
      case 2:
        return "4K1";
        break;
      case 3:
        return "UHD";
        break;
      case 4:
        return "HD";
        break;

    }
  }

  public highlightOrder(order: Order) {
    this.highlighteddOrder = order;
  }

  public highlightClass(order: Order): string {
    if (order == null || this.highlighteddOrder == null)
      return "";
    if (order.idString === this.highlighteddOrder.idString)
      return "table-dark";
    return "";
  }

  public createOrder ()
  {
    this.selectedOrder = null;
    this.creatingOrder = true;
  }


  onSubmit(form : FormGroup) {
    var foundOrder : Order = null;
    var foundLicence : OrderLicence = null;
    var  orderIndex:number = 0;
    var licenceIndex: number = 0;
    var found = false;
    while (orderIndex < this.orders.length)
    {
      licenceIndex = 0;
      var order : Order = this.orders[orderIndex];
      while (licenceIndex < order.licenses.length)
      {
        var licence : OrderLicence  = order.licenses[licenceIndex];
        if (licence.code === form["searchTerm"]) {
          found = true;
          break;
        }
        licenceIndex++;
      }
      if (found) {
        break;
      }
      orderIndex++;
    }

    if (found)
    {
      let pageIndex =  Math.floor(orderIndex / this.pageSize);
      this.page = pageIndex + 1;
      this.selectedOrder = this.orders[orderIndex];
      this.highlightedLicenceIndex = licenceIndex;
    }
    else
    {
      this.selectedOrder = null;
      this.alert.error("Licence not found.");
    }
  }



  public finished(reload:boolean)
  {
    this.creatingOrder = false;
    if (reload)
    {
      this.fetchOrders();
    }

  }
}

