import {Component, OnInit} from '@angular/core';
import {AccountService} from "../_services/account.service";
import {Router} from '@angular/router';
import {AlertService} from '../_alert/alert.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../_services/validation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'amp-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  isLoading = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private account: AccountService,
              private validation: ValidationService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      accountIdentifier: ["", [Validators.required, Validators.minLength(7), this.validation.accountIdValidator(), Validators.maxLength(12)]]
    });
  }

  onSubmit(form) {
    this.isLoading = true;
    this.account.resetPassword(form.accountIdentifier)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe(
        response => {
          this.router.navigate(["/login"], {queryParams: {fromReset: true}});
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  isAccountIdentifierInvalid() {
    return this.form.get('accountIdentifier').invalid && this.form.get('accountIdentifier').touched
  }

}
