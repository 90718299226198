import {Installation} from "./installation";
export class OrderLicence {

  constructor( public id: number,
               public productRendition: string,
              public accountId: number,
              public code: string,
              public systemId: string,
              public dateCreated: Date,
               public installationId: number,
               public orderIdString: string,
               public type: number,
               public status: number,
               public defaultDuration: number,
               public expiryDate: Date,
               public ownerName: string,
               public dateActivated: Date)
  {
  }


}
