let PROTOCOL = 'http';
let DOMAIN = 'localhost';
let PORT = 8080;

let IMAGE_SERVER = 'https://www.atmosphere365.com';
let IMAGE_DIRECTORY = 'images/Production';

let CLIENT_ID = "50139dc8-0a05-4fbb-a891-da113788e27a";
let CLIENT_SECRET ="AwF/j/i0UEbta4uKwxNle7eTplMr+7n+TaFch1Y7IE842843sVCu3Z6swbBnDuM5eMcBKEqe9SpcRsytA6q+oaHFSya7NJkoAaEMTKKbCIH8eAwQo6d5MITrFXdySKqjMgs=";

let STRIPE_TEST_API_KEY = "pk_test_B3xOvVaaIBZAFxuETYut44Tn";

export const environment = {
  production: false,
  protocol: PROTOCOL,
  domain: DOMAIN,
  port: PORT,
  // server: PROTOCOL + '://' + DOMAIN + ':' + PORT,
  server: 'https://ampqa.atmosphere-media.com',

  imageServer: IMAGE_SERVER,
  imageDirectory: IMAGE_DIRECTORY,

  clientId: CLIENT_ID,
  clientSecret: CLIENT_SECRET,

  stripeAPIKey: STRIPE_TEST_API_KEY
};
