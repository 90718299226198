import {ApplicationRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Account} from "../../_models/account";
import {AdminService} from "../../_services/admin.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {ContentItemPlays} from "../../_models/contentitemplays";

@Component({
  selector: 'admin-plays',
  templateUrl: './admin-plays.component.html',
  styleUrls: ['../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
    '../admin-orders/licence-assign/licence-assign.component.scss',
    './admin-plays.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class AdminPlaysComponent implements OnInit {

  plays: ContentItemPlays[] = [];
  isLoading = false;
  sinceList: any[];
  selectedSince: any;


  constructor(private adminService: AdminService,
              private alert: AlertService,
              private translate: TranslateService) {
    this.sinceList = [
      {
        days: 0,
        description: "All Time"
      },
      {
        days: 30,
        description: "Last Month"
      },
      {
        days: 92,
        description: "Last three months"
      },
      {
        days: 365,
        description: "Last Year"
      }
    ];

  }

  ngOnInit() {
    this.selectedSince = this.sinceList[0];
    this.isLoading = true
  }


  public fetchPlays()
  {
    this.adminService.fetchContentItemPlays(this.selectedSince.days)
      .subscribe((contentItemPlays: ContentItemPlays[]) => {
          this.plays = contentItemPlays;
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });

  }
}

