// Added standalone clonedeep dependency from lodash.
// Reduce side effects of object references where relevant.
import cloneDeep from 'lodash.clonedeep';


export interface EditModeController<T> {
    startEditMode(activeObject: T): void;
    saveEditMode(editedObject: T): void;
    cancelEditMode(): void;
    isInEditMode(): boolean;
    getEditStateClassName(): string;
}

export class GenericEditModeController<T> implements EditModeController<T> {
    private _editMode: boolean;
    private _cachedObject: T;
    private readonly _saveHandler: (editedObj: T) => void;
    private readonly _cancelHandler: (cachedObj: T) => void;
    private readonly _validator: (cachedObj: T) => boolean;

    getEditStateClassName(): string {
        return (this._editMode) ? 'atmosphere-workspace--edit-mode' : '';
    }

    constructor(saveHandler, cancelHandler, validator?) {
        this._editMode = false;
        this._saveHandler = saveHandler;
        this._cancelHandler = cancelHandler;
        this._validator = validator;
    }

    startEditMode(activeObject: T): void {
        this._cachedObject = cloneDeep(activeObject);
        this._editMode = true;
    }

    saveEditMode(editedObject: T): void {
        if (this._editMode) {
            if (this._validator && this._validator(this._cachedObject) || !this._validator) {
                this._editMode = false;
                this._saveHandler(editedObject);
            }
        }
    }

    cancelEditMode(): void {
        if (this._editMode) {
            this._editMode = false;
            this._cancelHandler(this._cachedObject);
        }
    }

    isInEditMode(): boolean {
        return this._editMode;
    }
}


