import {ListItemConfig} from './list-item-config';


interface NestableVariance<T> {
    hasNestedItems(): boolean;
    getNestedItems(): Array<T>;
}


export class ListItem implements NestableVariance<ListItem> {
    // Data
    private displayText: Function;
    private readonly innerModel: any;
    private readonly config: ListItemConfig;

    // State
    protected _hasNestedItems: boolean;
    private selected: boolean;

    constructor(config, displayText, model) {
        this.config = config;

        this.displayText = displayText;
        this.innerModel = model;

        this.selected = false;
        this._hasNestedItems = false;
    }

    hasNestedItems(): boolean {
        return this._hasNestedItems;
    }

    getNestedItems(): Array<ListItem> {
        return [];
    }

    getInnerModel() {
        return this.innerModel;
    }

    isSelected(): boolean {
        return this.selected;
    }

    setSelected(selected: boolean) {
        this.selected = selected;
    }

    getDisplayText(): string {
        return this.displayText(this);
    }

    getIcons() {
        return this.config.getIcons();
    }
}

export class List extends ListItem {
    private readonly nestedItems: Array<ListItem>;

    constructor(config, displayText, model, nestedItems) {
        super(config, displayText, model);
        this.nestedItems = nestedItems;
        this._hasNestedItems = true;
    }

    hasNestedItems(): boolean {
        return this._hasNestedItems;
    }

    getNestedItems(): Array<ListItem> {
        return this.nestedItems;
    }
}

