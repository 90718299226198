import { Programme } from './programme';

export class Schedule {
  name: string;
  id: number;
  defaultSchedule: boolean;
  accountId: number;
  folderId: number;
  programmes: Array<Programme>;


  constructor(id?: number, name?: string, defaultSchedule?: boolean, accountId?: number, folderId?: number, programmes?: Array<Programme>) {
    this.id = id;
    this.name = name;
    this.defaultSchedule = defaultSchedule;
    this.accountId = accountId;
    this.folderId = folderId;
    this.programmes = programmes;
  }

  setProgrammeForDay(dayIndex: Number, programme: Programme) {
    if (this.programmes === undefined) {
      this.programmes = [];
      let i;
      for (i = 0; i < 7; i++) {
        this.programmes[i] = programme;
      }
    }
    else {
      this.programmes[dayIndex.valueOf()] = programme;
    }
  }

  public getProgrammes() {
    return this.programmes;
  }
}



export class Artist {
  public id: number;
  public name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class Channel {
  public id: number;
  public name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
