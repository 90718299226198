import { ProgramSequence } from './program-sequence';

export class Programme {
  id: number;
  name: string;
  accountId: number;
  folderId: number;
  startTime: number;
  sequences: Array<ProgramSequence>;
  constructor(id?: number, name?: string, accountId?: number, folderId?: number, sequences?: Array<ProgramSequence>) {
    this.id = id;
    this.name = name;
    this.accountId = accountId;
    this.folderId = folderId;
    this.sequences = sequences;
  }
}
