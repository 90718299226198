import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AMPFolder} from '../../_models/folder';
import {ScheduleActionMenuDelegate} from './schedule-action-menu.delegate';
import {Schedule} from '../../_models/schedule';

@Component({
    selector: 'amp-schedule-action-menu',
    templateUrl: './schedule-action-menu.component.html',
    styleUrls: ['./schedule-action-menu.component.scss']
})
export class ScheduleActionMenuComponent {

    @Input() schedule: Schedule;
    @Input() scheduleFolders: Array<AMPFolder>;
    @Input() delegate: ScheduleActionMenuDelegate;

    form: FormGroup;

    constructor(
    ) { }


    moveToFolder(folder: AMPFolder) {
        this.schedule.folderId = folder.id;

        this.delegate.onMoveScheduleToFolder(this.schedule);
    }
}
