import {Component, Input} from '@angular/core';
import {ListItem} from './models/list-item';
import {MasterViewListItemEventDelegate} from './interfaces/master-view-list-item-event-delegate';

@Component({
    selector: 'app-master-view-list',
    templateUrl: './master-view-list.component.html',
    styleUrls: ['./master-view-list.component.scss']
})
export class MasterViewListComponent {
    @Input() listItems: Array<ListItem>;
    @Input() eventDelegate: MasterViewListItemEventDelegate;
    @Input() hasTabs?: boolean;
    @Input() hasToolbar?: boolean;
}
