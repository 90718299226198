import {ListItem} from '../../../common-components/master-view-list/models/list-item';
import {PlaylistFilter} from '../../../_models/playlist-filter';
import {IPlaylistFilterView} from './iplaylist-filter-view';
import {Tag} from '../../../_models/tag';
import ListItemIconConfigFactory from '../../../common-components/master-view-list/util/list-item-config-factory';
import {TagListItemFactory} from '../../util/tags-listitem-factory';
import {ListItemConfig} from '../../../common-components/master-view-list/models/list-item-config';
import {AudioLibraryService} from '../../../_services/audio-library.service';

export class AudioTagPlaylistFilterView implements IPlaylistFilterView<Tag> {

    private _audioTags = new Array<Tag>();
    private _audioTagMap = {};
    private _dropdownItems = new Array<ListItem>();

    getFilterId(): number {
        return PlaylistFilter.AMP_FILTER_TYPE_ATTRIBUTE;
    }

    getFilterDisplayString(): string {
        return 'Attribute';
    }

    getFilterImageIcoAsset(): string {
        return 'fa-tag';
    }

    isFilterIconAssetFont(): boolean {
        return true;
    }

    isFilterIconAssetImg(): boolean {
        return false;
    }

    setFilterContent(content: Array<Tag>): void {
        const AUDIO_TAG_FILTER = ['Genre', 'Hits', 'Language', 'Mood', 'Type'];
        this._audioTags = content.filter(tag => {
            return AUDIO_TAG_FILTER.includes(tag.name);
        });

        const tagMapLoop = (tagChild => {
            this._audioTagMap[tagChild.idString] = tagChild;
            if (tagChild.children.length > 0) {
                tagChild.children.forEach(tagMapLoop);
            }
        });

        this._audioTags.forEach(tagMapLoop);

        const listItemFactory = new TagListItemFactory();
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemIcoIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            'fa-tag-ico'
        );
        const leftIcoConf = listItemIconConfigFactory.buildListItemIcoIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            'fa-tag-ico'
        );
        const listConfig = new ListItemConfig({
            leftHeading: leftHeadingIcoConf,
            left: leftIcoConf
        });

        this._dropdownItems = this._audioTags.map(audioTag => {
            return listItemFactory.buildListItem(audioTag, listConfig);
        });

    }

    getDropdownItems(): Array<ListItem> {
        return this._dropdownItems;
    }

    buildDefaultFilter(playlistId): PlaylistFilter {
        const getNextChild = (tag) =>  {
            const hasNextChild = tag.hasOwnProperty('children') && tag.children.length > 1;

            return (!hasNextChild) ? tag : getNextChild(tag.children[0]);
        };
        const firstElement = getNextChild(this._audioTags[0]);

        return new PlaylistFilter(0, playlistId, this.getFilterId(), firstElement.idString, firstElement.idString);
    }

    handleFilterUpdate(filter: PlaylistFilter, filterContent: Tag) {
        filter.filterIdentifier = filterContent.idString;
        filter.filterIdentifierAsString = filterContent.idString;
    }

    getReducedFilterContentOption(filterContent: string): {contentId: string, name: string} {
        const result = {
            contentId: '',
            name: ''
        };

        if (this._audioTagMap[filterContent]) {
            result.contentId = this._audioTagMap[filterContent].idString;
            result.name =  this._audioTagMap[filterContent].name;
        }

        return result;
    }

    getFilterIconAssetClassName() {
        return '';
    }
}
