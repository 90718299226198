import {ListItem} from '../../../common-components/master-view-list/models/list-item';
import {AudioLibraryArtistListItemFactory} from '../../../audio-library/util/audio-lib-artist-listitem-factory';
import ListItemIconConfigFactory from '../../../common-components/master-view-list/util/list-item-config-factory';
import {ListItemConfig} from '../../../common-components/master-view-list/models/list-item-config';
import {PlaylistFilter} from '../../../_models/playlist-filter';
import {IPlaylistFilterView} from './iplaylist-filter-view';
import {AudioLibraryService} from '../../../_services/audio-library.service';

export class ArtistPlaylistFilterView implements IPlaylistFilterView<{id: number, name: string}> {

    private _artists = Array<{id: number, name: string}>();
    private _artistMap = {};
    private _dropdownItems = new Array<ListItem>();

    getFilterId(): number {
        return PlaylistFilter.AMP_FILTER_TYPE_ARTIST;
    }

    getFilterDisplayString(): string {
        return 'Artist';
    }

    getFilterImageIcoAsset(): string {
        return '../../assets/img/icons_ic_audio_artist.png';
    }

    isFilterIconAssetFont(): boolean {
        return false;

    }
    isFilterIconAssetImg(): boolean {
        return true;
    }

    setFilterContent(content: Array<{ id: number; name: string }>): void {
        this._artists = content;

        content.forEach(artist => {
            this._artistMap[artist.id] = artist;
        });

        const listItemFactory = new AudioLibraryArtistListItemFactory();
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            'audio-artist-ico'
        );
        const leftIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            'audio-artist-ico'
        );

        this._dropdownItems = listItemFactory.buildListItem(content, new ListItemConfig({
            leftHeading: leftHeadingIcoConf,
            left: leftIcoConf
        })).getNestedItems();
    }

    getDropdownItems(): Array<ListItem> {
        return this._dropdownItems;
    }


    buildDefaultFilter(playlistId): PlaylistFilter {
        return new PlaylistFilter(
            0, playlistId,
            this.getFilterId(),
            `${this._artists[0].id}`,
            `${this._artists[0].id}`)
    }

    handleFilterUpdate(filter: PlaylistFilter, filterContent: {id: number, name: string}) {
        filter.filterIdentifier = `${filterContent.id}`;
        filter.filterIdentifierAsString = `${filterContent.id}`;
    }

    getReducedFilterContentOption(filterContent: string): {contentId: string, name: string} {
        return {contentId: `${this._artistMap[filterContent].id}`, name: this._artistMap[filterContent].name};
    }

    getFilterIconAssetClassName() {
        return 'artist-filter-view-image';
    }
}
