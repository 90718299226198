import {ListItemFactory} from '../../common-components/master-view-list/interfaces/list-item.factory';
import {ListItem} from '../../common-components/master-view-list/models/list-item';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';

const INSTALLATIONS_DISPLAY = 'Installations';
const PRODUCTS_DISPLAY = 'Licenced Products';

export class DashboardListItemModel {
    private readonly _elementId: string;

    constructor(elementId: string) {
        this._elementId = elementId;
    }

    get elementId(): string {
        return this._elementId;
    }
}

export class InstallationsListItemFactory implements ListItemFactory<DashboardListItemModel> {

    buildListItem(model: DashboardListItemModel, config: ListItemConfig): ListItem {
        return new ListItem(config, () => { return INSTALLATIONS_DISPLAY }, model);
    }

}

export class ProductsListItemFactory implements ListItemFactory<DashboardListItemModel> {

    buildListItem(model: DashboardListItemModel, config: ListItemConfig): ListItem {
        return new ListItem(config, () => { return PRODUCTS_DISPLAY }, model);
    }

}

