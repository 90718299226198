import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService} from "../_services/account.service";
import {Business} from "../_models/business";
import {Router} from "@angular/router";
import {ValidationService} from "../_services/validation.service";
import {AlertService} from "../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {AppComponent} from "../app.component";
import {Country} from "../_models/country";

@Component({
  selector: 'register-form',
  styleUrls: ['../../../node_modules/@ng-select/ng-select/themes/default.theme.css',  './registration.component.scss'],
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {

  form: FormGroup;
  isLoading = false;
  selectedCountry: Country;
  countries: any;

  constructor(private app: AppComponent,
            private formBuilder: FormBuilder,
              private router: Router,
              private account: AccountService,
              private validation: ValidationService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.countries = this.app.countries;

   this.selectedCountry = this.app.countryForCode("DE");

    this.form = this.formBuilder.group({
      companyName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      contactName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      address: this.formBuilder.group({
        address1: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        address2: ["", [Validators.minLength(2), Validators.maxLength(30)]],
        postcode: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        city: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        state: ["", [Validators.required]],
        country: [null, [Validators.required]]
      }),
      businessNumber: [""],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required, this.validation.emailValidator()]],
      password: this.formBuilder.group({
        password1: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
        password2: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]]
      }, {validator: this.validation.equalityValidator("password1", "password2")}),

      agreePrivacy: ["", [Validators.requiredTrue]],
      agreeEULA: ["", [Validators.requiredTrue]],
      agreeTCs: ["", [Validators.requiredTrue]]
    });

  }

  onSubmit(form) {
    let business: Business = new Business(
      form.companyName,
      form.contactName,
      form.address.address1,
      form.address.address2,
      form.address.city,
      form.address.state,
      form.address.country.code,
      form.address.postcode,
      form.businessNumber,
      form.phone,
      form.email
    );
    this.isLoading = true;
    this.account.registerAccount(business, form.password.password1, form.password.password2)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe(response => {
          this.router.navigate(['/login'], {queryParams: {fromReg: true}});
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          if (response.responseCode == 31)
          {
            this.alert.error(this.translate.instant("invalid-business-number"));
          }
          else if (response.responseCode == 32) {
            this.alert.error(this.translate.instant("business-number-check-unavailable"));
          }
          else
          {
            this.alert.error(response.responseData);
          }
        });
  }

  isPasswordGroupInvalid() {
    return this.form.get('password').hasError('notEqual') && this.form.get('password.password1').touched && this.form.get('password.password2').touched
  }

  isPassword1Invalid() {
    return this.form.get('password.password1').invalid && this.form.get('password.password1').touched
  }

  isPassword2Invalid() {
    return this.form.get('password.password2').invalid && this.form.get('password.password2').touched
  }


  onSelectedCountry(country: any) {
    this.selectedCountry = country;
  }


  openLink (key)
  {
    var link;
    this.translate.get(key).subscribe(
      data => {
        link = data;
      });
      window.open(link);
  }

  euNotGermany() {
    return this.selectedCountry.code !== 'DE' && this.app.isEuCountry(this.selectedCountry.code);
  }

}
