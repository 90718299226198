import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Util from '../../Util';
import { AudioLibraryService } from '../../_services/audio-library.service';
import { AlertService } from '../../_alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Playlist } from '../../_models/playlist';
import { ModalComponent } from '../../modal/modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { PlaylistComponent } from '../../playlist/playlist.component';
import { AudioLibraryComponent } from '../audio-lib.component';
import { MultiSelectListMediator } from 'app/common-components/master-view-list/interfaces/mulit-select-list';
import { LibraryModalDelegate } from '../library-action-menu/library-action-menu.component';
import { AudioPreviewModalComponent } from '../audio-preview-modal/audio-preview-modal.component';
import { AppComponent } from 'app/app.component';
import {ListItem} from '../../common-components/master-view-list/models/list-item';
import {Artist} from '../../_models/schedule';

@Component({
  selector: 'amp-audio-lib-detail',
  templateUrl: './audio-lib-detail.component.html',
  styleUrls: ['./audio-lib-detail.component.scss']
})
export class AudioLibraryDetailComponent implements OnInit {
  umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  };
  @Input() artists: any;
  @Input() contentItems: Array<object>;
  @Input() playlists: Array<Playlist>;
  @Input() multiSelectListMediator: MultiSelectListMediator;
  @Input() modalDelegate: LibraryModalDelegate;
  @Input() playlistListItems: Array<ListItem>;
  @Input() searchFilter: string;

  constructor(private audioLibraryService: AudioLibraryService,
    private alert: AlertService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private audioLibComp: AudioLibraryComponent,
    private app: AppComponent
  ) { }

  ngOnInit(): void {
  }

    getDuration(item) {
        const duration = item.mediaEnd - item.mediaStart;
        const u = new Util();
        return u.millisToMinutesAndSeconds(duration);
    }

  getImgForContentItem(contentItem) {
    const img = PlaylistComponent.getImgForContentItem(contentItem);
    return img;
  }

  selectItem(index) {
    this.multiSelectListMediator.selectItem(index);
  }

  isSelected(index) {
    return this.multiSelectListMediator.isSelected(index);
  }

  getSelectedItemStyle(index): string {
    return this.multiSelectListMediator.getSelectedItemStyle(index);
  }

  clickPlay(event, contentItem) {
    event.stopPropagation();

    let options: NgbModalOptions = {
      size: 'lg'
    };
    const modalRef = this.modalService.open(AudioPreviewModalComponent, options);
    modalRef.componentInstance.contentItem = contentItem;
    modalRef.componentInstance.artistName = this.artists[contentItem.artistId].name;
    const util = new Util();
    const duration = contentItem.mediaEnd - contentItem.mediaStart;
    modalRef.componentInstance.duration = util.millisToMinutesAndSeconds(duration);
  }


    // Search
    getFilterFunc() {
        return (item) => {
            // return item.name.includes(this.searchFilter) || this.artists[item.artistId].name.includes(this.searchFilter);
            return item.name.toLowerCase().includes(this.searchFilter.toLowerCase()) || this.artists[item.artistId].name.toLowerCase().includes(this.searchFilter.toLowerCase());
        };
    }

    visibleContent() {
        const content = this.contentItems;
        let listItems;

        if (this.searchFilter !== '') {
            listItems = content.filter(this.getFilterFunc());
        } else {
            listItems = content;
        }

        return listItems;
    }
}

