import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ProductService} from "../_services/product.service";
import {Product} from "../_models/product";
import {AlertService} from "../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "amp-product-detail",
  templateUrl: "./product-detail.component.html"
})
export class ProductDetailComponent implements OnInit {

  product: Product;
  isLoading = false;
  url: string = null;

  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private alert: AlertService,
              private translate: TranslateService) {

    this.product = new Product();

  }

  public ngOnInit() {
    let productId = this.route.snapshot.params["productId"];
    this.isLoading = true;
    this.productService.fetchProduct(productId)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe((product: Product) => {
          this.product = product;
          if(this.product.promoVideoId === null || this.product.promoVideoId === "") {
            this.url = null;
          }
          else {
            this.url = `https://player.vimeo.com/video/${ this.product.promoVideoId }`;
          }

        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });

  }

  public openCheckout(product: Product) {
    this.productService.openCheckout(product);
  }

  public plusVATString () {
    if (this.product.plusVAT) {
      return '(' + this.translate.instant('plusVAT') +  ')';
    }
    return '';
  }
}
