import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {
    @Input() dialogId: string;
    @Input() dialogTitle: string;
    @Input() actionName: string;
    @Input() actionHandler: Function;
}
