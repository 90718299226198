import { Component } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { AccountService } from "../_services/account.service";
import { Business } from "../_models/business";
import { Observable } from "rxjs";
import { BusinessDetailsFormParent } from "../business-details-form/business-details-form.parent";
import { MasterViewListItemController } from 'app/common-components/master-view-list/interfaces/master-view-list-item';
import { MasterViewListItemEventDelegate } from 'app/common-components/master-view-list/interfaces/master-view-list-item-event-delegate';
import { ListItem } from 'app/common-components/master-view-list/models/list-item';
import { MasterViewListMediator, NestedMasterViewListMediator } from 'app/common-components/master-view-list/interfaces/master-view-list-mediator';
import ListItemIconConfigFactory from 'app/common-components/master-view-list/util/list-item-config-factory';
import { LoginListItemFactory, BusinessListItemFactory, AccountListItemModel } from './util/account-listitem-factory';
import { DashboardListItemModel } from 'app/dashboard/util/dashboard-listitem-factory';
import { ListItemConfig } from 'app/common-components/master-view-list/models/list-item-config';

@Component({
  selector: 'amp-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements BusinessDetailsFormParent, MasterViewListItemController, MasterViewListItemEventDelegate {

  // List Control & Management
  masterViewListMediator: MasterViewListMediator;
  masterViewList: Array<ListItem>;

  public editAccount: boolean = false;
  public editLogin: boolean = false;
  public detailTitle: string;


  constructor(
    private accountService: AccountService
  ) {
    this.masterViewList = [];
    this.masterViewListMediator = new NestedMasterViewListMediator();

  }

  ngOnInit(): void {
    this.buildMasterViewList();
  }

  buildMasterViewList() {

    const loginListItemFactory = new LoginListItemFactory();
    const businessListItemFactory = new BusinessListItemFactory();

    const listItemIconConfigFactory = new ListItemIconConfigFactory();
    const loginIconConfig = listItemIconConfigFactory.buildListItemImageIconConfig(
      '../../assets/img/ic_user.png',
      '../../assets/img/ic_user_color.png',
      {},
        'user-ico'
    );
    const businessIconConfig = listItemIconConfigFactory.buildListItemImageIconConfig(
      '../../assets/img/ic_business.svg',
      '../../assets/img/ic_icons_business_color.png',
      {},
        'business-ico'
    );

    // tslint:disable-next-line:max-line-length
    const loginListItem = loginListItemFactory.buildListItem(new AccountListItemModel('login'), new ListItemConfig({
      left: loginIconConfig, leftHeader: loginIconConfig
    }));
    this.pushToMasterViewList(loginListItem);
    this.masterViewListMediator.setSelectedListItem(loginListItem, 1);

    this.pushToMasterViewList(businessListItemFactory.buildListItem(new AccountListItemModel('business'), new ListItemConfig({
      left: businessIconConfig, leftHeader: businessIconConfig
    })))

  }

  enterEditLoginMode() {
    this.editLogin = true;
    this.editAccount = false;
  }

  enterEditAccountMode() {
    this.editLogin = false;
    this.editAccount = true;
  }

  exitEditModes() {
    this.editLogin = false;
    this.editAccount = false;
  }

  cardClicked() {
    this.exitEditModes();
  }

  public loadAccount() {
    return true;
  }

  public finished() {
    this.exitEditModes();
  }

  public saveBusiness(business: Business): Observable<any> {
    return this.accountService.updateAccountDetails(business);
  }

  public updateEmail(): boolean {
    return true;
  }

  public getAccount(): Account {
    return null;
  }


  getMasterViewList(): Array<ListItem> {
    return this.masterViewList;
  }

  pushToMasterViewList(listItem): void {
    this.masterViewList.push(listItem);
  }

  listItemSelected(eventPayload: any, listItem: any) {
    this.masterViewListMediator.setSelectedListItem(listItem, eventPayload.nestedDepth);
    this.getDetailViewForListItem(listItem);
  }

  clearMasterViewList(): Array<ListItem> {
    const cachedList = this.masterViewList;
    this.masterViewList = [];
    return cachedList;
  }

  getDetailViewForListItem(listItem): void {
    this.openTab(listItem.getInnerModel().elementId);
  }

  openTab(tabName) {
    let i, tabcontent;
    tabcontent = document.getElementsByClassName("dashboard-detail-view-content");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    document.getElementById(tabName).style.display = "block";
  }



}
