import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import Util from '../../Util';
import { AudioLibraryService } from '../../_services/audio-library.service';
import { AlertService } from '../../_alert/alert.service';
import { PlaylistComponent } from '../../playlist/playlist.component';
import { Schedule } from 'app/_models/schedule';
import { Account } from 'app/_models/account';
import { Programme } from 'app/_models/programme';
import {AMPFolder} from '../../_models/folder';
import {processProgrammeCollection} from '../../programme/util/programme-listitem-factory';

@Component({
  selector: 'amp-schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrls: ['./schedule-detail.component.scss']
})
export class ScheduleDetailComponent implements OnInit {

  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  @Input() schedule: Schedule;
  @Input() programmeFolders: Array<AMPFolder>;

  _programs: Array<Programme> = [];

  programmeListItems;

  @Input()
  set programs(programs: Array<Programme> ) {
        console.log(programs);
        console.log(this.programmeFolders);
        this._programs = programs;
        this.programmeListItems = processProgrammeCollection(this.programmeFolders, programs);
  }

  @Input() editMode: boolean;
  @Output() updateSchedule: EventEmitter<any> = new EventEmitter<any>();

  constructor(private audioLibraryService: AudioLibraryService,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {
  }

  programSelected(selectedProgram, dayIndex) {
    this.schedule.programmes[dayIndex] = selectedProgram;
    const clonedSchedule = {
      ...this.schedule,
      programmes: [...this.schedule.programmes]
    };

    // console.log(clonedSchedule);

    this.updateSchedule.emit(clonedSchedule);
  }

  getDuration(millisecs) {
    const u = new Util();
    return u.millisToMinutesAndSeconds(millisecs)
  }

  getImgForContentItem(contentItem) {
    const img = PlaylistComponent.getImgForContentItem(contentItem);
    return img;
  }

  isToday(day) {
    var weekdays = new Array(
      "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    );
    return weekdays[new Date().getDay()] == day;
  }

  handleProgrammeDropdownClick(listItem, dayIndex) {
      this.programSelected(listItem.getInnerModel(), dayIndex);
  }
}

