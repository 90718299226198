import {OrderLicence} from "../../../_models/order-licence";
import Util from "../../../Util";
import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {AdminService} from "../../../_services/admin.service";
import {BusinessDetailsFormParent} from "../../../business-details-form/business-details-form.parent";
import {OrderActionDelegate} from "../order-action.delegate";
import {Venue} from "../../../_models/venue";
import {Account} from "../../../_models/account";
import {Installation} from "../../../_models/installation";
import {Order} from "../../../_models/order";
import {AlertService} from "../../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'licence-assign',
  templateUrl: './licence-assign.component.html',
  styleUrls: ['../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css', './licence-assign.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LicenceAssignComponent implements OnInit, OnChanges {

  public selectedAccount : Account;
  public selectedVenue : Venue;
  public selectedInstallation : Installation;

  public installations: Installation[];
  public accountVenues : Venue[];

  @Input()
  public accounts : Account[];


  @Input()
  public venues : Venue[];

  @Input()
  delegate: OrderActionDelegate;

  @Input()
  public licence: OrderLicence;


  constructor(private adminService: AdminService,
              private alert: AlertService,
              private translate: TranslateService) {}

  ngOnInit() {
  }

  ngOnChanges()
  {
    if (this.accounts != null)
    {
      if (this.licence.accountId > 0)
      {
        for (let account of this.accounts)
        {
          if (this.licence.accountId == account.id)
          {
            this.selectedAccount = account;
            break;
          }
        }
      }
      else
        this.selectedAccount = this.accounts[0];
    }

    if (this.venues != null)
    {
      this.filterVenues();
      // this.filterInstallations();
    }
  }


  public cancel()
  {
    this.delegate.finished(false);
  }

  public assignLicence()
  {
    var installationId =  (this.selectedInstallation == null ? 0 : this.selectedInstallation.id);

    this.adminService.assignLicence(this.licence.code, this.selectedAccount.accountId, installationId)
      .finally(() => {
        this.delegate.finished(true);
       })
      .subscribe(response => {
          this.alert.success(this.translate.instant("licence-was-assigned"));
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  public filterVenues()
  {
    var i : number = 0;
    this.accountVenues = [];
    for (let venue of this.venues) {
      if (venue.parentId == this.selectedAccount.id)
      {
        this.accountVenues[i++] = venue;
      }
    }
      this.selectedVenue = null;
    this.filterInstallations();
  }

  public filterInstallations() {
    if (this.selectedVenue != null)
    {
      this.installations = this.selectedVenue.installations;
    }
    else {
      this.installations = null;
    }
    this.selectedInstallation = null;
   }
}

