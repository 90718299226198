import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Artist} from '../../_models/schedule';
import {LibrarySelectionDelegate} from '../library-delegate';

@Component({
  selector: 'amp-audio-library-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['../audio-lib.component.scss']
})
export class CategoriesComponent implements OnInit, OnChanges {
    @Input() type: Array<object>;
    @Input() genre: Array<object>;
    @Input() mood: Array<object>;
    @Input() artists: Artist[];
    @Input() delegate: LibrarySelectionDelegate;

  @Output() selectedCategoryContent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}


    ngOnInit() {
    }

    ngOnChanges() {
    }

    itemSelected(event) {
      this.delegate.libraryItemSelected(event);
    }
  emitContentToShow(content) {
    this.selectedCategoryContent.emit(content);
  }

}

