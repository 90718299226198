import {Injectable} from '@angular/core';
import {ProductService} from './product.service';

const STORAGE_KEY = 'product_content_filter';

@Injectable()
export class ProductFilterService {
    private products = [];
    public productFilters = [];

    constructor(
        private productService: ProductService,
    ) {
        this.loadProducts();
    }

    public getProducts() {
        return this.products;
    }

    public getProductsForMediaType(mediaType) {
        return this.getProducts().filter(p => p.mediaType === mediaType);
    }

    public parseStorageItems() {
        const filtersRaw = localStorage.getItem(STORAGE_KEY);
        if (!!filtersRaw) {
            const filters = JSON.parse(filtersRaw);
            if (filters.length > 0) {
                this.productFilters = this.productFilters.filter(pf => filters.includes(pf.contentBundleId));
            }
        } else {
            this.apply();
        }
    }

    public loadProducts() {
        return this.productService.fetchProducts().toPromise()
            .then(res => {
                this.products = res;
                this.productFilters = this.products.map(p => p);
                this.parseStorageItems();
            });
    }

    public isActiveProduct(product) {
        return this.productFilters.find(p => p.contentBundleId === product.contentBundleId);
    }


    public getProductFiltersForMediaType(mediaType) {
        return this.productFilters.filter(pf => pf.mediaType === mediaType)
    }

    public getProductIdsForMediaType(mediaType) {
        return this.getProductFiltersForMediaType(mediaType).map(pf => pf.contentBundleId);
    }

    public addProductFilter(product) {
        if (this.isActiveProduct(product)) {
            console.log('removing', product.contentBundleId);
          this.productFilters = this.productFilters.filter(pf => pf.contentBundleId !== product.contentBundleId);
        } else {
            this.productFilters.push(product);
        }
    }

    public apply() {
        console.log(this.productFilters);
        const contentBundles = this.productFilters.map(pf => pf.contentBundleId);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(contentBundles));
    }

    public cancel() {
        const filtersRaw = localStorage.getItem(STORAGE_KEY);
        if (!!filtersRaw) {
            const filters = JSON.parse(filtersRaw);
            if (filters.length > 0) {
                this.productFilters = this.products.filter(pf => filters.includes(pf.contentBundleId));
            }
        } else {
            this.productFilters = this.products.map(p => p);
        }
    }
}

// migrated to use products not content ids in filters.. not great? ........ component still alrgely content id based.
