import {ApplicationRef, Component, OnInit} from '@angular/core';
import {Licence} from "../../_models/licence";
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Installation} from "../../_models/installation";
import {ResellerLicences} from "../../_models/reseller-licences";

@Component({
  selector: 'cp-licences',
  templateUrl: './cp-licences.component.html',
  styleUrls: ['./cp-licences.component.scss']
})

export class CPLicencesComponent implements OnInit {

  resellerLicences: ResellerLicences[];
  isLoading = false;
  installationsMap = {};
  totalLicenceCount = 0;

  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true
    this.accountService.fetchResellerLicences()
      .subscribe((al: ResellerLicences[]) => {
          this.initialiseFromLicences (al);
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  public getLicenceStatus(licence: Licence): string {
    switch (licence.licenceStatus) {
      case 2:
        return "unassigned";
      case 3:
        return "awaiting-activation";
      case 4:
        return "active";
      case 5:
        return "expired";
      case 6:
        return "expired: awaiting re-activation";
      case 7:
        return "expired: re-activation enabled";
      default:
        return "undefined";
    }
  }


  public getLicenceStatusClass(licence: Licence): string {
    switch (licence.licenceStatus) {
      case 2:
        return "badge badge-default";
      case 3:
        return "badge badge-warning";
      case 4:
        return "badge badge-success";
      case 5:
        return "badge badge-danger";
      case 6:
      case 7:
        return "badge badge-warning";
      default:
        return "badge badge-default";
    }
  }

  private initialiseFromLicences (accountLicences : ResellerLicences[]) {
    this.installationsMap = {};
    // this.installations = [];
    //
    // var installations = this.extractInstallations (licences[0]);
    // this.setLicenceSelectionState (accountLicences[0], installations);

    this.resellerLicences = accountLicences;
    this.totalLicenceCount = 0;
    for (let rl of  accountLicences)
    {
       this.totalLicenceCount += rl.installations.length;
    }
    // this.installations = installations;
  }

}
