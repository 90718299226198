import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from "rxjs/Observable";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Account} from "../_models/account";
import {AccountService} from "../_services/account.service";
import {AccountListResponse} from "../_rest/account-list-response";
import "rxjs/add/observable/of";
import {ContentItemPlays} from "../_models/contentitemplays";
import {ContentItemPlaysListResponse} from "../_rest/contentitemplayslist-response";
import {Business} from "../_models/business";
import {Order} from "../_models/order";
import {Venue} from "../_models/venue";
import {OrderListResponse} from "../_rest/orderlist-response";
import {VenueListResponse} from "../_rest/venuelist-response";
import {AdminProductListResponse} from "../_rest/admin-product-ist-response";
import {AdminProduct, ProductRendition} from "../_models/admin-product";

export enum LicenceAction {
    Expire = 1,
    Renew,
    Extend
}

@Injectable()
export class AdminService {

    private adminBasePath: String = `${environment.server}/ampapi/admin`;
    private damBasePath: String = `${environment.server}/ampapi/dam`;

    constructor(private http: HttpClient,
                private accountService: AccountService) {
    }

    public getHeaders(): HttpHeaders {
        let headers = null
        if (this.accountService.token) {
            headers = new HttpHeaders({
                "Content-Type": "application/json",
                "client_id": environment.clientId,
                "client_secret": environment.clientSecret,
                "token": this.accountService.token
            })
        } else {
            headers = new HttpHeaders({
                "Content-Type": "application/json",
                "client_id": environment.clientId,
                "client_secret": environment.clientSecret,
            })
        }

        return headers;
    }


    public fetchResellers(): Observable<Account[]> {
        let url = this.adminBasePath + '/reseller';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<AccountListResponse>) => (<AccountListResponse>response.body).responseData);
    }


    public fetchContentProviders(): Observable<Account[]> {
        let url = this.adminBasePath + '/cp/s';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<AccountListResponse>) => response.body.responseData);
    }


    public fetchContentItemPlays(days: number): Observable<ContentItemPlays[]> {
        const url = this.adminBasePath + '/ampevents/contentitemplays/' + days;
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<ContentItemPlaysListResponse>) => response.body.responseData);
    }


    public libraryUpdated(): Observable<{}> {
        const url = this.adminBasePath + '/library/updated';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => response.body.responseData);
    }


    public updateResellerDetails(business: Business): Observable<any> {
        let url = this.adminBasePath + '/reseller';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};
        return this.http.post(url, business, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public updateContentProviderDetails(business: Business): Observable<any> {
        let url = this.adminBasePath + '/cp';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};
        return this.http.post(url, business, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }


    public fetchOrders(resellerId: number): Observable<Order[]> {
        let url = this.adminBasePath + '/order/' + resellerId;
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};
        return this.http.get(url, options)
            .map((response: HttpResponse<OrderListResponse>) => response.body.responseData);
    }


    public fetchProducts(): Observable<AdminProduct[]> {
        let url = this.damBasePath + '/product/s/false';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<AdminProductListResponse>) => response.body.responseData);
    }

    public fetchAccounts(includeTrial: boolean): Observable<Account[]> {
        let url = this.adminBasePath + '/accounts/' + includeTrial;
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<AccountListResponse>) => response.body.responseData);
    }

    public fetchVenues(): Observable<Venue[]> {
        let url = this.adminBasePath + '/venue';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<VenueListResponse>) => response.body.responseData);
    }


    public assignLicence(licenceCode: string, accountId: string, installationId: number): Observable<any> {
        let url = this.adminBasePath + '/license/assign';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        let postData = {
            licenseCode: licenceCode,
            accountId: accountId,
            installationId: installationId
        };

        return this.http.post(url, postData, options)
            .map( (response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }


    public executeLicenceAction(licenceCode: string, action: LicenceAction): Observable<any> {
        let url: string;
        switch (action) {
            case LicenceAction.Expire:
                url = this.adminBasePath + '/license/expire';
                break;
            case LicenceAction.Renew:
                url = this.adminBasePath + '/license/enablereactivation';
                break;
            default:
                url = this.adminBasePath + '/license/enablereactivation';
                break;
        }
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        let postData = {
            licenseCode: licenceCode
        };

        return this.http.post(url, postData, options)
            .map( (response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }


    public createOrder(productRenditionCode: string, reseller: string, licenseCount: number, licenseType: number, licenseDuration: number, description: string): Observable<any> {
        let url: string = this.adminBasePath + '/order';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        let postData = {
            productRenditionCode: productRenditionCode,
            resellerName: reseller,
            licenseCount: licenseCount,
            licenseType: licenseType,
            licenseDuration: licenseDuration,
            description: description
        };

        return this.http.post(url, postData, options)
            .map( (response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public updateOrder(orderId: string, orderDescription: string): Observable<any> {
        let url: string = this.adminBasePath + '/order/update/' + orderId;
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        let postData = {
            description: orderDescription
        };

        return this.http.post(url, postData, options)
            .map( (response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public updateTrialStatus(accountId: string, trial: boolean): Observable<any> {
        let url: string = this.adminBasePath + '/updatetrial';
        let options = {headers: this.getHeaders(), observe: 'response' as 'response'};

        let postData = {
            account: accountId,
            trial: trial
        };

        return this.http.post(url, postData, options)
            .map( (response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

}
