import {ListItem} from '../../../common-components/master-view-list/models/list-item';
import {PlaylistFilter} from '../../../_models/playlist-filter';
import {IPlaylistFilterView} from './iplaylist-filter-view';
import {Tag} from '../../../_models/tag';
import ListItemIconConfigFactory from '../../../common-components/master-view-list/util/list-item-config-factory';
import {TagListItemFactory} from '../../util/tags-listitem-factory';
import {ListItemConfig} from '../../../common-components/master-view-list/models/list-item-config';

// TODO: FIXME: Could probably make a generic tag filterview and swap out the tag filters
export class VideoTagPlaylistFilterView implements IPlaylistFilterView<Tag> {

    private _videoTags = Array<Tag>();
    private _videoTagMap = {};
    private _dropdownItems = new Array<ListItem>();

    getFilterId(): number {
        return PlaylistFilter.AMP_FILTER_TYPE_ATTRIBUTE;
    }

    getFilterDisplayString(): string {
        return 'Attribute';
    }

    getFilterImageIcoAsset(): string {
        return 'fa-tag';
    }

    isFilterIconAssetFont(): boolean {
        return true;
    }

    isFilterIconAssetImg(): boolean {
        return false;
    }

    setFilterContent(content: Array<Tag>): void {

        this._videoTags = content;

        const tagMapLoop = (tagChild => {
            this._videoTagMap[tagChild.idString] = tagChild;
            if (tagChild.children.length > 0) {
                tagChild.children.forEach(tagMapLoop);
            }
        });

        this._videoTags.forEach(tagMapLoop);

        const listItemFactory = new TagListItemFactory();
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemIcoIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            'fa-tag-ico'
        );
        const leftIcoConf = listItemIconConfigFactory.buildListItemIcoIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            'fa-tag-ico'
        );
        const listConfig = new ListItemConfig({
            leftHeading: leftHeadingIcoConf,
            left: leftIcoConf
        });

        this._dropdownItems = this._videoTags.map(videoTag => {
            return listItemFactory.buildListItem(videoTag, listConfig);
        });

    }

    getDropdownItems(): Array<ListItem> {
        return this._dropdownItems;
    }

    buildDefaultFilter(playlistId): PlaylistFilter {
        return new PlaylistFilter(0, playlistId, this.getFilterId(), this._videoTags[0].children[0].idString, this._videoTags[0].children[0].idString);
    }

    handleFilterUpdate(filter: PlaylistFilter, filterContent: Tag) {
        filter.filterIdentifier = filterContent.idString;
        filter.filterIdentifierAsString = filterContent.idString;
    }

    getReducedFilterContentOption(filterContent: string): {contentId: string, name: string} {
        const result = {
            contentId: '',
            name: ''
        };

        if (this._videoTagMap[filterContent]) {
            result.contentId = this._videoTagMap[filterContent].idString;
            result.name =  this._videoTagMap[filterContent].name;
        }

        return result;
    }

    getFilterIconAssetClassName() {
        return '';
    }
}
