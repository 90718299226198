import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Licence} from '../_models/licence';
import {AlertService} from '../_alert/alert.service';
import {AccountService} from '../_services/account.service';
import {TranslateService} from '@ngx-translate/core';
import {Installation} from '../_models/installation';
import {RenditionService} from '../_services/rendition.service';
import {formatDate} from "@angular/common";

@Component({
    selector: 'amp-license-modal',
    templateUrl: './license-modal.component.html',
    styleUrls: ['./license-modal.component.scss']
})
export class LicenseModalComponent implements OnInit {
    @Input() product: any;
    @Input() productImage: string;
    @Input() licenses: Array<Licence>;

    installations: Installation[] = [];
    installationsMap = {};
    venues = [];
    selectedVenueIndex = -1;

    constructor(
        private account: AccountService,
        private activeModal: NgbActiveModal,
                private accountService: AccountService,
                private renditions: RenditionService,
                private alert: AlertService,
                private translate: TranslateService
    ) {
    }


    getRenditions() {
        return this.product.renditions.map((productRendition) => {
            return this.renditions.get(productRendition.renditionId);
        });
    }

    ngOnInit(): void {
        this.initialiseFromLicences(this.licenses);
    }

    // TODO: Confirm done as server-sync and X as cancel
    close() {
        this.activeModal.close(this);
    }

    public selectVenue(index) {
        if (this.selectedVenueIndex === index) {
            this.selectedVenueIndex = -1;
        } else {
            this.selectedVenueIndex = index;
        }
    }

    getDisplayName() {
        const productTranslation = this.product.translations.find(translation => translation.language === this.account.getLanguage());
        return (productTranslation) ? productTranslation.displayName : this.product.name;
    }

    public getVenues() {
        return Object.values(this.licenses.reduce((accum, license) => {
            const venue = accum[license.venueName];
            if (venue) {
                venue.licenses.push(license);
            } else {
                accum[license.venueName] = {
                    name: license.venueName,
                    licenses: [license]
                }
            }
            return accum;
        }, {}));
    }


    onSelectedInstallation(licence : Licence, install) {
        //this.isLoading = true;
        licence.selectedInstallation = install;
        this.accountService.assignLicence(licence.licenceCode, install.id)
            .subscribe((licences: Licence[]) => {
                    this.initialiseFromLicences(licences);
                },
                error => {
                    if (error.status === 0) {
                        this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
                        return;
                    }
                    const response = JSON.parse(error.text());
                    this.alert.error(response.responseData);
                });
    }

    private initialiseFromLicences (licences : Licence[]) {
        this.installationsMap = {};
        this.installations = [];
        const installations = this.extractInstallations(licences);
        this.setLicenceSelectionState(licences, installations);

        this.installations = installations;
        this.venues = this.getVenues();
        console.log(this.venues);
    }

    public getLicenceStatus(licence: Licence): string {
        switch (licence.licenceStatus) {
            case 2:
                return "unassigned";
            case 3:
                return "awaiting-activation";
            case 4:
                return "active";
            case 5:
                return "expired";
            case 6:
                return "awaiting re-activation";
            case 7:
                return "re-activation enabled";

            default:
                return "undefined";
        }
    }

    public getLicenceStatusClass(licence: Licence): string {
        switch (licence.licenceStatus) {
            case 1:
            case 2:
                return "badge badge-light";
            case 3:
                return "badge badge-info";
            case 4:
                return "badge badge-success";
            case 5:
                return "badge badge-danger";
            case 6:
                return "badge badge-primary";
            case 7:
                return "badge badge-warning";
            default:
                return "badge badge-light";
        }
    }

    private extractInstallations (licences : Licence[]) : Array<Installation> {
        const  noInstallation : Installation = new Installation(0, "")
        const installations = [ noInstallation ];
        let j = 1;
        for (let i = 0; i < licences.length; i++) {
            const l = licences[i];
            if (l.installationId === 0) {
                continue;
            }
            if (this.installationsMap[l.installationName] == null) {
                const installation : Installation  = new Installation(l.installationId, l.installationName);
                this.installationsMap[l.installationName] = installation;
                installations[j++] = installation;
            }
        }
        return installations;
    }

    private setLicenceSelectionState (licences : Licence[], installations : Installation[]) {
        for (let i = 0; i < licences.length; i++) {
            const  l = licences[i];
            if (l.installationId !== 0) {
                l.selectedInstallation = this.installationsMap[l.installationName];
            }
            l.disabled = l.licenceStatus >= 4;
        }
    }

    getDate(timestamp) {
        return formatDate(timestamp, "dd/MM/yyyy", "en-IE")
    }
}

