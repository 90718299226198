import {ApplicationRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Licence} from "../_models/licence";
import {AccountService} from "../_services/account.service";
import {AlertService} from "../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Installation} from "../_models/installation";

@Component({
  selector: 'amp-licences',
  templateUrl: './licences.component.html',
  styleUrls: ['./licences.component.scss',
    '../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
    '../admin/admin-orders/licence-assign/licence-assign.component.scss',
  ]
})
export class LicencesComponent implements OnInit {

  licences: Licence[];
  installations: Installation[] = [];
  isLoading = false;
  installationsMap = {};


  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true
    this.accountService.fetchLicences()
      .subscribe((licences: Licence[]) => {
          this.initialiseFromLicences (licences);
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  public getLicenceStatus(licence: Licence): string {
    switch (licence.licenceStatus) {
      case 2:
        return "unassigned";
      case 3:
        return "awaiting-activation";
      case 4:
        return "active";
      case 5:
        return "expired";
      case 6:
        return "awaiting re-activation";
      case 7:
        return "re-activation enabled";

      default:
        return "undefined";
    }
  }

  public getLicenceStatusClass(licence: Licence): string {
    switch (licence.licenceStatus) {
      case 1:
      case 2:
        return "badge badge-light";
      case 3:
        return "badge badge-info";
      case 4:
        return "badge badge-success";
      case 5:
        return "badge badge-danger";
      case 6:
        return "badge badge-primary";
      case 7:
        return "badge badge-warning";
      default:
        return "badge badge-light";
    }
  }

  private initialiseFromLicences (licences : Licence[]) {
    this.installationsMap = {};
    this.installations = [];
    var installations = this.extractInstallations (licences);
    this.setLicenceSelectionState (licences, installations);

    this.licences = licences;
    this.installations = installations;
  }

  onSelectedInstallation( licence : Licence) {
    this.isLoading = true;
    this.accountService.assignLicence (licence.licenceCode, licence.selectedInstallation.id)
      .subscribe((licences: Licence[]) => {
          this.initialiseFromLicences (licences);
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  private extractInstallations (licences : Licence[]) : Array<Installation> {
    let  noInstallation : Installation = new Installation(0, "")
    var installations = [ noInstallation ];
    var j = 1;
    for (var i = 0; i < licences.length; i++) {
      var  l = licences[i];
      if (l.installationId == 0) {
        continue;
      }
      if (this.installationsMap[l.installationName] == null)
      {
        var installation : Installation  = new Installation(l.installationId, l.installationName);
        this.installationsMap[l.installationName] = installation;
        installations[j++] = installation;
      }
    }
    return installations;
  }

  private setLicenceSelectionState (licences : Licence[], installations : Installation[]) {
    for (var i = 0; i < licences.length; i++) {
      var  l = licences[i];
      if (l.installationId != 0) {
         l.selectedInstallation = this.installationsMap[l.installationName];
      }
      l.disabled = l.licenceStatus >= 4;
    }
  }


}
