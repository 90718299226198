import {ApplicationRef, Component, OnInit} from '@angular/core';
import {AccountService} from "../_services/account.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {


  constructor( private router: Router,
              private route: ActivatedRoute,
              private account: AccountService) {
  }


  ngOnInit()
  {
    if (this.account.isAdmin()) {
      this.router.navigateByUrl("adminhome");
      return;
    }
    if (this.account.isReseller()) {
      this.router.navigateByUrl("resellerhome");
      return;
    }
    if (this.account.isContentProvider()) {
      this.router.navigateByUrl("cphome");
      return;
    }

    this.router.navigateByUrl("clienthome");
  }

}
