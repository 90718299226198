import {Component} from '@angular/core';
import {ProductService} from '../../_services/product.service';
import {AccountService} from '../../_services/account.service';
import {Installation} from '../../_models/installation';
import {Licence} from '../../_models/licence';
import {LicenseModalComponent} from '../../licences/license-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'amp-dashboard-detail-products',
    templateUrl: './dashboard-detail-products.component.html',
    styleUrls: ['./dashboard-detail-products.component.scss'],
})
export class DashboardDetailProductsComponent {

    installedLicenses: Array<Licence> = [];

    products: Array<{
        name: string
    }> = [];

    constructor(
        private productService: ProductService,
        private accountService: AccountService,
        private modalService: NgbModal
        ) {
        this.getProducts();
        this.getLicenses();
    }

    getProducts() {
        return this.productService.fetchProducts().toPromise().then(res => {
            this.products = res;
            return this.products;
        });
    }

    getLicenses() {
        return this.accountService.fetchLicences().toPromise().then(res => {
            this.installedLicenses = res;
            return this.installedLicenses;
        });
    }

    getInstallationsForProduct(product) {
        return this.installedLicenses.filter(license => license.productName === product.name);
    }

    getProductsCount() {
        return this.products.length;
    }

    openLicenseModal(product) {
        // @ts-ignore
        const modalRef = this.modalService.open(LicenseModalComponent, {
            centered: true,
            size: 'lg',
            windowClass: 'license-modal-window'
        });
        modalRef.componentInstance.product = product;
        modalRef.componentInstance.productImage = this.getProductImage(product);
        modalRef.componentInstance.licenses = this.getInstallationsForProduct(product)
            .sort((lic1, lic2) => {
            const i1FirstChar = lic1.venueName[0];
            const i2FirstChar = lic2.venueName[0];
            const i1BeforeI2 = i1FirstChar < i2FirstChar;
            const isEqual = lic1.venueName === lic2.venueName;

            return (!isEqual && !i1BeforeI2) ? 1 : -1;
        });
    }

    getProductImage(product) {
        return this.productService.posterImage(product.name);
    }
}

