import {ApplicationRef, Component, OnInit} from '@angular/core';
import {Licence} from "../../_models/licence";
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Installation} from "../../_models/installation";
import {ResellerLicences} from "../../_models/reseller-licences";
import {ResellerLicencesComponent} from "../reseller-licences/reseller-licences.component";

@Component({
  selector: 'reseller-home',
  templateUrl: './reseller-home.component.html'
})
export class ResellerHomeComponent implements OnInit {

  resellerLicences: ResellerLicences[];
  isLoading = false;
  installationsMap = {};


  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true;
  }
}
