import { BrowserModule } from "@angular/platform-browser";
import { ElementRef, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule  } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateModule } from "@ngx-translate/core";

import { AppComponent } from "./app.component";
import { ProductListComponent } from "./product-list/product-list.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { MovieDetailComponent } from "./movie-detail/movie-detail.component";
import { ProductService } from "./_services/product.service";
import { LicencesComponent } from './licences/licences.component';
import { AccountComponent } from './account/account.component';
import { LoginComponent } from './login/login.component';
import { AccountService } from "./_services/account.service";
import { AdminService } from "./_services/admin.service";
import { AlertService } from "./_alert/alert.service";
import { AuthGuard } from "./_guards/auth.guard";
import { BusinessDetailsComponent } from "./account/business-details/business-details.component";
import { RegistrationComponent } from "./registration/registration.component";
import { TrialComponent } from "./trial/register/trial.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { BusinessDetailsFormComponent } from "./business-details-form/business-details-form.component";
import { LoginDetailsComponent } from "./account/login-details/login-details.component";
import { LoginDetailsFormComponent } from "./login-details-form/login-details-form.component";
import { ValidationService } from "./_services/validation.service";
import { AlertComponent } from "./_alert/alert.component";
import { FormEntryErrorsComponent } from './form-entry-errors/form-entry-errors.component';
import { SafePipe } from "./_directives/safe-pipe";
import { ResellerHomeComponent } from "./reseller/reseller-home/reseller-home.component";
import { ResellerLicencesComponent } from "./reseller/reseller-licences/reseller-licences.component";
import { AdminHomeComponent } from "./admin/admin-home/admin-home.component";
import { HomeComponent } from "./home/home.component";
import { GroupVenuesComponent } from "./group/group-venues/group-venues.component";
import { AdminTabsComponent } from "./admin/admin-tabs/admin-tabs.component";
import { ClientTabsComponent } from "./client/client-tabs/client-tabs.component";
import { ClientHomeComponent } from "./client/client-home/client-home.component";
import { ClientShopComponent } from "./client/client-shop/client-shop.component";
import { ResellerTabsComponent } from "./reseller/reseller-tabs/reseller-tabs.component";
import { CPTabsComponent } from "./cp/cp-tabs/cp-tabs.component";
import { CPHomeComponent } from "./cp/cp-home/cp-home.component";
import { AdminResellersComponent } from "./admin/admin-resellers/admin-resellers.component";
import { AdminCPsComponent } from "./admin/admin-cps/admin-cps.component";
import { AdminAccountsComponent } from "./admin/admin-accounts/admin-accounts.component";
import { OrderListComponent } from "./admin/admin-orders/order-list/order-list.component";
import { OrderDetailComponent } from "./admin/admin-orders/order-detail/order-detail.component";
import { OrderEditComponent } from "./admin/admin-orders/order-edit/order-edit.component";
import { OrderCreateComponent } from "./admin/admin-orders/order-create/order-create.component";
import { AdminPlaysComponent } from "./admin/admin-plays/admin-plays.component";
import { AdminControlComponent } from './admin/admin-control/admin-control.component';
import { LicenceAssignComponent } from "./admin/admin-orders/licence-assign/licence-assign.component";
import { LicenceRenewComponent } from "./admin/admin-orders/licence-renew/licence-renew.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import * as moment from 'moment';
import { CPLicencesComponent } from "./cp/cp-licences/cp-licences.component";
import { DashboardDetailComponent } from './dashboard/dashboard-detail/dashboard-detail.component';
import { LicenseComponent } from './dashboard/licenses/licenses.component';
import { ModalComponent } from './modal/modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AudioLibraryComponent } from '../app/audio-library/audio-lib.component';
import { AudioLibraryDetailComponent } from './audio-library/audio-lib-detail/audio-lib-detail.component';
import { AudioLibraryService } from './_services/audio-library.service';
import { PlaylistComponent } from './playlist/playlist.component';
import { PlaylistTabContentComponent } from './playlist/playlist-detail/playlist-detail.component';
import { ProgrammeComponent } from './programme/programme.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonPopoverComponent } from './button_popover/button-popover.component';
import { FooterComponent } from './footer/footer.component';
import { CategoriesComponent } from '../app/audio-library/categories/categories.component';
import { DayTimelineCalenderComponent } from './programme/day-timeline-calender/day-timeline-calender.component';

// import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule } from '@syncfusion/ej2-angular-calendars';

import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { LibraryActionMenuComponent } from './audio-library/library-action-menu/library-action-menu.component';
import { CommonComponentsModule } from './common-components/common-components.module';
import { VenueModalComponent } from "./dashboard/venue-modal/venue-modal.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { ScheduleDetailComponent } from "./schedule/schedule-detail/schedule-detail.component";
import { AddPlaylistFolderFormComponent } from './playlist/add-playlist-folder-form/add-playlist-folder-form.component';
import { AddPlaylistFormComponent } from './playlist/add-playlist-form/add-playlist-form.component';
import { DelPlaylistConfirmComponent } from './playlist/del-playlist-confirm/del-playlist-confirm.component';
import { ProgrammeDetailComponent } from "./programme/programme-detail/programme-detail.component";
import { DelPlaylistFolderConfirmComponent } from "./playlist/del-playlist-folder-confirm/del-playlist-folder-confirm.component";
import { PlaylistActionMenuComponent } from "./playlist/playlist-action-menu/playlist-action-menu.component";
import { AddProgrammeFormComponent } from './programme/add-programme-form/add-programme-form.component';
import { AddProgrammeFolderFormComponent } from './programme/add-programme-folder-form/add-programme-folder-form.component';
import { DelProgrammeConfirmComponent } from './programme/del-programme-confirm/del-programme-confirm.component';
import { DelProgrammeFolderConfirmComponent } from './programme/del-programme-folder-confirm/del-programme-folder-confirm.component';
import { ProgrammeActionMenuComponent } from './programme/programme-action-menu/programme-action-menu.component';
import { AddNewEventModalComponent } from "./programme/add-new-event-modal/add-new-event-modal.component";
import { AddScheduleFormComponent } from "./schedule/add-schedule-form/add-schedule-form.component";
import { AddScheduleFolderFormComponent } from './schedule/add-schedule-folder-form/add-schedule-folder-form.component';
import { DelScheduleFolderConfirmComponent } from './schedule/del-schedule-folder-confirm/del-schedule-folder-confirm.component';
import { ScheduleActionMenuComponent } from './schedule/schedule-action-menu/schedule-action-menu.component';
import { EditFolderFormComponent } from './audio-collections/edit-folder-form/edit-folder-form.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VideoLibDetailComponent } from "./video-library/video-lib-detail/video-lib-detail.component";
import { VideoLibraryComponent } from "./video-library/video-library.component";
import { VideoPreviewModalComponent } from "./video-library/video-preview-modal/video-preview-modal.component";
import {PlaylistRulesPanelComponent} from './playlist/playlist-rules-panel/playlist-rules-panel.component';
import { AudioPreviewModalComponent } from "./audio-library/audio-preview-modal/audio-preview-modal.component";
import { NewBusinessDetailsComponent } from "./account/new-business-details/new-business-details.component";
import {DropdownListComponent} from './common-components/dropdown-list/dropdown-list.component';
import {DropdownListItemComponent} from './common-components/dropdown-list/dropdown-list-item.component';
import {DelScheduleConfirmComponent} from './schedule/del-schedule-confirm/del-schedule-confirm.component';
import {ProductCardComponent} from './dashboard/product-card/product-card.component';
import {DashboardDetailProductsComponent} from './dashboard/dashboard-detail-products/dashboard-detail-products.component';
import {LicenseModalComponent} from './licences/license-modal.component';
import {RenditionService} from './_services/rendition.service';

import { ProductFilterComponent } from './product-filter/product-filter.component';
import {ProductFilterService} from './_services/product-filter.service';

@NgModule({
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpModule,
      HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    TranslateModule.forRoot(),
    NgbModule,
    NgbPopoverModule,
    NgbDropdownModule,
    // DropDownListAllModule,
    // MultiSelectAllModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    ScheduleModule,
    CommonComponentsModule,
    ScrollingModule
  ],
    declarations: [
        AppComponent,
        AlertComponent,
        SafePipe,
        RegistrationComponent,
        HomeComponent,
        ForgotPasswordComponent,
        LoginComponent,
        AccountComponent,
        LoginDetailsComponent,
        LoginDetailsFormComponent,
        BusinessDetailsComponent,
        BusinessDetailsFormComponent,
        LicencesComponent,
        ProductListComponent,
        ProductDetailComponent,
        MovieDetailComponent,
        FormEntryErrorsComponent,
        ResellerHomeComponent,
        ResellerTabsComponent,
        ResellerLicencesComponent,
        GroupVenuesComponent,
        ClientTabsComponent,
        ClientShopComponent,
        ClientHomeComponent,
        TrialComponent,

        CPHomeComponent,
        CPTabsComponent,
        CPLicencesComponent,

        // ADMIN
        AdminHomeComponent,
        AdminTabsComponent,
        AdminResellersComponent,
        AdminCPsComponent,
        AdminAccountsComponent,
        AdminControlComponent,
        OrderListComponent,
        OrderDetailComponent,
        OrderEditComponent,
        LicenceAssignComponent,
        OrderCreateComponent,
        LicenceRenewComponent,
        AdminPlaysComponent,
        // DASH_BOARD
        DashboardComponent,
        // PRODUCT AND LICENCE
        DashboardDetailComponent,
        LicenseComponent,
        ModalComponent,
        VenueModalComponent,
        // AUDIO LIBRARY
        AudioLibraryComponent,
        AudioLibraryDetailComponent,
        PlaylistComponent,
        PlaylistTabContentComponent,
        CategoriesComponent,
        AddPlaylistFolderFormComponent,
        AddPlaylistFormComponent,
        DelPlaylistConfirmComponent,
        DelPlaylistFolderConfirmComponent,
        PlaylistActionMenuComponent,
        EditFolderFormComponent,
        PlaylistRulesPanelComponent,
        // BUTTON POPOVER
        ButtonPopoverComponent,
        // FOOTER
        FooterComponent,
        // Schedule
        ScheduleComponent,
        ScheduleDetailComponent,
        ProgrammeComponent,
        DayTimelineCalenderComponent,
        LibraryActionMenuComponent,
        ProgrammeDetailComponent,
        AddProgrammeFormComponent,
        AddProgrammeFolderFormComponent,
        DelProgrammeConfirmComponent,
        DelProgrammeFolderConfirmComponent,
        ProgrammeActionMenuComponent,
        AddNewEventModalComponent,
        AddScheduleFormComponent,
        AddScheduleFolderFormComponent,
        DelScheduleFolderConfirmComponent,
        ScheduleActionMenuComponent,
        VideoLibraryComponent,
        VideoLibDetailComponent,
        VideoPreviewModalComponent,
        AudioPreviewModalComponent,
        ProductFilterComponent,
        NewBusinessDetailsComponent,
        DropdownListComponent,
        DropdownListItemComponent,
        DelScheduleConfirmComponent,
        ProductCardComponent,
        DashboardDetailProductsComponent,
        LicenseModalComponent
    ],

  providers: [
    AccountService,
    AdminService,
    ProductService,
      ProductFilterService,
    ValidationService,
    AlertService,
    AuthGuard,
    AccountComponent,
    AudioLibraryService,
    DayTimelineCalenderComponent,
    ProgrammeComponent,
      RenditionService
  ],
  bootstrap: [AppComponent],

  entryComponents: [
    ModalComponent,
    VenueModalComponent,
    AddNewEventModalComponent,
    VideoPreviewModalComponent,
    AudioPreviewModalComponent,
      LicenseModalComponent
  ]
})
export class AppModule { }
