
export const FLOATING_ITEM_ID = 0;
export const processFolderMap = (folders, modelList) => {

    let folderMap = modelList.reduce((accum, currentVal) => {
        const folderId = (currentVal.hasOwnProperty('folderId')) ? currentVal.folderId : FLOATING_ITEM_ID;

        if (accum[folderId] !== undefined) {
            accum[folderId].push(currentVal);
        } else {
            accum[folderId] = [currentVal];
        }
        return accum;

    }, { [FLOATING_ITEM_ID]: []});

    folderMap = folders.reduce((accum, currentVal) => {
        if (accum[currentVal.id] === undefined) {
            accum[currentVal.id] = [];
        }
        return accum;
    }, folderMap);

    return folderMap;
};
