import {Component, Input, Output, EventEmitter, OnInit, ViewChild, ComponentFactoryResolver} from '@angular/core';
import {Playlist} from '../../_models/playlist';
import {Programme} from '../../_models/programme';
import {Account} from '../../_models/account';
import {
    DayService,
    View,
    DragAndDropService,
    ResizeService,
    TimeScaleModel,
    GroupModel,
    ResourceDetails,
    TreeViewArgs,
    ScheduleComponent,
    EventSettingsModel,
    PopupOpenEventArgs,
    WorkHoursModel,
    CellTemplateArgs,
    TimelineMonthService
} from '@syncfusion/ej2-angular-schedule';
import {SequenceAction} from '../../_models/sequence-action';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddNewEventModalComponent, ActionType} from '../add-new-event-modal/add-new-event-modal.component';
import {ProgramSequence} from 'app/_models/program-sequence';
import {Internationalization} from '@syncfusion/ej2-base';
import {AudioLibraryService} from 'app/_services/audio-library.service';
import {AMPFolder} from '../../_models/folder';
import {buildPlaylistMediaTypeFilter, processPlaylistCollections} from '../../playlist/iplaylist-tab-view';
import Util from 'app/Util';

@Component({
    selector: 'amp-programme-detail',
    templateUrl: './programme-detail.component.html',
    providers: [DayService, DragAndDropService, ResizeService, TimelineMonthService],
    styleUrls: [
        './programme-detail.component.scss'
    ]
})
export class ProgrammeDetailComponent implements OnInit, ProgrammeModalListener {

    @Input()
    account: Account;

    @Input()
    playlists: Array<Playlist>;

    @Input()
    audioPlaylistFolders: Array<AMPFolder>;

    @Input()
    videoPlaylistFolders: Array<AMPFolder>;

    @Input() set activeProgram(value: Programme) {
        this._activeProgram = value;
        if (this._activeProgram) {
            console.log("set activeProgram", value);
            this.HOURS_OFFSET = this._activeProgram.startTime / 3600;
            this.generateSchedulerData(value);
        }
    };

    get activeProgram(): Programme {
        return this._activeProgram;
    }

    _editMode: boolean;
    @Input() set editMode(value: boolean) {
        console.log("editMode", value);
        this.localEventCount = 0;
        this._editMode = value;
    };

    get editMode(): boolean {
        return this._editMode;
    }

    @Output()
    updatedProgram: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('scheduleObj', {static: true})
    public scheduleObj: ScheduleComponent;


    private _activeProgram: Programme;
    private static AUDIO_COL = 1;
    private static VIDEO_COL = 2;
    private static ACTION_COL = 3;
    private sequenceActions: Array<SequenceAction>;
    private localEventCount = 0;


    public HOURS_OFFSET = 0; // number of hours schedulers should be offset forward e.g if HOURS_OFFSET=2 then 2am becomes 4am
    public showQuickInfo: Boolean = false;
    public columnDataSource: any[] = [
        {Text: 'Audio Sequence', ColumnId: ProgrammeDetailComponent.AUDIO_COL, Color: '#357cd2'},
        {Text: 'Video Sequence', ColumnId: ProgrammeDetailComponent.VIDEO_COL, Color: '#1aaa55'},
        {Text: 'Action Sequence', ColumnId: ProgrammeDetailComponent.ACTION_COL, Color: '#7fa900'}
    ];
    public currentView: View = 'Day';
    public selectedDate: Date = new Date();
    public timeScaleOptions: TimeScaleModel = {enable: true, slotCount: 1, interval: 60};
    public group: GroupModel = {allowGroupEdit: false, resources: ['Columns']};
    public international: Internationalization = new Internationalization();
    public util = new Util();


    constructor(
        private modalService: NgbModal
    ) {
    }


    ngOnInit(): void {
    }

    onCreate() {
    }

    addEventButton() {

        if (!this.editMode) {
            return;
        }
        const modalRef = this.modalService.open(AddNewEventModalComponent);
        modalRef.componentInstance.formListener = this;
        modalRef.componentInstance.eventType = 1;

        const audioPlaylists = this.playlists.filter(buildPlaylistMediaTypeFilter(2));
        const videoPlaylists = this.playlists.filter(buildPlaylistMediaTypeFilter(1));
        modalRef.componentInstance.selectedPlaylist = audioPlaylists.find(playlist => playlist.folderId === 0);

        modalRef.componentInstance.audioPlaylistListItems = processPlaylistCollections(this.audioPlaylistFolders, audioPlaylists);
        modalRef.componentInstance.videoPlaylistListItems = processPlaylistCollections(this.videoPlaylistFolders, videoPlaylists);
        modalRef.componentInstance.programmeName = this._activeProgram.name;
        modalRef.componentInstance.hourOffset = this.HOURS_OFFSET;

        modalRef.componentInstance.selectedPlaylist = audioPlaylists.find(playlist => playlist.folderId === 0);

        this.localEventCount++;
        modalRef.componentInstance.newEventId = 0 - this.localEventCount;
        modalRef.componentInstance.startTime = this.adjustTimeByHours(this.selectedDate, this.HOURS_OFFSET);
        const endTime = this.adjustTimeByHours(this.selectedDate, this.HOURS_OFFSET);
        modalRef.componentInstance.endTime = endTime;
    }


    onPopupOpen(args: PopupOpenEventArgs): void {
        const data: any = args.data;
        console.log("onPopupOpen", data);

        if (!this.editMode) {
            args.cancel = true;
            return;
        }

        if (data.ColumnId == ProgrammeDetailComponent.AUDIO_COL || data.ColumnId == ProgrammeDetailComponent.VIDEO_COL) {
            const modalRef = this.modalService.open(AddNewEventModalComponent);
            modalRef.componentInstance.formListener = this;
            modalRef.componentInstance.eventType = data.ColumnId;
            // Filter audio playlists
            const audioPlaylists = this.playlists.filter(buildPlaylistMediaTypeFilter(2));
            const videoPlaylists = this.playlists.filter(buildPlaylistMediaTypeFilter(1));

            modalRef.componentInstance.audioPlaylistListItems = processPlaylistCollections(this.audioPlaylistFolders, audioPlaylists);
            modalRef.componentInstance.videoPlaylistListItems = processPlaylistCollections(this.videoPlaylistFolders, videoPlaylists);
            modalRef.componentInstance.programmeName = this._activeProgram.name;
            modalRef.componentInstance.hourOffset = this.HOURS_OFFSET;

            if (args.data.hasOwnProperty('Id')) {
                const sequenceId = data.Id;
                const sequenceAction = this.getSequenceActionById(sequenceId);
                modalRef.componentInstance.sequenceAction = sequenceAction;
                modalRef.componentInstance.selectedPlaylist = this.playlists.find(playlist => playlist.id === data.PlaylistId);

            } else {
                if (data.ColumnId == ProgrammeDetailComponent.AUDIO_COL) {
                    modalRef.componentInstance.selectedPlaylist = audioPlaylists.find(playlist => playlist.folderId === 0);
                } else if (data.ColumnId == ProgrammeDetailComponent.VIDEO_COL) {
                    modalRef.componentInstance.selectedPlaylist = videoPlaylists.find(playlist => playlist.folderId === 0);
                }

                this.localEventCount++;
                modalRef.componentInstance.newEventId = 0 - this.localEventCount;
                modalRef.componentInstance.startTime = this.adjustTimeByHours(data.StartTime, this.HOURS_OFFSET);
                const endTime = this.adjustTimeByHours(data.EndTime, this.HOURS_OFFSET);
                endTime.setSeconds(endTime.getSeconds() - 1);
                modalRef.componentInstance.endTime = endTime;
            }
        }

        args.cancel = true;
    }

    generateSchedulerData(programme: Programme) {
        var displayData: Object[] = [];

        if (programme != undefined) {
            this.sequenceActions = [];
            for (let i = 0; i < 2; i++) {
                const sequence = programme.sequences[i];
                if (sequence != undefined) {
                    for (var action of sequence.actions) {
                        this.sequenceActions.push(action);

                        var startDate: Date = new Date();
                        startDate.setHours(0, 0, 0, 0);
                        startDate.setSeconds(action.startSeconds);

                        var endDate: Date = new Date();
                        endDate.setHours(0, 0, 0, 0);
                        endDate.setSeconds(action.startSeconds + action.duration);

                        const playlistId: number = parseInt(action.identifier);
                        const playlist = this.playlists.find((playlist) => playlist.id == playlistId);

                        const newElement = {
                            Id: action.id,
                            ColumnId: sequence.type,
                            Type: 'Playlist',
                            Name: playlist.name,
                            StartTime: startDate,
                            EndTime: endDate,
                            Shuffle: action.shuffle,
                            NetworkMode: action.networkMode,
                            Duration: playlist.duration,
                            PlaylistId: playlistId,
                            Mute: action.mute
                        };
                        displayData.push(newElement)
                    }
                }
            }
        }

        this.scheduleObj.eventSettings.dataSource = displayData;
    }

    getSequenceName(value: ResourceDetails | TreeViewArgs): string {
        const s = ((value as ResourceDetails).resourceData) ?
            (value as ResourceDetails).resourceData[(value as ResourceDetails).resource.textField] as string
            : (value as TreeViewArgs).resourceName;
        return s;
    }

    getSequenceImage(value: ResourceDetails | TreeViewArgs): string {
        const resourceName: string = this.getSequenceName(value);
        return resourceName.replace(' ', '-').toLowerCase();
    }

    onActionComplete(event): void {

        if (event.requestType == "eventChanged") {
            // handle user dragging & drag & drop actions on appointment
            var startTime: Date = event.data[0].StartTime;
            var endTime: Date = event.data[0].EndTime;

            if (endTime.getSeconds() == 0) {
                endTime.setMinutes(endTime.getMinutes() - 1);
                endTime.setSeconds(59);
            }
            console.log("onActionComplete", event.data)

            const startSeconds = this.getSSinceMidnight(startTime);
            var endSeconds = this.getSSinceMidnight(endTime);
            const sequenceId = event.data[0].Id;
            const eventType = event.data[0].ColumnId;
            console.log("onActionComplete eventType:", eventType, event.data[0].ColumnId)
            console.log("dump: ", sequenceId, startSeconds, endSeconds);
            var sequence: SequenceAction = this.findSequenceActionById(sequenceId);

            this.handleEventUpdate(sequence, startSeconds, endSeconds, sequenceId, eventType)
        }
    }

    onSubmit(sequenceAction: SequenceAction, modalType: ActionType, startSeconds: number, duration: number, programSequenceType: number) {
        console.log("onSubmit", sequenceAction);
        if (modalType == ActionType.DELETE) {
            this.removeSequenceById(sequenceAction.id);
            this.updatedProgram.emit(this._activeProgram);

        } else if (modalType == ActionType.NEW || modalType == ActionType.EDIT) {
            if (this.getSequenceOfType(1) == undefined) {
                const audioSequenceName = this._activeProgram.name + '-' + this.columnDataSource[0].Text;
                const audioSequence = new ProgramSequence(0, audioSequenceName, 1, 0, []);
                this._activeProgram.sequences.push(audioSequence);
            }
            if (this.getSequenceOfType(2) == undefined) {
                const videoSequenceName = this._activeProgram.name + '-' + this.columnDataSource[1].Text;
                const videoSequence = new ProgramSequence(0, videoSequenceName, 2, 0, []);
                this._activeProgram.sequences.push(videoSequence);
            }

            const endSeconds = startSeconds + duration;
            this.handleEventUpdate(sequenceAction, startSeconds, endSeconds, sequenceAction.id, programSequenceType)
        }
    }

    handleEventUpdate(sequenceAction: SequenceAction, startSeconds: number, endSeconds: number, sequenceId: number, programSequenceType: number) {
        const concurrentEvent = this.checkConcurrency(startSeconds, endSeconds, sequenceId, programSequenceType);

        if (concurrentEvent) {
            endSeconds = concurrentEvent.startSeconds - 1
            const concurrentEvent2 = this.checkConcurrency(startSeconds, endSeconds, sequenceId, programSequenceType);
            if (concurrentEvent2) {
                console.log("is concurrent - cancel action");
                this.updatedProgram.emit(this._activeProgram);
            } else {

                this.acceptEventUpdate(sequenceAction, startSeconds, endSeconds, sequenceAction.id, programSequenceType)
            }

        } else if (programSequenceType != sequenceAction.type) {
            // alert("Unable to change event type")
            this.updatedProgram.emit(this._activeProgram);

        } else {
            // console.log("not concurrent - change event", programSequenceType, this._activeProgram.sequences);
            this.acceptEventUpdate(sequenceAction, startSeconds, endSeconds, sequenceAction.id, programSequenceType)
        }
    }

    acceptEventUpdate(sequenceAction: SequenceAction, startSeconds: number, endSeconds: number, sequenceId: number, programSequenceType: number) {
        sequenceAction.startSeconds = startSeconds;
        sequenceAction.duration = (endSeconds - startSeconds);

        this.removeSequenceById(sequenceId);
        this.getSequenceOfType(programSequenceType).actions.push(sequenceAction);

        this.updatedProgram.emit(this._activeProgram);
    }

    getSequenceActionById(id) {
        return this.sequenceActions.find((e) => e.id == id);
    }

    findSequenceActionById(id) {
        for (var sequence of this._activeProgram.sequences) {
            if (sequence != undefined && sequence.actions != undefined) {
                // sequence.actions = sequence.actions.filter(item => item.id !== id);
                let action = sequence.actions.find((e) => e.id == id);
                if (action != undefined) {
                    return action;
                }
            }
        }
    }

    removeSequenceById(id) {
        for (var sequence of this._activeProgram.sequences) {
            if (sequence != undefined && sequence.actions != undefined) {
                sequence.actions = sequence.actions.filter(item => item.id !== id);
            }
        }
    }

    getSSinceMidnight(d) {
        var e = new Date(d);
        return (d - e.setHours(0, 0, 0, 0)) / 1000;
    }

    checkConcurrency(startSeconds, endSeconds, sequenceId, programSequenceType) {
        return this.getSequenceOfType(programSequenceType).actions.find((seqAct) => {
            const seqActEndSeconds = seqAct.startSeconds + seqAct.duration;
            const startTimeRule = startSeconds >= seqAct.startSeconds && startSeconds <= seqActEndSeconds;
            const endTimeRule = endSeconds >= seqAct.startSeconds && startSeconds <= seqActEndSeconds;
            const idRule = sequenceId !== seqAct.id;

            return (startTimeRule || endTimeRule) && idRule;
        });
    }

    getSequenceOfType(type) {
        console.log("getSequenceOfType", type, this._activeProgram.sequences)
        return this._activeProgram.sequences.find(seq => seq.type === type);
    }

    getMajorTime(date: Date): string {
        return this.international.formatDate(this.adjustTimeByHours(date, this.HOURS_OFFSET), {skeleton: 'Hm'});
    }

    adjustTimeByHours(date: Date, hours: number): Date {
        var adjustedDate = new Date(date.getTime());
        adjustedDate.setHours(adjustedDate.getHours() + hours);
        return adjustedDate;
    }
}


export interface ProgrammeModalListener {
    onSubmit(actionSequence: SequenceAction, modalType: ActionType, startSeconds: number, duration: number, programSequenceType: number);
}
