import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import DelScheduleFolderConfirmDelegate from './del-schedule-folder-confirm.delegate';
import {ScheduleFolderListItemModel} from '../util/schedule-listitem-factory';

@Component({
    selector: 'amp-del-schedule-folder-confirm',
    templateUrl: './del-schedule-folder-confirm.component.html',
    styleUrls: ['./del-schedule-folder-confirm.component.scss']
})
export class DelScheduleFolderConfirmComponent {

    private _folderItemModel;

    @Input() eventDelegate: DelScheduleFolderConfirmDelegate;
    @Input()
    set folderItemModel(value: ScheduleFolderListItemModel) {
        if (value.schedules && value.folder) {
            this._folderItemModel = value;
        } else {
            this._folderItemModel = {schedules: [], folder: {}}
        }

    }

    get folderItemModel() {
        return this._folderItemModel;
    }

    constructor(
    ) { }

    onCancel() {
        this.eventDelegate.onDelScheduleFolderCancel({});
    }

    onConfirm() {
        // if (this.folderItemModel && this.folderItemModel.schedules.length > 0) { return; }
        this.eventDelegate.onDelScheduleFolderConfirm(this._folderItemModel.folder);
    }

}
