import {Component, OnInit} from '@angular/core';
import {ListItem} from '../common-components/master-view-list/models/list-item';

import {
    MasterViewListMediator,
    NestedMasterViewListMediator
} from '../common-components/master-view-list/interfaces/master-view-list-mediator';
import {MasterViewListItemController} from 'app/common-components/master-view-list/interfaces/master-view-list-item';
import {MasterViewListItemEventDelegate} from 'app/common-components/master-view-list/interfaces/master-view-list-item-event-delegate';
import {AlertService} from 'app/_alert/alert.service';
import {Router} from '@angular/router';
import {AudioLibraryService} from 'app/_services/audio-library.service';
import {Artist} from 'app/_models/schedule';
import ListItemIconConfigFactory from 'app/common-components/master-view-list/util/list-item-config-factory';
import {ListItemConfig} from 'app/common-components/master-view-list/models/list-item-config';
import {Playlist} from 'app/_models/playlist';
import {Tag} from 'app/_models/tag';
import {TranslateService} from '@ngx-translate/core';
import {AudioLibraryArtistListItemFactory} from 'app/audio-library/util/audio-lib-artist-listitem-factory';
import {isStaticListItem, StaticListItemComparator} from '../common-components/master-view-list/util/static-list-item-comparator';
import {GenericStaticListItemFactory, GenericTreeListItemFactory, StaticListItemModel} from './util/generic-listitem-factories';
import {Category} from '../_models/category';
import {MultiSelectListMediator} from 'app/common-components/master-view-list/interfaces/mulit-select-list';
import {AMPFolder} from '../_models/folder';
import {processPlaylistCollections} from '../playlist/iplaylist-tab-view';
import {LibraryModalDelegate} from '../audio-library/library-action-menu/library-action-menu.component';


@Component({
    selector: 'amp-video-library',
    templateUrl: './video-library.component.html',
    styleUrls: ['./video-library.component.scss']
})
export class VideoLibraryComponent implements OnInit, MasterViewListItemController, MasterViewListItemEventDelegate, LibraryModalDelegate {

    VIDEO_TYPE = 1;

    // List Control & Management
    listItemIconConfigFactory: ListItemIconConfigFactory;
    masterViewListMediator: MasterViewListMediator;
    masterViewList: Array<ListItem>;
    multiSelectListMediator: MultiSelectListMediator;


    // Cached Models
    playlists: Array<Playlist> = [];
    playlistFolders: Array<AMPFolder> = [];
    artists: Array<Artist> = [];

    // Detail View
    currentTab: string;
    tabContent: Array<object> = [];
    filteredTabContent: Array<object> = [];
    playlistListItems: Array<ListItem> = [];

    listType = 'grid';

    public searchFilter = '';
    public productFilters = [];
    public productFiltersVisible = false;
    public serverSearchPhrase = '';

    constructor(
        private audioLibraryService: AudioLibraryService,
        private router: Router,
        private alert: AlertService,
        private translate: TranslateService
    ) {
        this.masterViewList = [];
        this.masterViewListMediator = new NestedMasterViewListMediator();
        this.listItemIconConfigFactory = new ListItemIconConfigFactory();
        this.multiSelectListMediator = new MultiSelectListMediator();

        this.handleServiceError = this.handleServiceError.bind(this);
    }

    ngOnInit(): void {
        this.loadArtists()
            .then(() => this.loadPlaylists())
            .then(() => this.loadVideoPlaylistFolders())
            .then(() => this.loadVideoLibraryCollection())
            .catch(this.handleServiceError).finally(() => {
            this.buildPlaylistList();
        });
    }

    loadProductOrientedLibraries() {
        //const old = this.clearMasterViewList();
        const idx_offset = 0;
        Promise.all(
            [
                this.loadRecentItems(),
                this.loadCategories(),
                this.loadChannels(),
                this.loadSeries(),
                this.loadAlbums(),
                this.loadArtists(),
                this.loadAttributes()
            ]
        ).then(listItemResults => {
            listItemResults.forEach((listItem, i) => this.masterViewList[i + idx_offset] = listItem);
        }).catch(this.handleServiceError).finally(() => {
            this.getDetailViewForListItem(this.masterViewList[0]);
        });
    }

    cancelProductFilter() {
        this.productFiltersVisible = false;
    }

    setProductFilter(products) {
        this.productFilters = products;
        if (this.masterViewListMediator.isLastItemSelected()) {
            console.log('Setting filters');
            this.loadProductOrientedLibraries();
        }
    }

    handleServiceError(error) {
        if (error.status === 0) {
            this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            return;
        }
        try {
            const response = JSON.parse(error.text());
            this.alert.error(response.responseData);
        } catch (e) {
            this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
        }
    }

    loadVideoLibraryCollection(): Promise<void> {
        return Promise.all(
            [
                this.loadRecentItems(),
                this.loadCategories(),
                this.loadChannels(),
                this.loadSeries(),
                this.loadAlbums(),
                this.loadArtists(),
                this.loadAttributes()
            ]
        ).then(listItemResults => {
            listItemResults.forEach(listItem => this.pushToMasterViewList(listItem));
        });
    }

    loadPlaylists(): Promise<Array<Playlist>> {
        return this.audioLibraryService.fetchPlaylists(this.VIDEO_TYPE).toPromise().then(res => {
            return this.playlists = res;
        });
    }

    loadVideoPlaylistFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(4).toPromise().then(res => {
            return this.playlistFolders = res;
        });
    }

    loadRecentItems(): Promise<ListItem> {
        return this.audioLibraryService.fetchVideoLibrary(this.productFilters).toPromise().then(res => {
            const staticListItemFactory = new GenericStaticListItemFactory();
            const leftHeadingIcoConf = this.listItemIconConfigFactory.buildListItemImageIconConfig(
                '../../assets/img/icons_ic_sequence_uncolored.png',
                '../../assets/img/icons_ic_sequence.png',
                {},
                'sequence-ico'
            );

            const config = new ListItemConfig({
                left: leftHeadingIcoConf,
                leftHeading: leftHeadingIcoConf
            });

            const listItem = staticListItemFactory.buildListItem(
                new StaticListItemModel('Recently Added', res),
                config
            );

            this.listItemSelected({event: {}, nestedDepth: 1}, listItem);
            return listItem;
        });
    }

    loadCategories(): Promise<ListItem> {
        return this.audioLibraryService.fetchCategories(1).toPromise()
            .then(res => {
                const leftIcoConf = this.listItemIconConfigFactory.buildListItemImageIconConfig(
                    '../../assets/img/icons_ic_sequence_uncolored.png',
                    '../../assets/img/icons_ic_sequence.png',
                    {},
                    'sequence-ico'
                );

                const listConfig = new ListItemConfig({
                    leftHeading: leftIcoConf,
                    left: leftIcoConf
                });

                return this.buildListItem(listConfig, 'Categories', res, 'children');
            });
    }

    loadChannels(): Promise<ListItem> {
        return this.audioLibraryService.fetchChannels(this.productFilters).toPromise().then(res => {
            const leftIcoConf = this.listItemIconConfigFactory.buildListItemImageIconConfig(
                '../../assets/img/icons_ic_channel_uncolored.png',
                '../../assets/img/icons_ic_channel_colored.png',
                {},
                'channel-ico'
            );

            const listConfig = new ListItemConfig({
                leftHeading: leftIcoConf,
                left: leftIcoConf
            });

            return this.buildListItem(listConfig, 'Channels', res, 'children');
        });
    }

    loadSeries(): Promise<ListItem> {
        return this.audioLibraryService.fetchSeries(this.productFilters).toPromise().then(res => {

            const leftIcoConf = this.listItemIconConfigFactory.buildListItemImageIconConfig(
                '../../assets/img/icons_ic_series_uncolored.png',
                '../../assets/img/icons_ic_series_colored.png',
                {},
                'series-ico'
            );

            const listConfig = new ListItemConfig({
                leftHeading: leftIcoConf,
                left: leftIcoConf
            });

            return this.buildListItem(listConfig, 'Series', res, 'children');
        });
    }

    loadAlbums(): Promise<ListItem> {
        return this.audioLibraryService.fetchAlbums(this.productFilters).toPromise().then(res => {

            const leftIcoConf = this.listItemIconConfigFactory.buildListItemImageIconConfig(
                '../../assets/img/icons_ic_album_uncolored.png',
                '../../assets/img/icons_ic_album_colored.png',
                {},
                'album-ico'
            );

            const listConfig = new ListItemConfig({
                leftHeading: leftIcoConf,
                left: leftIcoConf
            });

            return this.buildListItem(listConfig, 'Albums', res, 'children');
        });
    }

    loadAttributes(): Promise<ListItem> {
        const tagMapper = (tag) => {
            return new Tag(
                tag.idString,
                tag.name,
                tag.tagGroupId,
                tag.children.map(tagMapper)
            );
        };
        return this.audioLibraryService.fetchTags().toPromise().then(res => {
                return res.map(tagMapper);
            }
        ).then(res => {

            const leftIcoConf = this.listItemIconConfigFactory.buildListItemImageIconConfig(
                '../../assets/img/icons_ic_attribute_uncolored.png',
                '../../assets/img/icons_ic_attribute_colored.png',
                {},
                'attribute-ico'
            );

            const listConfig = new ListItemConfig({
                leftHeading: leftIcoConf,
                left: leftIcoConf
            });

            return this.buildListItem(listConfig, 'Attributes', res, 'children');
        });
    }

    loadArtists(): Promise<ListItem> {
        return this.audioLibraryService.fetchArtists(1).toPromise().then(res => {
            this.artists = res;

            const artistListFactory = new AudioLibraryArtistListItemFactory();
            const leftIcoConf = this.listItemIconConfigFactory.buildListItemImageIconConfig(
                '../../assets/img/icons_ic_audio_artist.png',
                '../../assets/img/icons_ic_artist_colored.png',
                {},
                'audio-artist-ico'
            );

            const listConfig = new ListItemConfig({
                leftHeading: leftIcoConf,
                left: leftIcoConf
            });
            return artistListFactory.buildListItem(this.artists, listConfig);
        });
    }


    clearMasterViewList(): Array<ListItem> {
        const cachedList = this.masterViewList;
        this.masterViewList = [];
        return cachedList;
    }

    loadContentForArtist(artist: Artist): Promise<any> {
        return this.audioLibraryService.fetchContentByCategoryTypeAndArtistId(this.VIDEO_TYPE, artist.id, this.productFilters).toPromise();
    }

    loadContentForSIdentifier(sIdentifier: string): Promise<any> {
        return this.audioLibraryService.fetchVideoContentByIdentifier([sIdentifier]).toPromise();
    }

    loadContentForTag(tag: Tag): Promise<any> {
        return this.audioLibraryService.fetchContentByCategoryTypeAndTagID(this.VIDEO_TYPE, tag.idString, this.productFilters).toPromise();
    }

    loadContentForCategory(category: Category): Promise<any> {
        return this.audioLibraryService.fetchContentByCategoryTypeAndCategoryId(this.VIDEO_TYPE, category.idAsString, this.productFilters).toPromise();
    }

    getDetailViewForListItem(listItem): void {
        this.multiSelectListMediator.clearSelection();
        let tabContentPromise;
        if (listItem.getInnerModel() instanceof Artist) {
            tabContentPromise = this.loadContentForArtist(listItem.getInnerModel());
        } else if (listItem.getInnerModel() instanceof Tag) {
            tabContentPromise = this.loadContentForTag(listItem.getInnerModel());
        } else if (listItem.getInnerModel() instanceof Category) {
            tabContentPromise = this.loadContentForCategory(listItem.getInnerModel())
        } else if (listItem.getInnerModel().hasOwnProperty('id')) {
            tabContentPromise = this.loadContentForSIdentifier(listItem.getInnerModel().id);
        } else if (isStaticListItem(listItem)) {
            tabContentPromise = new Promise((resolve, reject) => resolve(listItem.getInnerModel().tabContent));
        } else {
            return;
        }

        tabContentPromise.then(res => {
            res = (res.hasOwnProperty('length') && res.length === 1 && Array.isArray(res[0])) ? res[0] : res;
            this.tabContent = res;
            this.filteredTabContent = res;
            this.currentTab = listItem.getDisplayText()
        }).catch(this.handleServiceError);
    }

    getMasterViewList(): Array<ListItem> {
        return this.masterViewList;
    }

    listItemSelected(evPayload: { event; nestedDepth: number }, listItem) {
        this.masterViewListMediator.setSelectedListItem(listItem, evPayload.nestedDepth);
        if (!listItem.hasNestedItems()) {
            this.getDetailViewForListItem(listItem);
        }
    }

    buildListItem(config, header, model, childKey) {
        const ListFactory = new GenericTreeListItemFactory(header, childKey);
        return ListFactory.buildListItem(model, config);
    }

    pushToMasterViewList(listItem): void {
        this.masterViewList.push(listItem);
    }

    // Detail View Handling

    handleDetailListViewTypeToggle(event) {
        if (event.target.id === 'list') {
            this.listType = 'list';
        } else {
            this.listType = 'grid';
        }
    }

    buildPlaylistList() {
        this.playlistListItems = processPlaylistCollections(this.playlistFolders, this.playlists.filter(playlist => {
            return playlist.playlistType === 1;
        }));
    }

    success() {
        Promise.all([this.loadPlaylists(), this.loadVideoPlaylistFolders()]).then(res => {
            this.buildPlaylistList();
        });
    }

    onSearchChange(event) {
        let listItems
        if (this.searchFilter !== '') {
            listItems = this.tabContent.filter(this.getFilterFunc(this.searchFilter));
        } else {
            listItems = this.tabContent;
        }
        this.filteredTabContent = listItems;
    }

    getFilterFunc(searchFilter) {
        return (item) => {
            return item.name.toLowerCase().includes(searchFilter.toLowerCase()) || this.getArtistForId(item.artistId).name.toLowerCase().includes(searchFilter.toLowerCase());
        };
    }

    getArtistForId(artistId) {
        const foundArtist = this.artists.find(artist => artist.id === artistId);
        return (foundArtist) ? foundArtist : new Artist(-1, '');
    }

    queryServerSearch() {
        if (this.serverSearchPhrase.length > 0) {
            this.audioLibraryService.searchServer(this.serverSearchPhrase, 1).toPromise()
                .then(res => {
                    if (this.masterViewListMediator.isLastItemSelected()) {
                        this.masterViewListMediator.setSelectedListItem(this.masterViewListMediator.getLastSelectedItem(), 0);
                    }
                    this.currentTab = 'Search results'
                    this.filteredTabContent = res;
                });
        }
    }
}
