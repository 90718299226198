import {IPlaylistFilterView} from './iplaylist-filter-view';
import {Playlist} from '../../../_models/playlist';
import {PlaylistFilter} from '../../../_models/playlist-filter';
import {ListItem} from '../../../common-components/master-view-list/models/list-item';
import {AMPFolder} from '../../../_models/folder';
import {processPlaylistCollections} from '../../iplaylist-tab-view';

export class CombinedPlaylistFilterView implements IPlaylistFilterView<Playlist> {

    private _mediaType;
    private _playlistFolders = new Array<AMPFolder>();
    private _playlists = new Array<Playlist>();
    private _playlistsMap = {};
    private _dropdownItems = new Array<ListItem>();

    constructor(mediaType: number, playlistFolders: Array<AMPFolder>) {
        this._playlistFolders = playlistFolders;
        this._mediaType = mediaType;
    }

    buildDefaultFilter(playlistId): PlaylistFilter {
        return new PlaylistFilter(0, playlistId, this.getFilterId(), `${this._playlists[0].id}`, `${this._playlists[0].id}`);
    }

    getDropdownItems(): Array<ListItem> {
        return this._dropdownItems;
    }

    getFilterDisplayString(): string {
        return 'Playlist';
    }

    getFilterIconAssetClassName(): string {
        return '';
    }

    getFilterId(): number {
        return PlaylistFilter.AMP_FILTER_TYPE_PLAYLIST;
    }

    getFilterImageIcoAsset(): string {
        return '../../assets/img/icons_ic_playlist_type.svg';
    }

    getReducedFilterContentOption(filterContent: string): { contentId: string; name: string } {
        const result = {contentId: '', name: ''};

        if (this._playlistsMap[filterContent]) {
            result.contentId = this._playlistsMap[filterContent].id;
            result.name = this._playlistsMap[filterContent].name;
        }

        return result;
    }

    handleFilterUpdate(filter: PlaylistFilter, filterContent: Playlist) {
        filter.filterIdentifier = `${filterContent.id}`;
        filter.filterIdentifierAsString = `${filterContent.id}`;
    }

    isFilterIconAssetFont(): boolean {
        return false;
    }

    isFilterIconAssetImg(): boolean {
        return true;
    }

    setFilterContent(content: Array<Playlist>): void {
        const SUPPORTED_PLAYLIST_TYPES = [1, 2];
        this._playlists = content.filter(playlist => SUPPORTED_PLAYLIST_TYPES.includes(playlist.playlistType) && playlist.mediaType === this._mediaType);

        this._playlists.forEach(playlist => {
            this._playlistsMap[playlist.id] = playlist;
        });

        this._dropdownItems = processPlaylistCollections(this._playlistFolders, this._playlists);
    }

}
