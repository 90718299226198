import { Component, OnInit } from '@angular/core';
import { AccountService } from "../../_services/account.service";
import { Account } from "../../_models/account";
import { AlertService } from "../../_alert/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "../../app.component";

@Component({
  selector: 'amp-business-details',
  templateUrl: './business-details.component.html'
})
export class BusinessDetailsComponent implements OnInit {

  account: Account;
  isLoading = false;

  constructor(private app: AppComponent,
    private accountService: AccountService,
    private alert: AlertService,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.account = new Account();

    this.isLoading = true;
    this.accountService.fetchAccount()
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe((account: Account) => {
        this.account = account;
        account.countryRecord = this.app.countryForCode(account.country);

      }, error => {
        if (error.status == 0) {
          this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
          return;
        }
        let response = JSON.parse(error.text());
        this.alert.error(response.responseData);
      });
  }

  public getCountry(): string {
    if (this.account != null && this.account.countryRecord != null)
      return this.account.countryRecord.name;
    return '';
  }

}
