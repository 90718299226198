import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationService } from "../../_services/validation.service";
import { Business } from "../../_models/business";
import { DialogFormSubmitListener } from 'app/common-components/dialogs/modal-dialog-form.parent';
import { Account } from 'app/_models/account';
import { AppComponent } from 'app/app.component';
import { Country } from 'app/_models/country';

@Component({
  selector: 'amp-venue-modal',
  templateUrl: './venue-modal.component.html',
  styleUrls: ['./venue-modal.component.scss'],
})
export class VenueModalComponent implements OnInit {


  public form: FormGroup;
  public countries: any;
  public formListener: DialogFormSubmitListener;
  public modalMode: VenueModalMode;
  public account: Account;
  public app: AppComponent;


  get modalModes() { return VenueModalMode }

  constructor(
    private validation: ValidationService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
  ) {
    this.form = this.formBuilder.group({
      companyName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      contactName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      addressLine1: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      addressLine2: ["", [Validators.minLength(2), Validators.maxLength(30)]],
      postcode: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      city: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      state: ["", [Validators.required]],
      country: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      businessNumber: [""],
      contactEmail: ["", [Validators.required, this.validation.emailValidator()]]
    });
  }

  ngOnInit(): void {
    if (this.modalMode !== VenueModalMode.ADD) {
      this.account.countryRecord = this.app.countryForCode(this.account.country);
      console.log(this.account.countryRecord)
      this.updateForm(this.account);
    }
  }

  close() {
    this.activeModal.close(this);
  }

  updateForm(account: Account) {
    this.form.controls['companyName'].setValue(account.name);
    this.form.controls['contactName'].setValue(account.contactName);
    this.form.controls['phone'].setValue(account.phone);
    this.form.controls['businessNumber'].setValue(account.businessNumber);
    this.form.controls['contactEmail'].setValue(account.contactEmail);
    this.form.controls['addressLine1'].setValue(account.addressLine1);
    this.form.controls['addressLine2'].setValue(account.addressLine2);
    this.form.controls['postcode'].setValue(account.postcode);
    this.form.controls['city'].setValue(account.city);
    this.form.controls['state'].setValue(account.state);
    this.form.controls['country'].setValue(account.countryRecord);
  }


  onSubmit(form) {
    let business: Business = new Business(
      this.form.controls.companyName.value,
      this.form.controls.contactName.value,
      this.form.controls.addressLine1.value,
      this.form.controls.addressLine2.value,
      this.form.controls.city.value,
      this.form.controls.state.value,
      this.form.controls.country.value.code,
      this.form.controls.postcode.value,
      this.form.controls.businessNumber.value,
      this.form.controls.phone.value,
      this.form.controls.contactEmail.value
    );

    console.log(business);

    if (this.modalMode === VenueModalMode.EDIT) {
      business.organisationId = this.account.id;
    }

    this.formListener.onSubmit(business)
      .subscribe(response => {
        this.close();
      },
        error => {
          console.log(error);
          this.close();
        });
  }

  compareFn(c1: Country, c2: Country): boolean {
    return c1 && c2 ? c1.name === c2.name : c1 === c2;
    }

    getAccountId(): string {
        if (this.account != null && this.account.accountId != null)
            return this.account.accountId;
        return '';
    }
}


export enum VenueModalMode {
  ADD,
  EDIT,
  INFO
}
