import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationService } from "../../_services/validation.service";
import {AMPFolder} from '../../_models/folder';
import {EditFolderFormDelegate} from './edit-folder-form.delegate';
import {ProgrammeFolderListItemModel} from '../../programme/util/programme-listitem-factory';

@Component({
    selector: 'amp-edit-folder-form',
    templateUrl: './edit-folder-form.component.html',
    styleUrls: ['./edit-folder-form.component.scss']
})
export class EditFolderFormComponent implements OnInit, OnChanges {

    @Input() eventDelegate: EditFolderFormDelegate;
    @Input() folderItem: {folder: AMPFolder};

    form: FormGroup;

    constructor(
        private validation: ValidationService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            folderName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]]
        });
    }

    close() {
        this.eventDelegate.onEditFolderCancel({});
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.folderItem.isFirstChange() && changes.folderItem.currentValue.isFolderListItemModel) {
            this.form.controls.folderName.setValue(changes.folderItem.currentValue.folder.folderName);
        }
    }

    onSubmit(form) {
        this.folderItem.folder.folderName = form.folderName;
        this.eventDelegate.onEditFolderSubmit(this.folderItem.folder);
    }
}
