import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import DelPlaylistConfirmDelegate from './del-programme-folder-confirm.delegate';
import {Playlist} from '../../_models/playlist';
import DelPlaylistFolderConfirmDelegate from './del-programme-folder-confirm.delegate';
import {AMPFolder} from '../../_models/folder';
import {ProgrammeFolderListItemModel} from '../util/programme-listitem-factory';
import DelProgrammeFolderConfirmDelegate from './del-programme-folder-confirm.delegate';

@Component({
    selector: 'amp-del-programme-folder-confirm',
    templateUrl: './del-programme-folder-confirm.component.html',
    styleUrls: ['./del-programme-folder-confirm.component.scss']
})
export class DelProgrammeFolderConfirmComponent {

    private _folderItemModel;

    @Input() eventDelegate: DelProgrammeFolderConfirmDelegate;
    @Input()
    set folderItemModel(value: ProgrammeFolderListItemModel) {
        if (value.programmes && value.folder) {
            this._folderItemModel = value;
        } else {
            this._folderItemModel = {programmes: [], folder: {}}
        }

    }

    get folderItemModel() {
        return this._folderItemModel;
    }

    constructor(
    ) { }

    onCancel() {
        this.eventDelegate.onDelProgrammeFolderCancel({});
    }

    onConfirm() {
        // if (this.folderItemModel && this.folderItemModel.programmes.length > 0) { return; }
        this.eventDelegate.onDelProgrammeFolderConfirm(this._folderItemModel.folder);
    }



}
