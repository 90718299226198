import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {DelProgrammeConfirmDelegate} from './del-programme-confirm.delegate';
import {Programme} from '../../_models/programme';

@Component({
    selector: 'amp-del-programme-confirm',
    templateUrl: './del-programme-confirm.component.html',
    styleUrls: ['./del-programme-confirm.component.scss']
})
export class DelProgrammeConfirmComponent {

    @Input() eventDelegate: DelProgrammeConfirmDelegate;
    @Input() programme: Programme;

    constructor(
    ) { }

    onCancel() {
        this.eventDelegate.onDelProgrammeCancel({});
    }

    onConfirm() {
        this.eventDelegate.onDelProgrammeConfirm(this.programme);
    }

}
