import {Component, Input, } from '@angular/core';
import DelScheduleConfirmDelegate from './del-schedule-form.delegate';
import {Schedule} from '../../_models/schedule';

@Component({
    selector: 'amp-del-schedule-confirm',
    templateUrl: './del-schedule-confirm.component.html',
    styleUrls: ['./del-schedule-confirm.component.scss']
})
export class DelScheduleConfirmComponent {

    @Input() eventDelegate: DelScheduleConfirmDelegate;
    @Input() schedule: Schedule;

    constructor(
    ) { }

    onCancel() {
        this.eventDelegate.onDelScheduleCancel({});
    }

    onConfirm() {
        this.eventDelegate.onDelScheduleConfirm(this.schedule);
    }

}
