import {ApplicationRef, Component, OnInit} from '@angular/core';

import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";



@Component({
  selector: 'cp-tabs',
  templateUrl: './cp-tabs.component.html'
})

export class CPTabsComponent implements OnInit {

  isLoading = false;

  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true
  }

}
