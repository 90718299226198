import {ListItemFactory} from '../../common-components/master-view-list/interfaces/list-item.factory';
import {List, ListItem} from '../../common-components/master-view-list/models/list-item';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';

const ARTISTS_HEADING = 'Artists';

const NUMERIC_CATEGORY = '0 - 9';
const OTHER_CATEGORY = 'Other';
const categoriseArtistByLetterReducer = (accumulator, currentValue: {id: number, name: string}) => {
    const currentAlphaNum: string = currentValue.name[0].toUpperCase();


    const isAlpha = currentAlphaNum.match(/[A-Z]/i);
    const isNumeric = !Number.isNaN(Number(currentAlphaNum));
    const isOther = !isAlpha && !isNumeric;
    let category = currentAlphaNum;

    if (isNumeric) {
        category = NUMERIC_CATEGORY;
    } else if (isOther) {
        category = OTHER_CATEGORY;
    }

    if (accumulator[category]) {
        accumulator[category].push(currentValue);
    } else {
        accumulator[category] = [currentValue];
    }
    return accumulator;
};

const buildArtistsToListMap = (config: ListItemConfig) => {
    return (artist) => {
        return new ListItem(config, () => { return artist.name }, artist);
    };
};

const buildArtistCategorisationMap = (config: ListItemConfig) => {
    return (categoryEntry) => {
        return new List(config, () => { return categoryEntry[0] }, categoryEntry, categoryEntry[1].map(buildArtistsToListMap(config)));
    };
};

export class AudioLibraryArtistListItemFactory implements ListItemFactory<Array<object>> {
    buildListItem(model: Array<object>, config: ListItemConfig): ListItem {
        const categorisedArtists = model.reduce(categoriseArtistByLetterReducer, {});
        return new List(config, () => {return ARTISTS_HEADING }, model, Object.entries(categorisedArtists).map(buildArtistCategorisationMap(config)));
    }
}
