import {Component, OnInit} from '@angular/core';
import {AccountService} from "../_services/account.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../_services/validation.service";
import {AlertService} from "../_alert/alert.service";
import {AccountComponent} from "../account/account.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'amp-login-details-form',
  templateUrl: './login-details-form.component.html'
})
export class LoginDetailsFormComponent implements OnInit {

  form: FormGroup;
  isLoading = false;

  constructor(private formBuilder: FormBuilder,
              public account: AccountService,
              private validation: ValidationService,
              private alert: AlertService,
              private accountComponent: AccountComponent,
              private translate: TranslateService) {
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
        password1: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
        password2: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
      }, {validator: this.validation.equalityValidator("password1", "password2")}
    );

  }

  onSubmit(form) {
    this.isLoading = true;
    this.account.updateAccountPassword(form.password1)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe(response => {
          this.accountComponent.exitEditModes();
          this.alert.success(this.translate.instant("log-in-details-have-been-updated"));
        },
        error => {
          this.accountComponent.exitEditModes();
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  cancel() {
    this.accountComponent.exitEditModes();
  }

  isPasswordGroupInvalid() {
    return this.form.hasError('notEqual') && this.form.get('password1').touched && this.form.get('password2').touched
  }

  isPassword1Invalid() {
    return this.form.get('password1').invalid && this.form.get('password1').touched
  }

  isPassword2Invalid() {
    return this.form.get('password2').invalid && this.form.get('password2').touched
  }

}
