import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService} from "../../_services/account.service";
import {Business} from "../../_models/business";
import {ValidationService} from "../../_services/validation.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {AppComponent} from "../../app.component";
import {Router, ActivatedRoute, Params, Route} from '@angular/router';
import {TrialProductPayload} from "../../_rest/payloads/product-payload";

@Component({
  selector: 'trial-form',
  styleUrls: ['../../../../node_modules/@ng-select/ng-select/themes/default.theme.css', './trial.component.scss'],
templateUrl: './trial.component.html',
  encapsulation: ViewEncapsulation.None
})



export class TrialComponent implements OnInit {

  form: FormGroup;
  isLoading = false;
  resellerId : string;
  businessType : number = 1;
  public trialProducts: TrialProductPayload[];
  public selectedTrialProduct: TrialProductPayload;

  constructor(private app: AppComponent,
            private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private account: AccountService,
              private validation: ValidationService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {

    let reseller: string = this.route.snapshot.queryParams["reseller"];
    this.resellerId = reseller.substr(2, 5);
    this.businessType = this.route.snapshot.queryParams["type"];
    this.app.setBackgroundImage("assets/img/Trial.Type." + this.businessType + ".jpg");

    this.account.fetchTrialProducts()
      .subscribe((trialProducts: TrialProductPayload[]) => {
        this.trialProducts = trialProducts;
        this.selectedTrialProduct = trialProducts[this.businessType-1];
      });
    this.form = this.formBuilder.group({
      companyName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      email: ["", [Validators.required, this.validation.emailValidator()]],
      password: this.formBuilder.group({
        password1: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
        password2: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]]
      }, {validator: this.validation.equalityValidator("password1", "password2")}),
      trialProduct: [null, []]
    });

  }


  onSubmit(form) {
    let business: Business = new Business(
      form.companyName,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      form.email
    );
    this.isLoading = true;
    this.account.registerTrialAccount(business, form.password.password1, form.password.password2, this.resellerId, this.selectedTrialProduct.productRenditionCode)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe(response => {
          this.router.navigate(['/login'], {queryParams: {fromReg: true}});
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          if (response.responseCode == 31)
          {
            this.alert.error(this.translate.instant("invalid-business-number"));
          }
          else if (response.responseCode == 32) {
            this.alert.error(this.translate.instant("business-number-check-unavailable"));
          }
          else
          {
            this.alert.error(response.responseData);
          }
        });
  }

  isPasswordGroupInvalid() {
    return this.form.get('password').hasError('notEqual') && this.form.get('password.password1').touched && this.form.get('password.password2').touched
  }

  isPassword1Invalid() {
    return this.form.get('password.password1').invalid && this.form.get('password.password1').touched
  }

  isPassword2Invalid() {
    return this.form.get('password.password2').invalid && this.form.get('password.password2').touched
  }


  onSelectedTrialProduct(trialProduct: TrialProductPayload) {
    this.selectedTrialProduct = trialProduct;
  }

  getDivId()
  {
    return "trial-" + this.businessType;
  }


  openLink (key)
  {
    var link;
    this.translate.get(key).subscribe(
      data => {
        link = data;
      });
      window.open(link);
  }

  public  type1or2() : boolean
  {
    return this.businessType == 1 || this.businessType == 2;
  }
}
