import {ChangeDetectorRef, Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AccountService} from "./_services/account.service";
import {Country} from "./_models/country";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})

export class AppComponent {

  public backgroundImage : string = 'assets/img/Login.jpg';

  ampLink = "https://s3.eu-central-1.amazonaws.com/amp-releases/v8/Atmosphere-9.7.1-b1220.dmg";
  hospitalityLink = "http://wecreateatmosphere.com";
  spaLink = "http://en.atmosphere-spa.com";
  healthcareLink = "http://en.atmosphere-healthcare.com";
  newsLink = "http://wecreateatmosphere.com/#news";

  public mediaTypes = [
    {id: 1, label: "Video Playlist"},
    {id: 2, label: "Audio Playlist"}
  ]

  public playlistTypes = [
    {id: 1, label: "Standard Playlist"},
    {id: 2, label: "Filtered Playlist"},
      {id: 3, label: "Combined Playlist"}
  ]

  public countries = [];
  private euCountries = {
    'AT': 1,
    'BE': 1,
    'BG': 1,
    'HR': 1,
    'CY': 1,
    'CZ': 1,
    'DK': 1,
    'EE': 1,
    'FI': 1,
    'FR': 1,
    'DE': 1,
    'EL': 1,
    'HU': 1,
    'IE': 1,
    'IT' : 1,
    'LV' : 1,
    'LT' : 1,
    'LU' : 1,
    'MT' : 1,
    'NL' : 1,
    'PL' : 1,
    'PT' : 1,
    'RO' : 1,
    'SK' : 1,
    'SI' : 1,
    'ES' : 1,
    'SE' : 1,
    'GB' : 1
  };

  constructor(private translate: TranslateService,
              public account: AccountService) {

    translate.setDefaultLang("de");
    translate.use(account.getLanguage());

    translate.setTranslation("en", {

      "amp-link" : this.ampLink,
      "hospitality-link" : this.hospitalityLink,
      "spa-link" : this.spaLink,
      "healthcare-link" : this.healthcareLink,
      "news-link" : this.newsLink,

      "never" : "Never",
      "home" : "Home",

      "client-id" : "Client ID",
      "client-name" : "Client Name",
      "client-phone" : "Client Phone",
      "client-email" : "Contact Email",
      "installation-count" : "Number of Installations",


      "venues": "Venues",
      "shop": "Shop",
      "licences": "Licences",
      "account": "Account",
      "log-out": "Log Out",
      "log-in": "Log In",

      "venue-account-id": "Venue Account ID",
      "venue-name": "Venue Name",
      "installation-name": "Installation Name",
      "product-name": "Product Name",
      "licence-code": "Licence Code",
      "licence-status": "Licence Status",
      "licence-expiry": "Expiry Date",

      "unassigned": "Unassigned",
      "awaiting-activation": "Awaiting Activation",
      "active": "Active",
      "expired": "Expired",
      "undefined": "Undefined",

      "products": "Products",
      "movies": "Movies",
      "buy": "Buy",
      "purchased": "Purchased",

      "login-details": "Login Details",
      "business-details": "Business Details",

      "account-identifier" : "Account Identifier",
        "venue-identifier" : "Venue Identifier",
      "company-name": "Business Name",
      "contact-name": "Contact Name",
      "company-address1": "Address 1",
      "company-address2": "Address 2",
      "company-postcode": "Postcode",
      "company-city": "City",
      "company-state": "State",
      "company-country": "Country",
      "company-phone": "Phone",
      "contact-email": "Contact Email",

      "password": "Password",
      "confirm-password": "Confirm Password",
      "passwords-must-match": "Passwords must match",

      "save": "Save",
      "register": "Register",
      "register-trial-heading": "Register for the free atmosphere UHD Trial Version",
      "register-trial-description-1": "With the download of the atmosphere Trial Version you get the full atmosphere media player© and a wide selection of atmosphere films (demo versions). Convince yourself of the unique and fascinating atmosphere content and discover the versatile features of the atmosphere media player©. The atmosphere media player© has been developed for the Apple MacOs Platform (10.13 o newer) and is compatible with any Apple computer (iMac, MacMini, MacBook, MacPro from 2012).",
      "register-trial-description-2": "The atmosphere Trial Version has a duration of 30 days. To purchase a full license please contact your dealer.",
      "register-trial-button-help": "Clicking on the button \"Free 30 day Trial\” will create your account and you will receive an e-mail with a account validation link and another email with your trial licence.",
      "register-trial-button": "Free 30 day Trial",
      "edit": "Edit",
      "change": "Change",
      "update": "Update",
      "cancel": "Cancel",

      "value-required": "Value is required",
      "value-min-length": "Value must be greater than {{minLength}} characters",
      "value-max-length": "Value must be less than {{maxLength}} characters",
      "plusVAT" : "plus VAT",



      "congratulations" : "Congratulations",
      "registration-success" : "Registration has been successful.<br/>An email has been sent to your registered email address to validate the account.<br/>Once the account has been validated you will be able to login.",
      "password-reset" : "Password Reset",
      "password-reset-success" : "Password reset was successful.<br/>An email has been sent to your registered email address. Please use the supplied password to log in and set your preferred password as soon as is possible.",

      "forgot-password": "Forgot Password?",
      "reset-password": "Reset Password",
      "an-email-will-be-sent" : "An email will be sent to the registered email address with your new password.<br/>You should change this password as soon as possible for security reasons.",

      "invalid-email": "Invalid Email",
      "invalid-identifier": "Invalid Identifier",

      "unable-to-connect-to-the-server" : "Unable to connect to the server.",
      "log-in-details-have-been-updated" : "Log In Details have been updated.",
      "account-details-have-been-updated" : "Account Details have been updated.",
      "invalid-account-identifier-or-password" : "Invalid account identifier or password.",



      "licence-was-assigned" : "Licence was assigned.",
      "licence-was-renewed" : "licence was renewed",
      "licence-was-expired" : "licence was expired",



      "format": "Format",
      "cinematographer" : "Director of Photography",
      "sound": "Sound",
      "video-and-audiocodec": "Video and Audiocodec",

      "description": "Description",

      "agreeEULA" : "I have read and agree to the End User License Agreement (EULA).",
      "agreeEULA1" : "I have read and agree to the <a href=\'#\' onclick=\"openLink(\'EULALink\');  false;\">End User License Agreement</a>",

      "EULALink" : "http://support.atmosphere-media.com/eula",
      "agreeTCs" : "I am aware that by this consent I will lose my right of withdrawal for digital content.",
      "accept-eula": "You must accept to the EULA",
      "accept-tcs": "You must agree to the Terms and Conditions with respect to digital content",

      "DataPrivacy" : "Data Privacy. Personal data are not passed on to third parties. atmosphere media GmbH uses personal data only to communicate with the client with information necessary for the correct operation of the platform (i.e. the registration process, purchase invoices, license expiry/renewal, and terms, conditions and policies).",
      "agreePrivacy" : "I consent to the atmosphere media GmbH data privacy policy.",
      "accept-privacy": "You must agree to the atmosphere media GmbH Data Privacy Policy.",


      "company-business-number": "VAT Number (without country code, i.e. DE, AT, FR etc.)",
      "vat-number-check-unavailable": "VAT Number check unavailable at this time",
      "invalid-business-number": "Invalid VAT number",


      "resellers" : "Resellers",

      "client-accounts" : "Client Accounts",
      "reseller-name" : "Reseller Name",
      "artist-name" : "Artist Name",
      "content-item-name" : "Movie Name",
      "number-of-plays" : "Number of Plays",
      "movies-by-play-count" : "Movies By Play Count",

      "find-out-more" : "To learn more about the various atmosphere products, visit the websites below or email us at <a href=\"mailto:sales@atmosphere-media.com\"><span class='amp-link'>sales@atmosphere-media.com</span></a>",
      "trial-product" : "Trial Product",
      "trial-msg-1" : "Your account is a trial account.",
      "trial-msg-2" : "See <a  href=\"http://wecreateatmosphere.com#contact\"  target='_blank'><span class='amp-link'>atmosphere corporate website</span></a> for details on how to purchase or update a subscription.",

      "shop-msg-1" : "The atmosphere shop is unavailable at present.",
      "shop-msg-2" : "A new improved atmosphere shop will return in the near future.",

      // Countries
      countries : [
        new Country ('AF', 'Afghanistan'),
        new Country ('AL', 'Albania'),
        new Country ('DZ', 'Algeria'),
        new Country ('AR', 'Argentina'),
        new Country ('AM', 'Armenia'),
        new Country ('AT', 'Austria'),
        new Country ('AU', 'Australia'),
        new Country ('AZ', 'Azerbaijan'),
        new Country( 'BE', 'Belgium'),
        new Country( 'BR', 'Brazil'),
        new Country( 'BG', 'Bulgaria'),
        new Country( 'CA', 'Canada'),
        new Country( 'CN', 'China'),
        new Country( 'HR', 'Croatia'),
        new Country( 'CY', 'Cyprus'),
        new Country( 'CZ', 'Czech Republic'),
        new Country( 'DE', 'Germany'),
        new Country( 'DK', 'Denmark'),
        new Country( 'EG', 'Egypt'),
        new Country( 'EE', 'Estonia'),
        new Country( 'FI', 'Finland'),
        new Country( 'FR', 'France'),
        new Country( 'HK', 'Hong Kong'),
        new Country( 'HU', 'Hungary'),
        new Country( 'IN', 'India'),
        new Country( 'IE', 'Ireland'),
        new Country( 'IC', 'Iceland'),
        new Country( 'IL', 'Israel'),
        new Country( 'IT', 'Italy'),
        new Country( 'KW', 'Kuwait'),
        new Country( 'JP', 'Japan'),
        new Country( 'LV', 'Latvia'),
        new Country( 'LT', 'Lithuania'),
        new Country( 'LU', 'Luxembourg'),
        new Country( 'MT', 'Malta'),
        new Country( 'ML', 'Monaco'),
        new Country( 'MX', 'Mexico'),
        new Country( 'NL', 'Netherlands'),
        new Country( 'NZ', 'New Zealand'),
        new Country( 'NO', 'Norway'),
        new Country( 'PH', 'Philippines'),
        new Country( 'PL', 'Poland'),
        new Country( 'PT', 'Portugal'),
        new Country( 'QA', 'Qatar'),
        new Country( 'RO', 'Romania'),
        new Country( 'RU', 'Russian Federation'),
        new Country( 'SA', 'Saudi Arabia'),
        new Country( 'SG', 'Singapore'),
        new Country( 'SK', 'Slovak Republic'),
        new Country( 'SI', 'Slovenia'),
        new Country( 'ZA', 'South Africa'),
        new Country( 'KR', 'South Korea'),
        new Country( 'ES', 'Spain'),
        new Country( 'SE', 'Sweden'),
        new Country( 'CH', 'Switzerland'),
        new Country( 'TH', 'Thailand'),
        new Country( 'TN', 'Tunisia'),
        new Country( 'TK', 'Turkey'),
        new Country ('AE', 'United Arab Emirates'),
        new Country( 'GB', 'United Kingdom'),
        new Country( 'US', 'United States of America'),
        new Country( 'VN', 'Vietnam')

      ]

    });


    translate.setTranslation("de", {

      "amp-link" : this.ampLink,
      "hospitality-link" : this.hospitalityLink,
      "spa-link" : this.spaLink,
      "healthcare-link" : this.healthcareLink,
      "news-link" : this.newsLink,

      "never" : "Never",
      "home" : "Home",

      "client-id" : "Client ID",
      "client-name" : "Client Name",
      "client-phone" : "Client Phone",
      "client-email" : "Contact Email",
      "installation-count" : "Number of Installations",

      "venues": "Venues",
      "shop": "Shop",
      "licences": "Lizenzen",
      "account": "Konto",
      "log-out": "Abmelden",
      "log-in": "Einloggen",

      "venue-account-id": "Konto-ID-Nummer Venue",
      "venue-name": "Ort der Installation",
      "installation-name": "Installationsname",
      "product-name": "Produktname",
      "licence-code": "Lizenzcode",
      "licence-status": "Lizenzstatus",
      "licence-expiry": "Expiry Date",

      "unassigned": "Nicht zugewiesen",
      "awaiting-activation": "Warten auf Aktivierung",
      "active": "Aktiv",
      "expired": "Abgelaufen",
      "undefined": "Undefiniert",

      "products": "Produkte",
      "movies": "Filme",
      "buy": "Kaufen",
      "purchased": "Gekauft",

      "login-details": "Zugangsdaten",
      "business-details": "Firmenangaben",

      "account-identifier" : "Konto-ID-Nummer",
        "venue-identifier" : "Venue-ID-Nummer",
      "company-name": "Firmenname",
      "contact-name": "Kontaktname",
      "company-address1": "Firmenanschrift 1",
      "company-address2": "Firmenanschrift 2",
      "company-postcode": "Postleitzahl",
      "company-city": "Stadt",
      "company-state": "Bundesland",
      "company-country": "Land",
      "company-phone": "Telefonnummer",
      "contact-email": "E-Mail-Kontakt",

      "password": "Passwort",
      "confirm-password": "Passwort bestätigen",
      "passwords-must-match": "Die Passwörter müssen übereinstimmen",

      "save": "Sichern",
      "register": "Registrieren",
      "register-trial-heading": "Registrieren Sie sich hier für die kostenlose atmosphere UHD-Demo Version",
      "register-trial-description-1": "Mit dem Download der atmosphere Demo Version erhalten Sie den vollständigen atmosphere media player© und eine große Auswahl von atmosphere Filmen (Demo Version). So können Sie sich in Ruhe von dem faszinierenden atmosphere Content überzeugen und sich mit den vielseitigen Möglichkeiten des atmosphere Players vertraut machen. Der atmosphere media player© funktioniert mit jedem Apple Rechner (iMac; MacMini, MacBook, MacPro ab 2012) und einem Betriebssystem ab macOS 10.13.",
      "register-trial-description-2": "Die atmosphere Demo Version hat eine Laufzeit von 30 Tagen. Für die Lizenzierung einer atmosphere Vollversion wenden Sie sich bitte an Ihren Händler.",
      "register-trial-button-help": "Wenn Sie den Button \"Jetzt kostenlos 30 Tage testen\" drücken bekommen Sie von uns eine E-Mail mit Ihren persönlichen Zugangsdaten.",
      "register-trial-button": "Jetzt kostenlos 30 Tage testen",
      "edit": "Bearbeiten",
      "change": "Ändern",
      "update": "Aktualisieren",
      "cancel": "Abbrechen",

      "value-required": "Wert ist erforderlich",
      "value-min-length": "Mindestlänge {{minLength}} Zeichen",
      "value-max-length": "Maximal {{maxLength}} Zeichen",
      "plusVAT" : "zzgl. gesetzl. MwSt",


      "congratulations" : "Vielen Dank",
      "registration-success" : "Registrierung erfolgreich.<br/>Eine E-Mail wurde an Ihre registrierte E-Mail-Adresse gesendet, um das Konto zu bestätigen.<br/>Sobald das Konto bestätigt wurde, können Sie sich einloggen.",
      "password-reset" : "Passwort zurücksetzen",
      "password-reset-success" : "Ihr Password wurde erfolgreich zurückgesetzt.<br/>Eine E-Mail wurde an Ihre registrierte E-Mail-Adresse gesendet. Bitte benutzen Sie das mitgelieferte Passwort, um sich einzuloggen und ändern Sie es schnellstmöglich.",

      "forgot-password": "Passwort vergessen?",
      "reset-password": "Passwort Zurücksetzen",
      "an-email-will-be-sent" : "Eine E-Mail wird and die registrierte E-Mail-Adresse mit Ihrem neuen Passwort gesendet.<br/>Sie sollten dieses Passwort aus Sicherheitsgründen so schnell wie möglich ändern.",

      "invalid-email": "Ungültige E-Mail",
      "invalid-identifier": "Ungültige ID-Nummer",


      "licence-was-assigned" : "Licence was assigned.",
      "licence-was-renewed" : "licence was renewed",
      "licence-was-expired" : "licence was expired",

      "unable-to-connect-to-the-server" : "Kann nicht mit dem Server verbunden werden.",
      "log-in-details-have-been-updated" : "Anmeldedaten wurden aktualisiert.",
      "account-details-have-been-updated" : "Kontodaten wurden aktualisiert.",
      "invalid-account-identifier-or-password" : "Ungültige ID-Nummer oder Passwort.",

      "format": "Format",
      "cinematographer" : "Director of Photography",
      "sound": "Ton",
      "video-and-audiocodec": "Video und Audiocodec",

      "description": "Beschreibung",

      "agreeEULA" : "Ich habe den Endbenutzer-Lizenzvertrag (EULA) gelesen und stimme diesem zu.",
      "EULALink" : "http://support.atmosphere-media.com/eula-deutsch",
      "agreeTCs" : "Mir ist bekannt, dass ich durch diese Zustimmung mein Widerrufsrecht für Digital-Download Inhalte verliere.",
      "accept-eula": "Ich habe den Endbenutzer-Lizenzvertrag (EULA) gelesen und stimme diesem zu",
      "accept-tcs": "Mir ist bekannt, dass ich durch diese Zustimmung mein Widerrufsrecht für Digital-Download Inhalte verliere.",

      "DataPrivacy" : "Datenschutz. Personenbezogene Daten werden nicht an Dritte weitergegeben. atmosphere media GmbH verwendet personenbezogene Daten nur, um Kunden mit Informationen zu versorgen, die für den korrekten Betrieb der Plattform erforderlich sind (z. B. Registrierungsprozess, Rechnungen, Lizenzablauf / -erneuerung und Bedingungen, Geschäftsbedingungen und Richtlinien).",
      "agreePrivacy" : "Ich stimme der Datenschutzerklärung für die atmosphere media GmbH zu.",
      "accept-privacy": "Hiermit erkläre ich mich mit der Datenschutzerklärung der atmosphere media GmbH einverstanden.",



      "company-business-number": "USt-ID-Nr. (ohne Landerkennung, d.h. DE, AT,FR usw.)",
      "vat-number-check-unavailable": "MwSt.-Nr. Überprüfung zur Zeit nicht verfügbar",
      "invalid-business-number": "Ungültige MwSt.-Nr.",

      "resellers" : "Resellers",
      "content-providers" : "Content Providers",
      "client-accounts" : "Client Accounts",
      "reseller-name" : "Reseller Name",
      "artist-name" : "Artist Name",
      "content-item-name" : "Movie Name",
      "number-of-plays" : "Number of Plays",
      "movies-by-play-count" : "Movies By Play Count",

      "find-out-more" : "Wenn Sie mehr über die verschiedenen atmosphere Produkte erfahren wollen besuchen Sie unsere Websites oder schreiben Sie uns unter <a href=\"mailto:sales@atmosphere-media.com\"><span class='amp-link'>sales@atmosphere-media.com</span></a>.",
      "trial-product" : "Auswahl Demo Produkt",
      "trial-msg-1" : "Sie haben ein Testkonto.",
      "trial-msg-2" : "Weitere Informationen zur aktualisierung Ihrer Lizenz oder zu Ihrem Abonnement, finden Sie unter <a href=\"http://wecreateatmosphere.com#contact\" target='_blank'><span class='amp-link'>atmosphere Kontakt</span></a>.",

      "shop-msg-1" : "Der atmosphere Shop ist derzeit nicht verfügbar.",
      "shop-msg-2" : "Ein verbesserter atmosphere Shop wird bald verfügbar sein.",

      // Countries
      countries : [

        new Country ('AF', 'Afghanistan'),
        new Country( 'EG', 'Ägypten'),
        new Country ('AL', 'Albanien'),
        new Country ('DZ', 'Algerien'),
        new Country ('AR', 'Argentinien'),
        new Country ('AT', 'Österreich'),
        new Country ('AM', 'Armenien'),
        new Country ('AZ', 'Aserbaidschan'),
        new Country ('AU', 'Australien'),
        new Country( 'BE', 'Belgien'),
        new Country( 'BR', 'Brasilien'),
        new Country( 'BG', 'Bulgarien'),
        new Country( 'CN', 'China'),
        new Country( 'DE', 'Deutschland'),
        new Country( 'DK', 'Dänemark'),
        new Country( 'EE', 'Estland'),
        new Country( 'FI', 'Finnland'),
        new Country( 'FR', 'Frankreich'),
        new Country( 'HK', 'Hongkong'),
        new Country( 'IN', 'Indien'),
        new Country( 'IE', 'Irland'),
        new Country( 'IC', 'Island'),
        new Country( 'IL', 'Israel'),
        new Country( 'IT', 'Italien'),
        new Country( 'JP', 'Japan'),
        new Country( 'CA', 'Kanada'),
        new Country( 'QA', 'Katar'),
        new Country( 'HR', 'Kroatien'),
        new Country( 'KW', 'Kuwait'),
        new Country( 'LV', 'Lettland'),
        new Country( 'LT', 'Litauen'),
        new Country( 'LU', 'Luxemburg'),
        new Country( 'MT', 'Malta'),
        new Country( 'ML', 'Monaco'),
        new Country( 'MX', 'Mexiko'),
        new Country( 'NL', 'Niederlande (Holland)'),
        new Country( 'NZ', 'Neuseeland'),
        new Country( 'NO', 'Norwegen'),
        new Country ('AT', 'Österreich'),
        new Country( 'PH', 'Philippinen'),
        new Country( 'PL', 'Polen'),
        new Country( 'PT', 'Portugal'),
        new Country( 'RO', 'Rumänien'),
        new Country( 'RU', 'Russische Föderation (Russland)'),
        new Country( 'SA', 'Saudi-Arabien'),
        new Country( 'CH', 'Schweiz'),
        new Country( 'SG', 'Singapur'),
        new Country( 'SK', 'Slowakei (vormals Teil der Tschechoslowakei)'),
        new Country( 'SI', 'Slowenien'),
        new Country( 'ES', 'Spanien'),
        new Country( 'ZA', 'Südafrika'),
        new Country( 'KR', 'Süd Koreaa'),
        new Country( 'SE', 'Sweden'),
        new Country( 'TH', 'Thailand'),
        new Country( 'CZ', 'Tschechien (Tschechische Republik)'),
        new Country( 'TN', 'Tunesien'),
        new Country( 'TK', 'Türkei'),
        new Country( 'HU', 'Ungarn'),
        new Country ('AE', 'Vereinigte Arabische Emirate'),
        new Country( 'GB', 'Vereinigtes Königreich (England)'),
        new Country( 'US', 'Vereinigte Staaten (USA)'),
        new Country( 'VN', 'Vietnam'),
        new Country( 'CY', 'Zypern')

      ]


    });

    this.translate.get('countries').subscribe( (lands : any) => {
      this.countries = lands;
    });
  }

  countryForCode (code)
  {
    for (var i = 0; i < this.countries.length; i++)
    {
      var c = this.countries[i];
      if (c.code === code) {
        return c;
      }
    }
    return "";
  }


  isEuCountry(code) {
    return this.euCountries[code];
  }

  setBackgroundImage (imageURL : string)
  {
    this.backgroundImage = imageURL;
  }

  isAdminAccount() : boolean
  {
    return this.account != null && this.account.isAdmin();
  }

  colClass() : string
  {
    if (this.isAdminAccount())
      return "col-10";
    return "col-8";
  }

  getAccountName() : string {
    return this.account.getAccountName();
  }

  logout()
  {
    this.account.logout();
  }

  msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ':' + mins + ':' + secs;
  }


  static encodeURI(uri: string): string
  {
      var uriEncoded = encodeURI(uri);
      // console.log('uri:' + uri);
      // console.log('uriEncoded:' + uriEncoded);

      uriEncoded = uriEncoded.replace(/'/g, '%27');
      uriEncoded = uriEncoded.replace(/#/g, '%23');
      // European
      // encodeURI uses e%CC%80 for french e backtick for example, re-encode this.
      uriEncoded = uriEncoded.replace(/é/g, '%C3%A9');
      // uriEncoded = uriEncoded.replace(/à/g, '%C3%A0');
      uriEncoded = uriEncoded.replace(/a%CC%80/g, '%C3%A0');
      // uriEncoded = uriEncoded.replace(/è/g, '%C3%A8');
      uriEncoded = uriEncoded.replace(/e%CC%80/g, '%C3%A8');
        // Forward Accented
      uriEncoded = uriEncoded.replace(/e%CC%81/g, '%C3%A9');
      uriEncoded = uriEncoded.replace(/E%CC%81/g, '%C3%89');
      uriEncoded = uriEncoded.replace(/a%CC%81/g, '%C3%A1');
      uriEncoded = uriEncoded.replace(/o%CC%81/g, '%C3%B3');
      uriEncoded = uriEncoded.replace(/A%CC%81/g, '%C3%81');
      uriEncoded = uriEncoded.replace(/u%CC%81/g, '%C3%BA');
      uriEncoded = uriEncoded.replace(/I%CC%81/g, '%C3%8D');
      uriEncoded = uriEncoded.replace(/U%CC%81/g, '%C3%9A');

      // Back accented
      uriEncoded = uriEncoded.replace(/A%CC%80/g, '%C3%80');
      uriEncoded = uriEncoded.replace(/o%CC%80/g, '%C3%B2');
      uriEncoded = uriEncoded.replace(/E%CC%80/g, '%C3%88');
      uriEncoded = uriEncoded.replace(/i%CC%80/g, '%C3%AC');
      uriEncoded = uriEncoded.replace(/u%CC%80/g, '%C3%B9');

        // Signalefa
      uriEncoded = uriEncoded.replace(/a%CC%83/g, '%C3%A3');
      uriEncoded = uriEncoded.replace(/n%CC%83/g, '%C3%B1');

      uriEncoded = uriEncoded.replace(/c%CC%A7/g, '%C3%A7');
      uriEncoded = uriEncoded.replace(/o%CC%82/g, '%C3%B4');
      uriEncoded = uriEncoded.replace(/C%CC%A7/g, '%C3%87');
      uriEncoded = uriEncoded.replace(/e%CC%82/g, '%C3%AA');
      uriEncoded = uriEncoded.replace(/a%CC%82/g, '%C3%A2');
        // Umlauts
      uriEncoded = uriEncoded.replace(/e%CC%88/g, '%C3%AB');
      uriEncoded = uriEncoded.replace(/u%CC%88/g, '%C3%BC');
      uriEncoded = uriEncoded.replace(/o%CC%88/g, '%C3%B6');
      uriEncoded = uriEncoded.replace(/a%CC%88/g, '%C3%A4');
      uriEncoded = uriEncoded.replace(/A%CC%88/g, '%C3%84');
      uriEncoded = uriEncoded.replace(/O%CC%88/g, '%C3%96');
      uriEncoded = uriEncoded.replace(/U%CC%88/g, '%C3%9C');

      // console.log('uriEncoded:' + uriEncoded);
      return uriEncoded;

  }

}
