import {ApplicationRef, Component, OnInit} from '@angular/core';
import {Licence} from "../../_models/licence";
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Installation} from "../../_models/installation";
import { CPLicencesComponent } from "../cp-licences/cp-licences.component";

@Component({
  selector: 'cp-home',
  templateUrl: './cp-home.component.html'
})
export class CPHomeComponent implements OnInit {


  isLoading = false;
  installationsMap = {};


  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true;
  }
}
