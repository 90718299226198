import {environment} from '../../environments/environment';
import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
// import {map} from "rxjs/operators";
import {Router} from "@angular/router";
import {Observable} from "rxjs/Observable";
import {Product} from "../_models/product";
import {ProductsResponse} from "../_rest/products-response";
import {ProductResponse} from "../_rest/product-response";
import {ProductPayload} from "../_rest/payloads/product-payload";
import {Movie} from "../_models/movie";
import {PurchaseOrderRequest} from "../_rest/purchase-order-request";
import {AccountService} from "./account.service";
import {AppComponent} from '../app.component';

@Injectable()
export class ProductService {

  constructor(private http: HttpClient,
              private router: Router,
              private account: AccountService) {
  }

  public fetchProducts(): Observable<any[]> {
    let url = `${environment.server}/ampapi/account/${this.account.accountId}/products`;
     let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
    // return this.http.get(url, options)
    //   .map((response: Response) => (<ProductsResponse>response.json()).responseData)
    //   .map((productPayloads: ProductPayload[]) => {
    //
    //     var products: Product[] = [];
    //
    //     for (let productPayload of productPayloads) {
    //
    //       var filename1 = encodeURI(productPayload.productName);
    //       var filename2 = productPayload.productName.replace(/\s/g, "_");
    //
    //       let product = this.productFromPayload(productPayload);
    //       product.productImage = `${environment.imageServer}/atmosphere-images/${environment.imageDirectory}/Products/${filename1}/${filename2}`;
    //       products.push(product);
    //     }
    //
    //     return products;
    //   })
      return this.http.get(url, options)
          .map((response: HttpResponse<any>) => {
              return response.body.responseData;
          });
  }

  public fetchProduct(productId: number): Observable<Product> {
    let url = `${environment.server}/ampapi/account/${this.account.accountId}/shop/product/${productId}`;
     let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
    return this.http.get(url, options)
      .map((response: HttpResponse<any>) => (response.body.responseData)
      .map((productPayload: ProductPayload) => {

        let product = this.productFromPayload(productPayload);

        var filename1 = encodeURI(productPayload.productName);
        var filename2 = productPayload.productName.replace(/\s/g, "_");
        product.productImage = `${environment.imageServer}/atmosphere365.images/${environment.imageDirectory}/Products.v2/${filename1}/${filename2}`;

        for (let movie of productPayload.movies) {

          let movieView: Movie = new Movie(movie.id, movie.identifier, movie.name, movie.promoVideoId);

          for (let translation of movie.translations) {
            if (translation.language === this.account.getLanguage()) {
              movieView.displayName = translation.displayName;
              movieView.itemDescription = translation.itemDescription;
              movieView.summary = translation.summary;
              var runtimePathNoExt = movie.runtimePath.replace(/\.amp$/, "");
              var runtimePath = encodeURI(runtimePathNoExt);
              var tempFilename = movieView.name.replace(/\s/g, "_");
              var filename = tempFilename.replace(/\&/g, "and");
              movieView.imagePath = `${environment.imageServer}/atmosphere-images/${environment.imageDirectory}/Content/UHD/${runtimePath}/${filename}`;
              product.movies.push(movieView);
            } // if

          } // for

        } // for

        return product;
      }));

  }

  public openCheckout(product: Product) {

    var _self = this;

    let handler = (<any>window).StripeCheckout.configure({
      key: environment.stripeAPIKey,
      locale: "auto",
      token: function (token: any) {
        let url = `${environment.server}/ampapi/account/${_self.account.accountId}/shop/purchase`
        let body = new PurchaseOrderRequest(product.productCode, token.id, _self.account.accountId, product.currency, product.plusVAT);
        let options = {headers: _self.account.getHeaders()};
        _self.http.post(url, body, options)
          .map((response: Response) => response.json())
          .subscribe(data => {
            _self.router.navigate(["/shop/products"]);
          }, error => {
            console.log(JSON.stringify(error.json()));
          });

      }
    });

    handler.open({
      name: "atmosphere media",
      description: product.displayName,
      image: product.productImage + ".primary.jpg",
      currency: product.currency,
      email: this.account.getEmail(),
      allowRememberMe: false,
      amount: product.priceToPay()
    });

  } // openCheckout

  private  productFromPayload (productPayload : ProductPayload)
  {
    let product = new Product(
      productPayload.id,
      productPayload.productRenditionCode,
      productPayload.productName,
      productPayload.currency,
      productPayload.plusVAT,
      productPayload.price,
      productPayload.resellerPrice,
      productPayload.displayName,
      productPayload.productSummary,
      productPayload.productDescription,
      productPayload.licenced,
      productPayload.promoVideoId
    );
    return product;
  }


    private imageForNameAndType(productName: string, type: string) {
        const name = productName;
        const image = `${environment.imageServer}/${environment.imageDirectory}/Products.v2/${name}/${type}/${name}.png`;
        const img = AppComponent.encodeURI(image);
        return img;
    }


    public posterImage(name: string) : string
    {
        const img = this.imageForNameAndType(name, 'poster');
        return img;
    }

    public thumbnailImage(name: string) : string
    {
        const img = this.imageForNameAndType(name, 'thumbnail');
        return img;
    }
}
