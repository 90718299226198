import {PlaylistFilter} from './playlist-filter';

export class Playlist {
  constructor(public id: number,
              public name: string,
              public mediaType: number,
              public playlistType: number,
              public folderId: number,
              public movieIdentifiers: Array<any>,
              public movieIdentifiersAsStrings: Array<string>,
              public duration,
              public permanent: boolean,
              public accountId: number,
              public volumeLevel: number
              ) {
  }
}

export class FilteredPlaylist extends Playlist {

    constructor(public id: number,
                public name: string,
                public mediaType: number,
                public playlistType: number,
                public folderId: number,
                public movieIdentifiers: [],
                public movieIdentifiersAsStrings: Array<string>,
                public duration,
                public permanent: boolean,
                public accountId: number,
                public volumeLevel: number,
                public filters: Array<PlaylistFilter>) {
        super(id, name, mediaType, playlistType, folderId, movieIdentifiers, movieIdentifiersAsStrings, duration, permanent, accountId, volumeLevel);
    }
}
