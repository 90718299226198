import { Component, OnInit } from '@angular/core';
import { AccountService } from "../../_services/account.service";
import { Account } from "../../_models/account";
import { AlertService } from "../../_alert/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "../../app.component";
import { EditModeController, GenericEditModeController } from 'app/common-components/edit-mode/edit-mode.controller';
import { Business } from 'app/_models/business';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'app/_services/validation.service';

@Component({
  selector: 'amp-new-business-details',
  templateUrl: './new-business-details.component.html',
  styleUrls: ['./new-business-details.component.scss']
})
export class NewBusinessDetailsComponent implements OnInit {

  form: FormGroup;

  account: Account;
  updatedAccount: Account
  isLoading = false;
  public countries: any;


  // Edit Mode Management
  editModeController: EditModeController<Account>;

  constructor(private app: AppComponent,
    private accountService: AccountService,
    private alert: AlertService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private validation: ValidationService
  ) {

    this.countries = this.app.countries;

    this.editModeController = new GenericEditModeController(
      (obj) => this.saveEditMode(obj),
      (obj) => this.cancelEditMode(obj));

    this.form = this.formBuilder.group({
      companyName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      contactName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      addressLine1: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      addressLine2: ["", [Validators.minLength(2), Validators.maxLength(30)]],
      postcode: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      city: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      state: ["", [Validators.required]],
      country: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      businessNumber: [""],
      contactEmail: ["", [Validators.required, this.validation.emailValidator()]]
    });

  }

  ngOnInit() {
    this.account = new Account();

    this.isLoading = true;
    this.accountService.fetchAccount()
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe((account: Account) => {
        this.account = account;
        this.updatedAccount = account;
        account.countryRecord = this.app.countryForCode(account.country);

        this.updateForm(account);

      }, error => {
        if (error.status == 0) {
          this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
          return;
        }
        let response = JSON.parse(error.text());
        this.alert.error(response.responseData);
      });
  }

  public getCountry(): string {
    if (this, this.account != null && this.account.countryRecord != null)
      return this.account.countryRecord.name;
    return "";
  }

  updateForm(account: Account) {
    this.form.controls['companyName'].setValue(account.name);
    this.form.controls['contactName'].setValue(account.contactName);
    this.form.controls['phone'].setValue(account.phone);
    this.form.controls['businessNumber'].setValue(account.businessNumber);
    this.form.controls['contactEmail'].setValue(account.contactEmail);
    this.form.controls['addressLine1'].setValue(account.addressLine1);
    this.form.controls['addressLine2'].setValue(account.addressLine2);
    this.form.controls['postcode'].setValue(account.postcode);
    this.form.controls['city'].setValue(account.city);
    this.form.controls['state'].setValue(account.state);
    this.form.controls['country'].setValue(account.countryRecord);
  }


  saveEditMode(editObject: Account) {
    console.log(editObject, this.form.valid);

    if (!this.form.valid) {
      alert("Form input invalid")
      this.editModeController.startEditMode(editObject)
    } else {
      let business: Business = new Business(
        this.form.controls.companyName.value,
        this.form.controls.contactName.value,
        this.form.controls.addressLine1.value,
        this.form.controls.addressLine2.value,
        this.form.controls.city.value,
        this.form.controls.state.value,
        this.form.controls.country.value.code,
        this.form.controls.postcode.value,
        this.form.controls.businessNumber.value,
        this.form.controls.phone.value,
        this.form.controls.contactEmail.value
      );
      business.organisationId = this.account.id;

      this.accountService.updateAccountDetails(business)
        .subscribe(response => {
          console.log(response);
        }, error => {
          console.log(error);
        });
    }
  }

  cancelEditMode(cachedObject: Account) {
    this.updateForm(this.account);
  }
  

}
