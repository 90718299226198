import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Playlist} from "app/_models/playlist";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {VideoPreviewModalComponent} from "../video-preview-modal/video-preview-modal.component";
import {PlaylistComponent} from "app/playlist/playlist.component";
import {MultiSelectListMediator} from "app/common-components/master-view-list/interfaces/mulit-select-list";
import {Artist} from '../../_models/schedule';
import {AppComponent} from "app/app.component";
import Util from "app/Util";
import {ListItem} from '../../common-components/master-view-list/models/list-item';
import {LibraryModalDelegate} from '../../audio-library/library-action-menu/library-action-menu.component';

@Component({
    selector: "amp-video-lib-detail",
    templateUrl: "./video-lib-detail.component.html",
    styleUrls: ['./video-lib-detail.component.scss']
})
export class VideoLibDetailComponent implements OnInit {

    @Input() set contentItems(value: Array<object>) {
        this._contentItems = value
        this.gridLayoutRows = this.visibleGridContent()
    }

    get contentItems(): Array<object> {
        return this._contentItems
    }

    @Input() _contentItems: Array<object>;
    @Input() playlists: Array<Playlist>;
    @Input() artists: any;
    @Input() listType: string;
    @Input() multiSelectListMediator: MultiSelectListMediator;
    @Input() modalDelegate: LibraryModalDelegate;
    @Input() playlistListItems: Array<ListItem>;

    @Output() onViewMode: EventEmitter<any> = new EventEmitter();

    @ViewChild('gridContainer') element: ElementRef

    document = document;
    gridImageStyle;
    listImageStyle;
    gridLayoutRows = [];
    numberOfCols = 4;

    private util = new Util();

    constructor(private modalService: NgbModal, public app: AppComponent) {
        // this.handleImageStyle();
    }

    ngAfterViewInit(): void {
        this.calculateWidthOfColumnBasedOnScreenWidth()
    }

    ngOnInit(): void {
    }

    @HostListener('window:resize', ['$event'])
    handleResize() {
        this.calculateWidthOfColumnBasedOnScreenWidth()
        this.gridLayoutRows = this.visibleGridContent()
    }

    calculateWidthOfColumnBasedOnScreenWidth() {
        let cardWidth = 268;
        let cardMargin = 12;
        let totalCardWidth = cardWidth + cardMargin;
        let width = this.element.nativeElement.offsetWidth;
        let cols = Math.floor(width / totalCardWidth);
        this.numberOfCols = cols;
    }

    clickCard(item) {
        console.log("click");
        let options: NgbModalOptions = {
            size: 'lg'
        };
        const modalRef = this.modalService.open(VideoPreviewModalComponent, options);
        modalRef.componentInstance.contentItem = item;
        modalRef.componentInstance.artistName = this.getArtistForId(item.artistId).name;
        const util = new Util();
        const duration = item.mediaEnd - item.mediaStart;
        modalRef.componentInstance.duration = util.millisToMinutesAndSeconds(duration);
    }


    getImgForContentItem(contentItem) {
        const img = PlaylistComponent.getImgForContentItem(contentItem);
        return img;
    }

    getName(name) {
        return name.replace(PlaylistComponent.reIndex, '');
    }

    handleDetailListViewTypeToggle(ev) {
        this.onViewMode.emit(ev);
    }

    selectItem(index) {
        this.multiSelectListMediator.selectItem(index);
    }

    isSelected(index) {
        return this.multiSelectListMediator.isSelected(index);
    }

    getSelectedItemStyle(index): string {
        return this.multiSelectListMediator.getSelectedItemStyle(index);
    }

    getArtistForId(artistId) {
        const foundArtist = this.artists.find(artist => artist.id === artistId);
        return (foundArtist) ? foundArtist : new Artist(-1, '');
    }

    visibleContent() {
        return this._contentItems;
    }

    visibleGridContent() {
        let listItems = this.visibleContent();

        var i, j, temparray = [];
        for (i = 0, j = listItems.length; i < j; i += this.numberOfCols) {
            temparray.push(listItems.slice(i, i + this.numberOfCols));
        }
        // console.log("visibleGridContent ", temparray);
        return temparray;
    }

    getDurationDisplay(contentItem) {
        const newDuration = contentItem.mediaEnd - contentItem.mediaStart;
        return this.util.millisToMinutesAndSeconds(newDuration);
    }

}
