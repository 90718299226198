import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from "rxjs/Observable";
import {Licence} from "../_models/licence";
import {LicencesResponse} from "../_rest/licences-response";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Register, RegisterTrial} from "../_models/register";
import {Account} from "../_models/account";
import {AccountResponse} from "../_rest/account-response";
import {AccountListResponse} from "../_rest/account-list-response";
import {Business} from "../_models/business";
import "rxjs/add/observable/of";
import {ResellerLicencesResponse} from "../_rest/reseller-licences-response";
import {ResellerLicences} from "../_models/reseller-licences";
import {Router, ActivatedRoute, Params, Route} from '@angular/router';
import {Product} from "../_models/product";
import {ProductListComponent} from "../product-list/product-list.component";
import {ProductResponse} from "../_rest/product-response";
import {TrialProductPayload} from "../_rest/payloads/product-payload";
import {TrialProductsResponse} from "../_rest/trial-products-response";

@Injectable()
export class AccountService {

  public token: string = "";
  public accountId: string = "";
  public accountType = 0;

  private  TRIAL_ACCOUNT = 65536;
  private  ACCOUNT_TYPE = 4;
  private  RESELLER_TYPE = 2;
  private  CONTENT_PROVIDER_TYPE = 32;
  private  ADMIN_TYPE = 1;
  private activatedRoute: Route;

  constructor(private http: HttpClient) {
    this.parseStorageItems();
  }

    public getHeaders(): HttpHeaders {
        let headers = null
        if (this.token) {
            headers = new HttpHeaders({
                "Content-Type": "application/json",
                "client_id": environment.clientId,
                "client_secret": environment.clientSecret,
                "Accept-Language": this.getLanguage(),
                "token": this.token
            })
        } else {
            headers = new HttpHeaders({
                "Content-Type": "application/json",
                "client_id": environment.clientId,
                "client_secret": environment.clientSecret,
                "Accept-Language": this.getLanguage()
            })
        }

        return headers;
    }

    public login(username: string, password: string) {

        let url = `${environment.server}/ampapi/login`;
        let body = {accountId: username, password: password}
        let options = {headers:this.getHeaders(), observe: 'response' as 'response'};

        return this.http.post(url, body, options)
            .map((response: HttpResponse<any>) => {
                // login successful if there's a jwt token in the response
                let result = response.body;

                if (result && result.responseCode == 0) {
                    if (result.responseData) {

                        localStorage.setItem("token", result.responseData.token);
                        localStorage.setItem("accountId", result.responseData.account.accountId);
                        localStorage.setItem("email", result.responseData.account.contactEmail);
                        localStorage.setItem("accountType", result.responseData.account.type);
                        localStorage.setItem("accountName", result.responseData.account.name);
                        this.parseStorageItems();
                    }
                }
            });
  }

  public parseStorageItems() {
    let token = localStorage.getItem("token");
    this.token = (token) ? token : "";

    let accountId = localStorage.getItem("accountId");
    this.accountId = (accountId) ? accountId : "";

    let accountType = localStorage.getItem("accountType");
    this.accountType = (accountType) ? parseInt(accountType) : this.ACCOUNT_TYPE;
  }

  public getEmail(): string {
    let email = localStorage.getItem("email");
    return (email) ? email : "";
  }

  public setEmail(email: string) {
    localStorage.setItem("email", email);
  }

  public getAccountName(): string {
    let accountName = localStorage.getItem('accountName');
    return accountName ? accountName : ""
  }

  public updateSchedules (venueId: number) {
      let url = `${environment.server}/ampapi/account/${this.accountId}/updateschedules/${venueId}`;
      let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
      return this.http.get(url, options)
          .map((response: HttpResponse<any>) => (response.body.responseData))
  }

  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("accountId");
    localStorage.removeItem("email");
  }

  public fetchLicences(): Observable<Licence[]> {
    let url = `${environment.server}/ampapi/account/${this.accountId}/installations`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.get(url, options)
      .map((response: HttpResponse<LicencesResponse>) => response.body.responseData);
  }


  public fetchResellerLicences(): Observable<ResellerLicences[]> {
    let url = `${environment.server}/ampapi/vendor/${this.accountId}/installations`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.get(url, options)
      .map((response: HttpResponse<ResellerLicencesResponse>) => response.body.responseData);
  }


  public fetchAccount(): Observable<Account> {
    let url = `${environment.server}/ampapi/account/${this.accountId}`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.get(url, options)
      .map((response: HttpResponse<AccountResponse>) => response.body.responseData);
  }


  public fetchGroupVenues(): Observable<Account[]> {
    let url = `${environment.server}/ampapi/account/${this.accountId}/venues`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.get(url, options)
      .map((response: HttpResponse<AccountListResponse>) => response.body.responseData);
  }


  public fetchTrialProducts(): Observable<TrialProductPayload[]> {
    let url = `${environment.server}/ampapi/trial/products`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.get(url, options)
      .map((response: HttpResponse<TrialProductsResponse>) => response.body.responseData);
  }



  public registerAccount(account: Business, password: string, confirmPassword: string): Observable<any> {
    let url = `${environment.server}/ampapi/register`;
    var d = new Date();
    var minutesFromUTC = d.getTimezoneOffset();
    let body = new Register(account, password, confirmPassword, account, minutesFromUTC);
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.put(url, body, options);
  }

  public registerTrialAccount(account: Business, password: string, confirmPassword: string, resellerId: string, productRenditionCode: string): Observable<any> {
    let url = `${environment.server}/ampapi/trial/register`;
    var d = new Date();
    var minutesFromUTC = d.getTimezoneOffset();
    let body = new RegisterTrial(account, password, confirmPassword, account, resellerId, productRenditionCode, minutesFromUTC);
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.put(url, body, options);
  }

  public updateAccountDetails(business: Business): Observable<any> {
    let url = `${environment.server}/ampapi/account/${this.accountId}/updateDetails`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.post(url, business, options)
      .map((response: HttpResponse<any>) => {
        // let r = JSON.parse(response.text());
        return response.body.responseData;
      });
  }

  public updateAccountVenue(business: Business): Observable<any> {
    let url = `${environment.server}/ampapi/account/${this.accountId}/venue`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.post(url, business, options)
      .map((response: HttpResponse<any>) => {
            // let r = JSON.parse(response.text());
            return response.body.responseData;
        });
  }


    public updateInstallationSchedule(installationId, scheduleId): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.accountId}/installation/${installationId}/setschedule/${scheduleId}`;
     //   alert(url);
        const options = {headers: this.getHeaders()};
        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                // let r = JSON.parse(response.text());
                return response.body.responseData;
            });
    }



    public updateAccountPassword(password: string): Observable<any> {
    let url = `${environment.server}/ampapi/account/${this.accountId}/updatePassword`;
    let body = {"password": password};
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.post(url, body, options)
        .map((response: HttpResponse<any>) => {
            // let r = JSON.parse(response.text());
            return response.body.responseData;
        });
  }

  public resetPassword(accountId: string): Observable<any> {
    let url = `${environment.server}/ampapi/account/${accountId}/resetpassword`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    return this.http.get(url, options)
        .map((response: HttpResponse<any>) => {
            // let r = JSON.parse(response.text());
            return response.body.responseData;
        });
  }

  public getLanguage(): string {
    var language = navigator.language.substring(0, 2);
    if(language === "de") {
      return language;
    }
    return "en";
  }

  public isAuthenticated(): boolean {
    if(localStorage.getItem("token")) {
      return true;
    }
    return false;
  }

  public isClient(): boolean {
    return ((this.accountType & this.ACCOUNT_TYPE) != 0);
  }

  public isTrial(): boolean {
    return ((this.accountType & this.ACCOUNT_TYPE) != 0)  && ((this.accountType & this.TRIAL_ACCOUNT) > 0);
  }

  public isReseller(): boolean {
    return (this.accountType & this.RESELLER_TYPE) != 0  && ! this.isAdmin();
  }


  public isContentProvider(): boolean {
    return (this.accountType & this.CONTENT_PROVIDER_TYPE) != 0  && ! this.isAdmin();
  }


  public isAdmin(): boolean {
    return (this.accountType & this.ADMIN_TYPE) != 0;
  }

  public assignLicence (licenceCode: string, installationId: number) {
    let url = `${environment.server}/ampapi/account/${this.accountId}/assignlicence`;
    let options = {headers:this.getHeaders(), observe: 'response' as 'response'};
    let body = JSON.stringify({licenseCode: licenceCode, installationId: installationId});

    return this.http.post(url, body, options)
        .map((response: HttpResponse<any>) => {
            // let r = JSON.parse(response.text());
            return response.body.responseData;
        });

  }

  /*
  public makeTestLicences(): Observable<Licence[]> {
    var licences: Licence[] = [];
    for (var i = 1; i < 10; i++) {
      licences.push(new Licence('Test Product', `GNF7-JO75-IIK6-000${i}`, ((i - 1) % 4) + 2, "Cork Venue", 0, `Licence ${i}`));
    }
    return Observable.of(licences);
  }
*/

}
