import {Licence} from './licence';
import {LicenceStatus} from '../Util';

export class Installation {

    public licenceStatus: number;
    private  licences: Licence[] = [];
    constructor(public id: number = 0,
        public installationName:string = '',
                public venueId: number = 0,
                public systemId: string = '',
                public scheduleId: number = 0)
    {
        this.licenceStatus = LicenceStatus.Active;
    }


    public getLicences() {
        return this.licences;
    }



    public addLicence(licence: Licence)
    {
        this.licences.push(licence);

        if (licence.licenceStatus === LicenceStatus.Expired)
        {
            this.licenceStatus = LicenceStatus.Expired;
        }
        else if (licence.licenceStatus === LicenceStatus.AwaitingActivation)
        {
            if (this.licenceStatus === LicenceStatus.Active)
            {
                this.licenceStatus = LicenceStatus.AwaitingActivation;
            }
        }

        else if (licence.licenceStatus === LicenceStatus.AwaitingReactivation)
        {
            if (this.licenceStatus === LicenceStatus.Active)
            {
                this.licenceStatus = LicenceStatus.AwaitingReactivation;
            }
        }
        else if (licence.licenceStatus === LicenceStatus.ReactivationEnabled)
        {
            if (this.licenceStatus === LicenceStatus.Active)
            {
                this.licenceStatus = LicenceStatus.AwaitingReactivation;
            }

        }
    }
}
