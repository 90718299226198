import {AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-action-popover',
    templateUrl: './action-popover.component.html',
    styleUrls: ['./action-popover.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ActionPopoverComponent implements AfterViewInit, OnChanges {

    @ViewChild('actionPopOver', {static: false}) public popover: NgbPopover;
    private hasViewInit = false;

    @Input() className: string;
    @Input() placement: string;
    @Input() open: boolean;

    ngAfterViewInit(): void {
        this.handleOpenState(this.open);
        this.hasViewInit = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.hasViewInit) {
            this.handleOpenState(changes.open.currentValue);
        }
    }

    handleOpenState(open): void {
        if (open && !this.popover.isOpen()) {
            this.popover.open();
        } else if (!open && this.popover.isOpen()) {
            this.popover.close();
        }
    }
}
