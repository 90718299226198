import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { FilteredPlaylist, Playlist } from '../../_models/playlist';
import { AudioLibraryService } from '../../_services/audio-library.service';
import { PlaylistFilter } from '../../_models/playlist-filter';
import { Tag } from '../../_models/tag';
import { IPlaylistFilterView } from './filters/iplaylist-filter-view';
import { AudioTagPlaylistFilterView } from './filters/audiotag-playlist-filter-view';
import { ArtistPlaylistFilterView } from './filters/artist-playlist-filter-view';
import { VideoTagPlaylistFilterView } from './filters/videotag-playlist-filter-view';
import { Category } from '../../_models/category';
import { CategoryPlaylistFilterView } from './filters/category-playlist-filter-view';
import { VideoSharedModelFilterView } from './filters/video-shared-model-filter-view';
import Util from 'app/Util';
import {AMPFolder} from '../../_models/folder';
import {CombinedPlaylistFilterView} from './filters/combined-playlist-filter-view'
import {Artist} from '../../_models/schedule';

@Component({
    selector: 'amp-playlist-rules-panel',
    templateUrl: './playlist-rules-panel.component.html',
    styleUrls: ['./playlist-rules-panel.component.scss']
})
export class PlaylistRulesPanelComponent {

    // Constants

    VIDEO_MEDIA_TYPE = 1;
    AUDIO_MEDIA_TYPE = 2;

    AMP_PLAYLIST_TYPE_FILTER = 2;
    AMP_PLAYLIST_TYPE_COMBINED = 3;

    // Playlist Data

    @Input() isInEditMode: boolean;
    private _playlist: Playlist;
    private _playlistContent: Array<{ duration: number }>;

    @Output() playPermanentUpdated: EventEmitter<Playlist> = new EventEmitter<Playlist>();
    @Output() updatePlaylistPreview: EventEmitter<FilteredPlaylist> = new EventEmitter<FilteredPlaylist>();

    @ViewChild('filterDropdown', { static: false }) dropdownView: ElementRef;

    // Combined Playlists Filter View
    @Input() playlists: Array<Playlist> = [];
    private _playlistCollection: Array<AMPFolder>;
    playlistFilterView: IPlaylistFilterView<Playlist>;
    playlistsInitialized = false;

    // Shared Filter View
    mediaTags: Array<Tag>;
    tagFilterView: IPlaylistFilterView<Tag>;
    tagsInitialized = false;

    // Audio
    audioArtists: Array<Artist>;
    videoArtists: Array<Artist>;
    artistTagFilterView: IPlaylistFilterView<{ id: number, name: string }>;
    artistInitialized = false;

    // Video
    categoryFilterView: IPlaylistFilterView<Category>;
    categoryInitialized = false;

    videoChannelsFilterView: IPlaylistFilterView<object>;
    videoChannelsInitialized = false;

    audioChannelsFilterView: IPlaylistFilterView<object>;
    audioChannelsInitialized = false;

    seriesFilterView: IPlaylistFilterView<object>;
    seriesInitialized = false;

    videoAlbumsFilterView: IPlaylistFilterView<object>;
    videoAlbumsInitialized = false;

    private util = new Util();


    constructor(
        public app: AppComponent,
        private audioLibraryService: AudioLibraryService
    ) {
       this.getTags();
       this.getArtists();

       this.audioLibraryService.fetchCategories().toPromise().then((res) => {
           this.categoryFilterView = new CategoryPlaylistFilterView();
           this.categoryFilterView.setFilterContent(res);
           this.categoryInitialized = true;
       });

       this.audioLibraryService.fetchChannels([], 1).toPromise().then(res => {
           this.videoChannelsFilterView = new VideoSharedModelFilterView(
               PlaylistFilter.AMP_FILTER_TYPE_CHANNEL,
               'Channel',
               true,
               '../../assets/img/icons_ic_channel_uncolored.png',
               'children',
               'channel-ico'
           );
           this.videoChannelsFilterView.setFilterContent(res);
           this.videoChannelsInitialized = true;
       });

       this.audioLibraryService.fetchChannels([], 2).toPromise().then(res => {
           this.audioChannelsFilterView = new VideoSharedModelFilterView(
               PlaylistFilter.AMP_FILTER_TYPE_CHANNEL,
               'Channel',
               true,
               '../../assets/img/icons_ic_channel_uncolored.png',
               'children',
               'channel-ico'
           );
           this.audioChannelsFilterView.setFilterContent(res);
           this.audioChannelsInitialized = true;
       });

        this.audioLibraryService.fetchSeries().toPromise().then(res => {
            this.seriesFilterView = new VideoSharedModelFilterView(
                PlaylistFilter.AMP_FILTER_TYPE_SERIES,
                'Series',
                true,
                '../../assets/img/icons_ic_series_uncolored.png',
                'children',
                'series-ico'
            );
            this.seriesFilterView.setFilterContent(res);
            this.seriesInitialized = true;
        });

        this.audioLibraryService.fetchAlbums([], 1).toPromise().then(res => {
            this.videoAlbumsFilterView = new VideoSharedModelFilterView(
                PlaylistFilter.AMP_FILTER_TYPE_ALBUM,
                'Album',
                true,
                '../../assets/img/icons_ic_album_uncolored.png',
                'children',
                'album-ico'
            );
            this.videoAlbumsFilterView.setFilterContent(res);
            this.videoAlbumsInitialized = true;
        });

    }

    // Component I/O
    @Input() set playlistCollection(playlistCollection: Array<AMPFolder>) {
        this._playlistCollection = playlistCollection;
    }

    @Input() set playlistContent(playlistContent: Array<{ duration: number }>) {
        this._playlistContent = playlistContent;
    }

    @Input() set playlist(playlist: Playlist) {
        this._playlist = playlist;
        if (this.tagsInitialized) {
            this.buildTagFilterView(this.mediaTags);
        }
        if (this._playlistCollection.length > 0 && this.playlists.length > 0) {
            this.playlistFilterView = new CombinedPlaylistFilterView(this._playlist.mediaType, this._playlistCollection);
            this.playlistFilterView.setFilterContent(this.playlists);
            this.playlistsInitialized = true;
        }

        if (this.artistInitialized) {
            const artists = (playlist.mediaType === this.VIDEO_MEDIA_TYPE) ? this.videoArtists : this.audioArtists;
            this.artistTagFilterView.setFilterContent(artists);
        }
    }

    get playlist() {
        return this._playlist;
    }

    buildTagFilterView(tags) {
        if (this.playlist.mediaType === this.VIDEO_MEDIA_TYPE) {
            this.tagFilterView = new VideoTagPlaylistFilterView();
        } else if (this.playlist.mediaType === this.AUDIO_MEDIA_TYPE) {
            this.tagFilterView = new AudioTagPlaylistFilterView();
        }
        this.tagFilterView.setFilterContent(tags);
    }

    // Audio Library Content

    getTags() {
        return this.audioLibraryService.fetchTags().subscribe(obs => {
            this.mediaTags = obs;
            this.buildTagFilterView(this.mediaTags);
            this.tagsInitialized = true;
        });
    }

    getArtists() {
        this.artistTagFilterView = new ArtistPlaylistFilterView();

        const videoArtistsPromise = this.audioLibraryService.fetchArtists(this.VIDEO_MEDIA_TYPE).toPromise().then(res => {
            this.videoArtists = res;
        });
        const audioArtistsPromise = this.audioLibraryService.fetchArtists(this.AUDIO_MEDIA_TYPE).toPromise().then(res => {
            this.audioArtists = res;
        });

        return Promise.all([videoArtistsPromise, audioArtistsPromise]).then(() => {
            this.artistInitialized = true;
        });

    }

    handleUpdatePreview(playlist: FilteredPlaylist) {
        // Possible to add guards here e.g. media type
        this.updatePlaylistPreview.emit(playlist);
    }

    // Video Library Content

    handleAddFilter(ev) {
        if (this.isPlaylistFiltered()) {
            const fPlaylist = this.playlist as FilteredPlaylist;
            fPlaylist.filters.push(this.tagFilterView.buildDefaultFilter(this.playlist.id));
            this.handleUpdatePreview(fPlaylist);
        } else if (this.isPlaylistCombined()) {
            const fPlaylist = this.playlist as FilteredPlaylist;
            fPlaylist.filters.push(this.playlistFilterView.buildDefaultFilter(this.playlist.id));
            this.handleUpdatePreview(fPlaylist);
        }
    }

    isFilterContentInitialized(): boolean {
        const RULE_1 = (
            this.isPlaylistFiltered() &&
            (
            this.tagsInitialized &&
            this.artistInitialized &&
            this.categoryInitialized &&
            this.videoAlbumsInitialized &&
            this.seriesInitialized &&
            this.videoChannelsInitialized
            )
        );

        const RULE_2 = this.isPlaylistCombined() && this.playlistsInitialized;

        return RULE_1 || RULE_2;
    }

    // Playlist View Functions

    isPlaylistFiltered() {
        return this.playlist.playlistType === this.AMP_PLAYLIST_TYPE_FILTER;
    }

    isPlaylistCombined() {
        return this.playlist.playlistType === this.AMP_PLAYLIST_TYPE_COMBINED;
    }

    getPlaylistTypeLabel(playlistTypeId: number) {
        return this.app.playlistTypes.find(pType => pType.id === playlistTypeId).label;
    }

    handlePermanentCheckOnClick(ev) {
        this.playPermanentUpdated.emit(this.playlist);
    }

    updateLoudness(volumeLevel) {
        this.playlist.volumeLevel = volumeLevel;
    }

    displayMilliSecondsMMSS(milliseconds: number) {
        return this.util.millisToMinutesAndSeconds(milliseconds);
    }

    // Playlist Filter Functions
    processSupportedFiltersVSRules(supportedFilters) {
        if (this.playlist.playlistType === this.AMP_PLAYLIST_TYPE_FILTER) {
            const fPlaylist = this.playlist as FilteredPlaylist;
            const pFilters = fPlaylist.filters.map((filter) => filter.filterType);

            // Single Artist Filter Rule
            const hasArtist = pFilters.includes(this.artistTagFilterView.getFilterId());
            supportedFilters = (hasArtist) ? supportedFilters.filter(filterType =>
                filterType !== this.artistTagFilterView.getFilterId()
                ) : supportedFilters;

            // Single Category Filter Rule
            const hasCategory = pFilters.includes(this.categoryFilterView.getFilterId());
            supportedFilters = (hasCategory) ? supportedFilters.filter(
                filterType => filterType !== this.categoryFilterView.getFilterId()
            ) : supportedFilters;

            // Single Content Item Rule
            const hasContentItem = (
                pFilters.includes(this.videoChannelsFilterView.getFilterId()) ||
                pFilters.includes(this.audioChannelsFilterView.getFilterId()) ||
                pFilters.includes(this.seriesFilterView.getFilterId()) ||
                pFilters.includes(this.videoAlbumsFilterView.getFilterId())
            );
            supportedFilters = (hasContentItem) ? supportedFilters.filter(filterType => (
                filterType !== this.videoChannelsFilterView.getFilterId() &&
                filterType !== this.audioChannelsFilterView.getFilterId() &&
                filterType !== this.seriesFilterView.getFilterId() &&
                filterType !== this.videoAlbumsFilterView.getFilterId()
            )) : supportedFilters;
        }

        return supportedFilters;
    }


    getFiltersForFilteredPlaylist() {
        const AUDIO_SUPP_FILTERS = [
            PlaylistFilter.AMP_FILTER_TYPE_ARTIST,
            PlaylistFilter.AMP_FILTER_TYPE_ATTRIBUTE,
            PlaylistFilter.AMP_FILTER_TYPE_CHANNEL,
        ];
        const VIDEO_SUPP_FILTERS = [
            PlaylistFilter.AMP_FILTER_TYPE_ARTIST,
            PlaylistFilter.AMP_FILTER_TYPE_ALBUM,
            PlaylistFilter.AMP_FILTER_TYPE_SERIES,
            PlaylistFilter.AMP_FILTER_TYPE_CHANNEL,
            PlaylistFilter.AMP_FILTER_TYPE_ATTRIBUTE,
            PlaylistFilter.AMP_FILTER_TYPE_CATEGORY
        ];

        let suppFilters = [];

        if (this.playlist.mediaType === this.VIDEO_MEDIA_TYPE) {
            suppFilters = VIDEO_SUPP_FILTERS;
        } else if (this.playlist.mediaType === this.AUDIO_MEDIA_TYPE) {
            suppFilters = AUDIO_SUPP_FILTERS;
        }

        return suppFilters;
    }

    getSupportedFilters() {
        let suppFilters = [];
        if (this.isPlaylistFiltered()) {
            suppFilters = this.getFiltersForFilteredPlaylist();
        } else if (this.isPlaylistCombined()) {
            suppFilters = [
                PlaylistFilter.AMP_FILTER_TYPE_PLAYLIST,
                PlaylistFilter.AMP_FILTER_TYPE_ARTIST,
                PlaylistFilter.AMP_FILTER_TYPE_ATTRIBUTE,
                PlaylistFilter.AMP_FILTER_TYPE_ALBUM,
                PlaylistFilter.AMP_FILTER_TYPE_SERIES,
                PlaylistFilter.AMP_FILTER_TYPE_CHANNEL,
                PlaylistFilter.AMP_FILTER_TYPE_CATEGORY
            ];
        }
        return this.processSupportedFiltersVSRules(suppFilters);
    }

    getPlaylistFilters() {
        let filters = [];
        if (this.isPlaylistFiltered() || this.isPlaylistCombined()) {
            const fPlaylist = this.playlist as FilteredPlaylist;
            filters = fPlaylist.filters;
        }
        return filters;
    }

    getFilterViewForType(filterType): IPlaylistFilterView<any> {
        var filterViewTypeMap;
        if (this.playlist.mediaType === this.AUDIO_MEDIA_TYPE) {
            filterViewTypeMap = {
                1: this.artistTagFilterView,
                2: this.videoAlbumsFilterView,
                3: this.seriesFilterView,
                4: this.audioChannelsFilterView,
                5: this.tagFilterView,
                6: this.categoryFilterView,
                7: this.playlistFilterView,
            };

        } else if (this.playlist.mediaType === this.VIDEO_MEDIA_TYPE) {
            filterViewTypeMap = {
                1: this.artistTagFilterView,
                2: this.videoAlbumsFilterView,
                3: this.seriesFilterView,
                4: this.videoChannelsFilterView,
                5: this.tagFilterView,
                6: this.categoryFilterView,
                7: this.playlistFilterView,
            };
        }

        return filterViewTypeMap[filterType];
    }

    getFiltersForDropdown(selectedFilter) {
        return this.getSupportedFilters().filter(supportedFilter => {
            return supportedFilter !== selectedFilter;
        });
    }

    handleDelFilter(event, i) {
        if (this.isPlaylistFiltered() || this.isPlaylistCombined()) {
           const fPlaylist = this.playlist as FilteredPlaylist;
           fPlaylist.filters.splice(i, 1);
            this.handleUpdatePreview(fPlaylist);
        }
    }

    handleFilterDropDownOnClick(event, filter: PlaylistFilter, selectedFilterType, index: number) {
        if (this.isPlaylistFiltered() || this.isPlaylistCombined()) {
            const defaultSelFilter = this.getFilterViewForType(selectedFilterType).buildDefaultFilter(this.playlist.id);
            defaultSelFilter.id = filter.id;
            const fPlaylist = this.playlist as FilteredPlaylist;
            fPlaylist.filters[index] = defaultSelFilter;
            this.handleUpdatePreview(fPlaylist);
        }
    }

    handleDropDownHeaderOnClick(event, listItem) {
        event.preventDefault();
        listItem.setSelected(!listItem.isSelected());
    }

    handleDropDownItemOnClick(event, filter: PlaylistFilter, listItem, index) {
        if (this.isPlaylistFiltered() || this.isPlaylistCombined()) {
            // FIXME: Find out a better way to get control over the dropdown so its usable.
            // Current implementation uses a <form> instead of a div which breaks its default close/hide behavior
            // We force a toggle when we want it here instead
            // Angular/Typescript isnt a big fan of using jquery like this, defo a hack. Should be reconsidered.
            if (window.hasOwnProperty('$')) {
                window['$'](`#filter-dropdown-${index}`).dropdown('toggle');
                this.getFilterViewForType(filter.filterType).getDropdownItems().forEach(dropdownListItem => {
                    dropdownListItem.setSelected(false);
                });
            }
            this.getFilterViewForType(filter.filterType).handleFilterUpdate(filter, listItem.getInnerModel());
            this.handleUpdatePreview(this.playlist as FilteredPlaylist);
        }
    }
}
