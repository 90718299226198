import {ApplicationRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Order} from "../../../_models/order";
import {OrderActionDelegate} from "../order-action.delegate";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdminService} from "../../../_services/admin.service";
import {AlertService} from "../../../_alert/alert.service";

@Component({
  selector: 'order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})

export class OrderEditComponent implements OnInit, OnChanges {

  public formGroup: FormGroup;

  @Input()
  public order: Order;

  @Input()
  delegate: OrderActionDelegate;

  constructor(
    private adminService: AdminService,
    private alert: AlertService,
    private translate: TranslateService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      orderDescription: [this.order.description, [Validators.required, Validators.minLength(8), Validators.maxLength(40)]]
    });

  }


  ngOnChanges() {
  }


  cancelEdit() {
    this.delegate.finished(false);
  }

  onSubmit(form: FormGroup) {
    let d = form["orderDescription"];

    this.adminService.updateOrder(this.order.idString, d)
      .finally(() => {
        this.delegate.finished(true)
      })
      .subscribe(response => {
          this.alert.success("Order was modified.");
        },
        error => {
          if(error.status == 0) {
            this.alert.error("Order was modified.");
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

}

