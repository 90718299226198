import {ApplicationRef, Component, OnInit} from '@angular/core';
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'client-shop',
  styleUrls: ['../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',  './client-shop.component.scss'],
  templateUrl: './client-shop.component.html'
})
export class ClientShopComponent implements OnInit {

  isLoading = false;

  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true
  }


}
