import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from "../../_services/validation.service";
import { AMPFolder } from '../../_models/folder';
import { AddScheduleFormDelegate } from './add-schedule-form.delegate';
import { Programme } from '../../_models/programme';
import { Schedule } from 'app/_models/schedule';

@Component({
    selector: 'amp-add-schedule-form',
    templateUrl: './add-schedule-form.component.html',
    styleUrls: ['./add-schedule-form.component.scss']
})
export class AddScheduleFormComponent implements OnInit, OnChanges {

    @Input() eventDelegate: AddScheduleFormDelegate;
    @Input() scheduleFolders: Array<AMPFolder>;

    form: FormGroup;

    constructor(
        private validation: ValidationService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            shouldAddToFolder: [false],
            selectedFolder: [{}]
        });
    }

    close() {
        // Update state to default for form grp
        this.reset();
        this.eventDelegate.onAddScheduleCancel({});
    }

    reset() {
        this.form.reset();
        this.form.controls.name.setValue('');
        this.form.controls.shouldAddToFolder.setValue(false);
        this.form.controls.selectedFolder.setValue(this.scheduleFolders[0]);
    }

    onSubmit(form) {
        const folderId = (form.shouldAddToFolder) ? form.selectedFolder.id : 0;

        const schedule = new Schedule(
            0,
            form.name,
            false,
            0,
            folderId,
            []
        );
        this.reset()
        this.eventDelegate.onAddScheduleSubmit(schedule);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.scheduleFolders.isFirstChange() && changes.scheduleFolders.currentValue.length > 0) {
            this.form.controls.selectedFolder.setValue(changes.scheduleFolders.currentValue[0]);
        }
    }
    isFoldersAvailable() {
        return (this.scheduleFolders && this.scheduleFolders.length > 0);
    }
}
