
// Maintain top level item
// Maintain selected item

import {ListItem} from '../models/list-item';

export interface MasterViewListMediator {
    clear();
    setSelectedListItem(listItem: ListItem, nestedDepth);
    isLastItemSelected(): boolean;
    getLastSelectedItem(): ListItem;
}


export class NestedMasterViewListMediator implements  MasterViewListMediator {
    private static ROOT_DEPTH = 1;

    private listItemBranch: {};

    private lastSelectedListItemDepth: number;
    private lastSelectedListItem: ListItem;

    constructor() {
        this.listItemBranch = {};
    }

    clear() {
        this.listItemBranch = {};
        this.lastSelectedListItemDepth = undefined;
        this.lastSelectedListItem = undefined;
    }

    updateBranch(listItem, nestedDepth) {
        const itemBranch = {};

        // Go through the entries of the existing branch
        Object.entries(this.listItemBranch).forEach(entry => {
            const depth = entry[0];
            const item = <ListItem>entry[1];

            // For all nodes lower then the selected depth, deselect
            if (depth >= nestedDepth) {
                item.setSelected(false);
            } else {
                itemBranch[depth] = item;
            }
        });

        this.listItemBranch = itemBranch;
    }


    setSelectedListItem(listItem: ListItem, nestedDepth) {
        // Cache list item state to allow clicking off items
        let listItemSelectedState = listItem.isSelected();
        this.updateBranch(listItem, nestedDepth);
        listItem.setSelected(!listItemSelectedState);
        listItemSelectedState = listItem.isSelected();

        if (listItemSelectedState) {
            // Assign new item at its depth
            this.listItemBranch[nestedDepth] = listItem;
            this.lastSelectedListItem = listItem;
            this.lastSelectedListItemDepth = nestedDepth;
        } else if (!listItemSelectedState && nestedDepth > NestedMasterViewListMediator.ROOT_DEPTH) {
            // If the listitem is being deselected and there is a nested item below.
            const newItemDepth = nestedDepth - 1;
            this.lastSelectedListItem = this.listItemBranch[newItemDepth];
            this.lastSelectedListItemDepth = newItemDepth;
        }
    }

    getLastSelectedItem(): ListItem {
        return this.lastSelectedListItem;
    }

    isLastItemSelected(): boolean {
        return this.lastSelectedListItem && this.lastSelectedListItem.isSelected();
    }
}
