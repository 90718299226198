
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../_alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AudioLibraryService } from '../_services/audio-library.service';
import { FilteredPlaylist, Playlist } from '../_models/playlist';
import { MasterViewListItemController } from '../common-components/master-view-list/interfaces/master-view-list-item';
import { MasterViewListItemEventDelegate } from '../common-components/master-view-list/interfaces/master-view-list-item-event-delegate';
import { ListItem } from '../common-components/master-view-list/models/list-item';
import GenericActionPopoverController from '../common-components/popovers/generic-popover.controller';
import { AddPlaylistFormDelegate } from './add-playlist-form/add-playlist-form.delegate';
import GenericActionPopoverMediator from '../common-components/popovers/generic-popover.mediator';
import {
    MasterViewListMediator,
    NestedMasterViewListMediator
} from '../common-components/master-view-list/interfaces/master-view-list-mediator';
import DelPlaylistConfirmDelegate from './del-playlist-confirm/del-playlist-confirm.delegate';
import { isFolderListItemModel } from '../common-components/master-view-list/interfaces/folder-list-item-model';
import DelPlaylistFolderConfirmDelegate from './del-playlist-folder-confirm/del-playlist-folder-confirm.delegate';
import { PlaylistActionMenuDelegate } from './playlist-action-menu/playlist-action-menu.delegate';
import { EditModeController, GenericEditModeController } from '../common-components/edit-mode/edit-mode.controller';
import { EditFolderFormDelegate } from '../audio-collections/edit-folder-form/edit-folder-form.delegate';
import {buildPlaylistMediaTypeFilter, ITabableMasterView, PlaylistMasterViewTab} from './iplaylist-tab-view';
import { PlaylistListItemFactory, PlaylistFolderListItemModel, PlaylistFolderListItemFactory } from './util/playlist-listitem-factory';
import { ListItemConfig } from 'app/common-components/master-view-list/models/list-item-config';
import ListItemIconConfigFactory from 'app/common-components/master-view-list/util/list-item-config-factory';
import { MasterViewToolbarController } from 'app/common-components/master-view-toolbar/interfaces/master-view-toolbar-controller';
import { AddPlaylistFolderFormDelegate } from './add-playlist-folder-form/add-playlist-folder-form.delegate';
import { ActionPopoverMediator, ActionPopoverController } from 'app/common-components/popovers/action-popover.parent';
import { AMPFolder } from 'app/_models/folder';
import { MasterViewToolbarAction } from 'app/common-components/master-view-toolbar/master-view-toolbar-action';
import { MultiSelectListMediator } from 'app/common-components/master-view-list/interfaces/mulit-select-list';
import { Artist } from 'app/_models/schedule';
import {environment} from '../../environments/environment';
import {AppComponent} from '../app.component';
// import {newId} from '@ng-select/ng-select/ng-select/id';

/*
1. move both calls into a single promise
2. When resolved, map folders whilst filtering the playlists
3. refactor list item factory to use new model
4. Just works
 */

@Component({
    selector: 'amp-playlist',
    templateUrl: './playlist.component.html',
    styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit,
    MasterViewListItemController, MasterViewListItemEventDelegate,
    MasterViewToolbarController,
    AddPlaylistFolderFormDelegate, AddPlaylistFormDelegate,
    DelPlaylistFolderConfirmDelegate, DelPlaylistConfirmDelegate,
    PlaylistActionMenuDelegate, EditFolderFormDelegate {

    static re = new RegExp('[\\s]', 'g');
    static reSpecial = new RegExp('[,\'\\.]', 'g');
    static reCommaInIcon = new RegExp('\\/[,]_icon', 'g');
    static reIndex = new RegExp('^\\d{2}_', '');


    VIDEO_MEDIA_TYPE = 1;
    AUDIO_MEDIA_TYPE = 2;

    VIDEO_COLLECTION_TYPE = 4;
    AUDIO_COLLECTION_TYPE = 1;

    // Edit Mode Management
    editModeController: EditModeController<Playlist>;

    // List Control & Management
    masterViewListMediator: MasterViewListMediator;
    masterViewList: Array<ListItem>;
    masterViewEnabled = false;
    multiSelectListMediator: MultiSelectListMediator;


    // Popover Control
    popoverMediator: ActionPopoverMediator;
    addPlaylistFolderPopoverCtrl: ActionPopoverController;
    addPlaylistPopoverCtrl: ActionPopoverController;
    delPlaylistFolderPopoverCtrl: ActionPopoverController;
    delPlaylistPopoverCtrl: ActionPopoverController;
    editFolderPopoverCtrl: ActionPopoverController;

    // Tabs
    selectedTabIdentifier: number;
    masterViewTabs: Array<ITabableMasterView<Playlist>>;

    // Data Models
    playlists: Array<Playlist> = [];
    audioPlaylistFolders: Array<AMPFolder> = [];
    videoPlaylistFolders: Array<AMPFolder> = [];

    // Detail View Content
    playlistContent: Array<object>;
    currentActivePlaylist: Playlist;
    artists: Array<Artist> = [];
    artistsMap: any;
    active: Boolean = true;

    // Search
    public searchFilter = '';

    static getImgForContentItem(contentItem) {
        const encodedAMPPath = PlaylistComponent.getEncodedAMPPath(contentItem);
        const image = `${environment.imageServer}/${environment.imageDirectory}/Content/Library/` + encodedAMPPath + '/icon.jpg';
        const img = AppComponent.encodeURI(image);
        return img;
    }

    static getEncodedAMPPath(contentItem) {
        // tslint:disable-next-line:max-line-length
        let amppath = contentItem.runtimePath;
        // filename = filename.replace(PlaylistComponent.re, '+');
        // amppath = encodeURI(amppath) ;
        // filename = filename.replace(PlaylistComponent.re, '_');
        // filename = filename.replace(PlaylistComponent.reSpecial, '');
        // filename = filename.replace(PlaylistComponent.reIndex, '');
        // filename = filename.replace(PlaylistComponent.reCommaInIcon, '_');
        return amppath;
     }

    constructor(
        private route: ActivatedRoute,
        private alert: AlertService,
        private translate: TranslateService,
        private audioLibraryService: AudioLibraryService) {
        this.masterViewTabs = [];
        this.masterViewListMediator = new NestedMasterViewListMediator();

        this.popoverMediator = new GenericActionPopoverMediator();
        this.addPlaylistFolderPopoverCtrl = new GenericActionPopoverController();
        this.addPlaylistPopoverCtrl = new GenericActionPopoverController();
        this.delPlaylistPopoverCtrl = new GenericActionPopoverController();
        this.delPlaylistFolderPopoverCtrl = new GenericActionPopoverController();
        this.editFolderPopoverCtrl = new GenericActionPopoverController();

        this.editModeController = new GenericEditModeController<Playlist>(
            (obj) => this.saveEditMode(obj),
            (obj) => this.cancelEditMode(obj)
        );

        this.selectedTabIdentifier = -1;
        this.currentActivePlaylist = this.createEmptyPlaylistForDetailView();

        this.multiSelectListMediator = new MultiSelectListMediator();

    }


    public ngOnInit() {
        this.getAllArtists();
        this.getPlaylistCollections();
    }

    getCollectionTypeForCurrentTab() {
        return (this.selectedTabIdentifier === this.AUDIO_MEDIA_TYPE) ? this.AUDIO_COLLECTION_TYPE : this.VIDEO_COLLECTION_TYPE;
    }

    getFoldersForTab() {
        const collectTypeForTab = this.getCollectionTypeForCurrentTab();
        let folders = [];
        if (collectTypeForTab === this.VIDEO_COLLECTION_TYPE) {
            folders = this.videoPlaylistFolders;
        } else if (collectTypeForTab === this.AUDIO_COLLECTION_TYPE) {
            folders = this.audioPlaylistFolders;
        }
        return folders;
    }

    getFoldersForType(type) {
        return (type === this.VIDEO_MEDIA_TYPE) ? this.videoPlaylistFolders : this.audioPlaylistFolders;
    }

    createEmptyPlaylistForDetailView() {
        // FIXME: Media Type should be specified.
        // FIXME: Playlist type should be specified.
        // NOTE: This function should not be used for cases outside of supporting an EMPTY DETAIL VIEW!!!!!!!!!!
        //        - It is simply an easy means of integration with legacy
        //        - It should not be used for the construction of playlists for use within the system.
        return new Playlist(0, '', this.AUDIO_MEDIA_TYPE, 1, 0, [], [], 0, false, 0, 1);
    }

    // Model Retrieval

    getAllArtists() {
        this.audioLibraryService.fetchArtists(0).subscribe(res => {
            this.artists = res;
            this.artistsMap = res.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.name }), {});
        }, this.handleServiceError());
    }

    getAudioPlayListFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(this.AUDIO_COLLECTION_TYPE).toPromise();
    }

    getVideoPlaylistFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(this.VIDEO_COLLECTION_TYPE).toPromise();
    }

    getPlaylists() {
        return this.audioLibraryService.promisifiedFetchPlaylistsWithProcessing();
    }

    getCurrentMasterView(): ITabableMasterView<Playlist> {
        return this.masterViewTabs.find(tab => tab.getTabIdentifier() === this.selectedTabIdentifier);
    }

    getPlaylistCollections() {
        const playlistCollectionPromises = [this.getAudioPlayListFolders(), this.getVideoPlaylistFolders(), this.getPlaylists()];
        Promise.all(playlistCollectionPromises).then(values => {

            this.audioPlaylistFolders = <Array<AMPFolder>>values[0];
            this.videoPlaylistFolders = <Array<AMPFolder>>values[1];
            this.playlists = <Array<Playlist>>values[2];

            this.processPlaylistModels(this.playlists, this.audioPlaylistFolders, this.videoPlaylistFolders);
            this.masterViewEnabled = true;

            this.selectDefaultPlaylist();
        }).catch(this.handleServiceError());
    }

    selectDefaultPlaylist() {
        if (this.getCurrentMasterView().getMasterViewModel().length === 0) { return; }

        const defaultListItem = this.getCurrentMasterView().getMasterViewList().find((listItem: ListItem) => {
            return !isFolderListItemModel(listItem.getInnerModel());
        });

        this.masterViewListMediator.setSelectedListItem(defaultListItem, 1);
        this.getDetailViewForListItem(defaultListItem);


        // // Open first folder to reflect invision app.
        // const firstFolderListItem = this.getCurrentMasterView().getMasterViewList().find((listItem: ListItem) => {
        //     return isFolderListItemModel(listItem.getInnerModel());
        // });
        // firstFolderListItem.setSelected(true);
    }

    processPlaylistModels(playlists, audioPlaylistCollections, videoPlaylistCollections) {
        const videoPlaylists = playlists.filter(buildPlaylistMediaTypeFilter(this.VIDEO_MEDIA_TYPE));
        const audioPlaylists = playlists.filter(buildPlaylistMediaTypeFilter(this.AUDIO_MEDIA_TYPE));

        // Master View Data Processing & View Building

        const VideoMasterViewTab = new PlaylistMasterViewTab(
            this.VIDEO_MEDIA_TYPE,
            videoPlaylistCollections,
            videoPlaylists
        );

        const AudioMasterviewTab = new PlaylistMasterViewTab(
            this.AUDIO_MEDIA_TYPE,
            audioPlaylistCollections,
            audioPlaylists
        );

        this.masterViewTabs = [VideoMasterViewTab, AudioMasterviewTab];

        if (this.selectedTabIdentifier === -1) {
            this.selectedTabIdentifier = VideoMasterViewTab.getTabIdentifier();
        }

        this.masterViewListMediator.clear();
    }

    buildListItemForPlaylist(playlist) {
        const playlistListFactory = new PlaylistListItemFactory();
        const icoConf = this.getPlaylistIcoConf();
        const config = new ListItemConfig({ leftHeading: icoConf, left: icoConf });
    }

    getPlaylistIcoConf() {
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        return listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_playlist_type.svg',
            '../../assets/img/icons_ic_playlist_type.svg',
            {},
            'playlist-ico');
    }

    selectPlaylistTab(event, mediaType: number) {
        if (this.selectedTabIdentifier !== mediaType) {
            this.selectedTabIdentifier = mediaType;
            this.selectDefaultPlaylist();
        }
    }

    buildListItemForFolder(folder, playlists) {
        const playlistFolderListFactory = new PlaylistFolderListItemFactory(() => folder.folderName);

        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_folder.svg',
            '../../assets/img/icons_ic_folder.svg',
            {},
            'folder-ico'
        );

        const playlistsConfig = new ListItemConfig({ leftHeading: leftHeadingIcoConf, left: this.getPlaylistIcoConf() });

        return playlistFolderListFactory.buildListItem(new PlaylistFolderListItemModel(folder, playlists), playlistsConfig);
    }

    // Detail View Content / Data Handling

    getPlaylistContentByID(playlist: Playlist) {
        this.currentActivePlaylist = playlist;

        if (playlist.movieIdentifiersAsStrings.length === 0) {
            this.playlistContent = [];
        } else {
            this.audioLibraryService.fetchPlaylistMovies(playlist.id).subscribe(res => {
                res = res.filter(el => {
                    return el != null;
                });
                this.playlistContent = this.currentActivePlaylist.movieIdentifiersAsStrings.reduce((accum, id) => {

                    accum.push(res.find(item => item.sIdentifier === id));
                    return accum;
                }, []);
            }, error => {
                if (error.status === 0) {
                    this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
                    return;
                }
                const response = JSON.parse(error.text());
                this.alert.error(response.responseData);
            });
        }

    }

    deletePlaylistContent(playlist: Playlist, contentIdentifier: string) {
        if (this.editModeController.isInEditMode()) {

            const contentIndexToRemove = playlist.movieIdentifiersAsStrings.indexOf(contentIdentifier);
            if (contentIndexToRemove > -1) {
                playlist.movieIdentifiersAsStrings.splice(contentIndexToRemove, 1);
            }
            this.multiSelectListMediator.clearSelection();
            this.getPlaylistContentByID(playlist);
        }
    }

    deleteSelected() {
        const toRemove = this.multiSelectListMediator.getSelected();


        // Since indexes are based off view content which uses 'playlistContent' list.
        const removeOffIndexFilter = (idAsString, index) => {
            return !toRemove.includes(index);
        };
        this.playlistContent = this.playlistContent.filter(removeOffIndexFilter);

        // Filter identifiers by mapping remaining playlistContent
        this.currentActivePlaylist.movieIdentifiersAsStrings  = this.playlistContent.map((content: {sIdentifier: string}) => {
            return content.sIdentifier;
        });
        this.multiSelectListMediator.clearSelection();
    }


    reorderPlaylistContent(currentPlaylist, playlistContentPayload) {
        if (this.editModeController.isInEditMode()) {
            const contentStringIds = playlistContentPayload.map((content: { sIdentifier }) => content.sIdentifier);

            this.currentActivePlaylist.movieIdentifiersAsStrings = contentStringIds;
            const playlistContentMap = this.playlistContent.reduce((accum, item: {sIdentifier: string}) => {
                accum[item.sIdentifier] = item;
                return accum;
            }, {});
            this.playlistContent = contentStringIds.map(stringId => playlistContentMap[stringId]);
        }
    }

    updatePermanent(playlist: Playlist) {
        if (this.editModeController.isInEditMode()) {
            playlist.permanent = !playlist.permanent;
        }
    }
    // Master View Controller

    getMasterViewList(): Array<ListItem> {
        return this.getCurrentMasterView().getMasterViewList();
    }

    getDetailViewForListItem(listItem: ListItem) {
        if (listItem.isSelected() && listItem.getInnerModel().hasOwnProperty('movieIdentifiersAsStrings')) {
            if (listItem.getInnerModel().movieIdentifiersAsStrings.length > 0) {
                this.getPlaylistContentByID(listItem.getInnerModel())
            } else {
                this.currentActivePlaylist = listItem.getInnerModel();
                this.playlistContent = [];
            }
        } else if (!listItem.isSelected() && !isFolderListItemModel(listItem.getInnerModel())) {
            this.currentActivePlaylist = this.createEmptyPlaylistForDetailView();
            this.playlistContent = [];
        }
    }

    listItemSelected(eventPayload, listItem) {
        this.masterViewListMediator.setSelectedListItem(listItem, eventPayload.nestedDepth);
        this.popoverMediator.closeActionPopovers(this.getToolbarPopovers());
        this.getDetailViewForListItem(listItem);
    }

    pushToMasterViewList(listItem): void {
        // TODO: figure how to seperate this from interface or utilize in new code?
    }

    clearMasterViewList(): Array<ListItem> {
        const cachedList = this.masterViewList;
        this.masterViewList = [];
        return cachedList;
    }

    // Toolbar Controller

    getLastListItemModel() {
        let model = {};
        if (this.masterViewListMediator.isLastItemSelected()) {
            model = this.masterViewListMediator.getLastSelectedItem().getInnerModel();
        }
        return model;
    }

    handleDeleteAction(toolbarActionPopovers) {
        if (this.masterViewListMediator.isLastItemSelected()) {
            const listItem = this.masterViewListMediator.getLastSelectedItem();
            if (isFolderListItemModel(listItem.getInnerModel())) {
                this.popoverMediator.openPopover(toolbarActionPopovers, this.delPlaylistFolderPopoverCtrl);
            } else {
                this.popoverMediator.openPopover(toolbarActionPopovers, this.delPlaylistPopoverCtrl);
            }
        }
    }

    getToolbarPopovers() {
        return [
            this.addPlaylistPopoverCtrl,
            this.addPlaylistFolderPopoverCtrl,
            this.delPlaylistPopoverCtrl,
            this.delPlaylistFolderPopoverCtrl,
            this.editFolderPopoverCtrl
        ];
    }

    getClassNameForNotEmptyFolderListItem() {
        // const RULE = (
        //     this.masterViewListMediator.isLastItemSelected() &&
        //         this.masterViewListMediator.getLastSelectedItem().hasNestedItems() &&
        //         this.masterViewListMediator.getLastSelectedItem().getNestedItems().length > 0
        // );
        //
        // return (RULE) ? 'master-view-toolbar__toolbar-action__del--disabled' : '';
        return '';
    }

    getToolbarActions(): Array<MasterViewToolbarAction> {
        const toolbarActionPopovers = this.getToolbarPopovers();

        const isEditFolderEnabled = (this.masterViewListMediator.isLastItemSelected()
            && isFolderListItemModel(this.masterViewListMediator.getLastSelectedItem().getInnerModel()));
        const editFolderStateStyle = (isEditFolderEnabled) ? 'master-view-toolbar__toolbar-action__edit--enabled' : 'master-view-toolbar__toolbar-action__edit--disabled';

        return [
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_add_plus.svg',
                'master-view-toolbar__toolbar-action__add',
                () => this.popoverMediator.openPopover(toolbarActionPopovers, this.addPlaylistPopoverCtrl)),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_del_minus.svg',
                `master-view-toolbar__toolbar-action__del ${this.getClassNameForNotEmptyFolderListItem()}`,
                () => this.handleDeleteAction(toolbarActionPopovers),
            ),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_folder.svg',
                'master-view-toolbar__toolbar-action__add-folder',
                () => this.popoverMediator.openPopover(toolbarActionPopovers, this.addPlaylistFolderPopoverCtrl)
            ),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_pencil.svg',
                `master-view-toolbar__toolbar-action__edit ${editFolderStateStyle}`,
                () => this.popoverMediator.openPopover(toolbarActionPopovers, this.editFolderPopoverCtrl)
            )
        ]
    }

    // Form Delegation

    onAddPlaylistFolderSubmit(playlistFolder: AMPFolder) {
        this.audioLibraryService.createOrUpdateFolder(playlistFolder).subscribe(res => {
            this.addPlaylistFolderPopoverCtrl.closeActionPopover();
            this.getPlaylistCollections();
        }, this.handleServiceError());
    }

    onAddPlaylistFolderCancel(ev: any) {
        this.addPlaylistFolderPopoverCtrl.closeActionPopover();
    }

    onAddPlaylistCancel(ev: any) {
        this.addPlaylistPopoverCtrl.closeActionPopover();
    }

    onAddPlaylistSubmit(playlist: Playlist) {
        this.audioLibraryService.addOrUpdateContentToPlaylist(playlist).subscribe(res => {
            this.addPlaylistPopoverCtrl.closeActionPopover();
            this.getPlaylistCollections();
        }, this.handleServiceError())
    }

    handleServiceError() {
        return (error) => {
            if (error.status === 0) {
                this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
                return;
            } else if (error.status === 501 && this.alert) {
                this.alert.error('Not yet implemented');
            } else {
                const response = JSON.parse(error.text());
                this.alert.error(response.responseData);
            }
        }
    }

    onMovePlaylistToFolder(playlist: any) {
        this.audioLibraryService.addOrUpdateContentToPlaylist(playlist).subscribe(res => {
            this.getPlaylistCollections();
        }, this.handleServiceError());
    }

    onDelPlaylistCancel(ev: any) {
        this.delPlaylistPopoverCtrl.closeActionPopover();
    }

    buildDeleteAPIErrorHandler(responseCode: number): (error) => void {
        return (error) => {
            if (error.status === 403 && error.hasOwnProperty('_body')) {
                const errorBody = JSON.parse(error._body);
                if (errorBody.responseCode === responseCode) {
                    this.alert.error(errorBody.responseData);
                }
            } else if (error.status === 400) {
                // 'Item is not known'
                this.alert.error(`Something went wrong. Please refresh the atmosphere web player.`)
            } else {
                this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            }
        }
    }


    onDelPlaylistConfirm(playlist: Playlist) {
        const delPlaylistPromise = this.audioLibraryService.deletePlaylist(playlist).toPromise();
        delPlaylistPromise.then(res => {
            this.getPlaylistCollections();
            // Correct response code is 309...
        }).catch(this.buildDeleteAPIErrorHandler(101)).finally(
            () => { this.delPlaylistPopoverCtrl.closeActionPopover(); }
        );
    }

    onDelPlaylistFolderCancel(ev: any) {
        this.delPlaylistFolderPopoverCtrl.closeActionPopover();
    }

    onDelPlaylistFolderConfirm(folder: AMPFolder) {
        const deleteFolderPromise = this.audioLibraryService.deleteFolder(folder).toPromise();
        deleteFolderPromise.then(res => {
            this.delPlaylistFolderPopoverCtrl.closeActionPopover();
            if (folder.folderType === this.AUDIO_COLLECTION_TYPE) {
                this.audioPlaylistFolders = res;
            } else if (folder.folderType === this.VIDEO_COLLECTION_TYPE) {
                this.videoPlaylistFolders = res;
            }
            return this.getPlaylists();
        }).then(res => {
            this.playlists = res;
            this.processPlaylistModels(this.playlists, this.audioPlaylistFolders, this.videoPlaylistFolders);
        }).catch(this.handleServiceError());
    }

    getToolbarStateStyle(): string {
        return (!this.masterViewListMediator.isLastItemSelected()) ? 'master-view-toolbar--no-item-selected' : '';
    }

    getActionMenuStyleState(): string {
        let className = '';

        if (this.masterViewListMediator.isLastItemSelected()) {
            if (isFolderListItemModel((this.masterViewListMediator.getLastSelectedItem().getInnerModel()))) {
                className = `${className} action-menu-disabled`
            }
        } else {
            className = `${className} action-menu-disabled`
        }

        return className;
    }

    saveEditMode(editedObject: Playlist) {
        if (editedObject.playlistType === 2 || editedObject.playlistType === 3) {
            const fEO = editedObject as FilteredPlaylist;
            fEO.filters = fEO.filters.map(filter => {
                filter.filterIdentifier = filter.filterIdentifierAsString;
                return filter;
            });
        }

        this.audioLibraryService.addOrUpdateContentToPlaylist(editedObject).subscribe(res => {
            this.currentActivePlaylist.name = res.name;
            this.currentActivePlaylist.duration = res.duration;

            this.currentActivePlaylist.movieIdentifiersAsStrings = res.movieIdentifiersAsStrings;
            this.currentActivePlaylist.movieIdentifiers = res.movieIdentifiers;
            if (res.playlistType === 2 || res.playlistType === 3) {
                const fRes = res as FilteredPlaylist;
                const fCap = this.currentActivePlaylist as FilteredPlaylist;
                fCap.filters = fRes.filters;
            }
            this.getPlaylistContentByID(this.currentActivePlaylist);
        }, this.handleServiceError())
    }

    cancelEditMode(cachedObject: Playlist) {
        this.currentActivePlaylist.name = cachedObject.name;
        this.currentActivePlaylist.movieIdentifiersAsStrings = cachedObject.movieIdentifiersAsStrings;
        this.currentActivePlaylist.movieIdentifiers = cachedObject.movieIdentifiers;
        this.currentActivePlaylist.permanent = cachedObject.permanent;
        this.currentActivePlaylist.volumeLevel = cachedObject.volumeLevel;
        if (cachedObject.playlistType === 2 || cachedObject.playlistType === 3) {
            const fCachedObj = cachedObject as FilteredPlaylist;
            const fCap = this.currentActivePlaylist as FilteredPlaylist;
            fCap.filters = fCachedObj.filters;
        }
        this.getPlaylistContentByID(this.currentActivePlaylist);
        this.multiSelectListMediator.clearSelection();
    }

    // TODO: Make generic delegate implementation that takes in an audiolibrary and popoverctrl?
    onEditFolderCancel(ev: any) {
        this.editFolderPopoverCtrl.closeActionPopover();
    }

    onEditFolderSubmit(folder: AMPFolder) {
        this.audioLibraryService.createOrUpdateFolder(folder).subscribe(res => {
            this.editFolderPopoverCtrl.closeActionPopover();
        }, this.handleServiceError());
    }

    onCopyPlaylist(playlist, playlistType) {
        this.audioLibraryService.copyPlaylist(playlist, playlistType).subscribe(obs => {
            console.log('onCopyPlaylist Result', obs);
            this.getPlaylistCollections();
        }, err => {
            console.log('onCopyPlaylist Error', err);
        });
    }

    updatePlaylistPreview(playlist: FilteredPlaylist) {
        if (playlist.filters.length > 0) {
            this.audioLibraryService.generatePlaylistMovies(playlist).subscribe(res => {
                res = res.filter(el => {
                    return el != null;
                });

                this.playlistContent = res;
            }, error => {
                if (error.status === 0) {
                    console.log(error);
                    this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
                    return;
                } else if (error.status === 500) {
                    this.playlistContent = [];
                } else {
                    const response = JSON.parse(error.text());
                    this.alert.error(response.responseData);
                }
            });
        } else {
            this.playlistContent = [];
        }
    }
}

