import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ProductService} from "../_services/product.service";
import {Product} from "../_models/product";
import {Movie} from "../_models/movie";
import {AlertService} from "../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "amp-movie-detail",
  templateUrl: "./movie-detail.component.html"
})
export class MovieDetailComponent implements OnInit {

  product: Product;
  movie: Movie;
  isLoading = false;
  url: string = null;

  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private alert: AlertService,
              private translate: TranslateService) {

    this.product = new Product();
    this.movie = new Movie();

  }

  public ngOnInit() {

    let productId = this.route.snapshot.params["productId"];
    let movieId = this.route.snapshot.params["movieId"];

    this.isLoading = true;
    this.productService.fetchProduct(productId)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe((product: Product) => {
          this.product = product;
          for (let movie of product.movies) {
            if (movie.id == movieId) {
              this.movie = movie;
              if(this.movie.promoVideoId === null || this.movie.promoVideoId === "") {
                this.url = null;
              }
              else {
                this.url = `https://player.vimeo.com/video/${ this.movie.promoVideoId }`;
              }
              break;
            }
          }
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        }
      );


  }

}

