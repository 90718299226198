import {Component, OnInit} from "@angular/core";
import {ProductService} from "../_services/product.service";
import {Product} from "../_models/product";
import {Observable} from "rxjs/Observable";
import "rxjs/add/operator/finally";
import {AlertService} from "../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "amp-product-list",
  templateUrl: "./product-list.component.html"
})
export class ProductListComponent implements OnInit {

  products: Product[] = [];
  isLoading = false;

  constructor(private productService: ProductService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  public ngOnInit() {
    this.isLoading = true;
    this.productService.fetchProducts()
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe((products: Product[]) => {
          this.products = products;
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  public openCheckout(product: Product) {
    this.productService.openCheckout(product);
  }

  public plusVATString (product) {
    if (product.plusVAT) {
      return '(' + this.translate.instant('plusVAT') +  ')';
    }
    return '';
  }

}
