import {ListItemFactory} from '../../common-components/master-view-list/interfaces/list-item.factory';
import {ListItem} from '../../common-components/master-view-list/models/list-item';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';

const BUSINESS_DISPLAY = 'Business Details';
const LOGIN_DISPLAY = 'Login Details';

export class AccountListItemModel {
    private readonly _elementId: string;

    constructor(elementId: string) {
        this._elementId = elementId;
    }

    get elementId(): string {
        return this._elementId;
    }
}

export class BusinessListItemFactory implements ListItemFactory<AccountListItemModel> {

    buildListItem(model: AccountListItemModel, config: ListItemConfig): ListItem {
        return new ListItem(config, () => { return BUSINESS_DISPLAY }, model);
    }

}

export class LoginListItemFactory implements ListItemFactory<AccountListItemModel> {

    buildListItem(model: AccountListItemModel, config: ListItemConfig): ListItem {
        return new ListItem(config, () => { return LOGIN_DISPLAY }, model);
    }

}

