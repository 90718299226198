import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../_alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { extend } from '@syncfusion/ej2-base';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Programme } from '../_models/programme';
import { AudioLibraryService } from '../_services/audio-library.service';
import { AppComponent } from '../app.component';
import { MasterViewToolbarController } from '../common-components/master-view-toolbar/interfaces/master-view-toolbar-controller';
import { MasterViewToolbarAction } from '../common-components/master-view-toolbar/master-view-toolbar-action';
import { MasterViewListItemController } from '../common-components/master-view-list/interfaces/master-view-list-item';
import { MasterViewListItemEventDelegate } from '../common-components/master-view-list/interfaces/master-view-list-item-event-delegate';
import {
    MasterViewListMediator,
    NestedMasterViewListMediator
} from '../common-components/master-view-list/interfaces/master-view-list-mediator';
import { ListItem } from '../common-components/master-view-list/models/list-item';
import { ActionPopoverController, ActionPopoverMediator } from '../common-components/popovers/action-popover.parent';
import GenericActionPopoverMediator from '../common-components/popovers/generic-popover.mediator';
import { isFolderListItemModel } from '../common-components/master-view-list/interfaces/folder-list-item-model';
import GenericActionPopoverController from '../common-components/popovers/generic-popover.controller';
import { AMPFolder } from '../_models/folder';
import ListItemIconConfigFactory from '../common-components/master-view-list/util/list-item-config-factory';
import {
    processProgrammeCollection,
    ProgrammeFolderListItemFactory,
    ProgrammeFolderListItemModel,
    ProgrammeListItemFactory
} from './util/programme-listitem-factory';
import { ListItemConfig } from '../common-components/master-view-list/models/list-item-config';
import { AddProgrammeFormDelegate } from './add-programme-form/add-programme-form.delegate';
import { AddProgrammeFormFolderDelegate } from './add-programme-folder-form/add-programme-folder-form.delegate';
import { DelProgrammeConfirmDelegate } from './del-programme-confirm/del-programme-confirm.delegate';
import DelProgrammeFolderConfirmDelegate from './del-programme-folder-confirm/del-programme-folder-confirm.delegate';
import { ProgrammeActionMenuDelegate } from './programme-action-menu/programme-action-menu.delegate';
import { Playlist } from '../_models/playlist';
import { ProgrammeDetailComponent } from './programme-detail/programme-detail.component';
import { EditModeController, GenericEditModeController } from '../common-components/edit-mode/edit-mode.controller';
import { EditFolderFormDelegate } from '../audio-collections/edit-folder-form/edit-folder-form.delegate';
import { FLOATING_ITEM_ID, processFolderMap } from '../common-components/util/common-collections-process';

@Component({
    selector: 'amp-programme',
    templateUrl: './programme.component.html',
    styleUrls: [
        '../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
        './programme.component.scss']
})

export class ProgrammeComponent implements OnInit, AfterViewInit,
    MasterViewListItemController, MasterViewListItemEventDelegate,
    MasterViewToolbarController,
    AddProgrammeFormDelegate, AddProgrammeFormFolderDelegate,
    DelProgrammeConfirmDelegate, DelProgrammeFolderConfirmDelegate,
    ProgrammeActionMenuDelegate, EditFolderFormDelegate {


    @ViewChild(ProgrammeDetailComponent, { static: false })
    private detailView: ProgrammeDetailComponent;


    // Edit Mode Management
    editModeController: EditModeController<Programme>;

    // List Control & Management
    masterViewListMediator: MasterViewListMediator;
    masterViewList: Array<ListItem>;

    // Popover Control
    popoverMediator: ActionPopoverMediator;
    addFolderPopoverCtrl: ActionPopoverController;
    addPopoverCtrl: ActionPopoverController;
    delFolderPopoverCtrl: ActionPopoverController;
    delPopoverCtrl: ActionPopoverController;
    editFolderPopoverCtrl: ActionPopoverController;

    private hasViewInit = false;

    public showTimeline = true;
    public week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    public data: Object[] = <Object[]>extend([], Programme, null, true);
    public programs: Array<Programme> = [];
    public updatedProgramme: Programme;
    public selectedProgramme: Array<Programme> = [];
    public programFolders: Array<AMPFolder> = [];
    public playlists: Array<Playlist>;
    public audioPlaylistFolders: Array<AMPFolder> = [];
    public videoPlaylistFolders: Array<AMPFolder> = [];
    public activeProgramTab: Programme;
    public startTimes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

    constructor(public app: AppComponent,
        private alert: AlertService,
        private translate: TranslateService,
        private modalService: NgbModal,
        private audioLibraryService: AudioLibraryService) {
        this.masterViewList = [];
        this.popoverMediator = new GenericActionPopoverMediator();
        this.addFolderPopoverCtrl = new GenericActionPopoverController();
        this.addPopoverCtrl = new GenericActionPopoverController();
        this.delPopoverCtrl = new GenericActionPopoverController();
        this.delFolderPopoverCtrl = new GenericActionPopoverController();
        this.masterViewListMediator = new NestedMasterViewListMediator();
        this.editFolderPopoverCtrl = new GenericActionPopoverController();

        this.editModeController = new GenericEditModeController(
            (obj) => this.saveEditMode(obj),
            (obj) => this.cancelEditMode(obj));
    }

    ngOnInit() {
        const initPromises = [this.getPlaylists(), this.getAudioPlaylistFolders(), this.getVideoPlaylistFolders()];

        Promise.all(initPromises).then(results => {
            this.playlists = results[0];
            this.audioPlaylistFolders = results[1];
            this.videoPlaylistFolders = results[2];
            this.loadProgrammeCollection();
        });
    }
    ngAfterViewInit(): void {
        this.hasViewInit = true;
    }

    handleServiceError(error) {
        if (error.status === 0) {
            this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            return;
        }
        if (typeof error.text === 'function') {
            const response = JSON.parse(error.text());
            this.alert.error(response.responseData);
        } else {
            console.log(error);
        }
    }

    loadProgrammeCollection() {
        const audioLibPromises = [this.getProgrammeFolders(), this.getProgrammes()];
        Promise.all(audioLibPromises).then(values => {
            const shouldSelectDefault = (this.programs.length === 0);

            this.programFolders = values[0] || [];
            this.programs = values[1];

            this.processProgrammeCollections(this.programFolders, this.programs);

            if (shouldSelectDefault) {
                const defaultListItem = this.masterViewList.find((listItem: ListItem) => {
                    return !isFolderListItemModel(listItem.getInnerModel());
                });

                this.masterViewListMediator.setSelectedListItem(defaultListItem, 1);
                this.getDetailViewForListItem(defaultListItem);
            }
        }).catch(this.handleServiceError);
    }

    getPlaylists() {
        return this.audioLibraryService.promisifiedFetchPlaylistsWithProcessing();
    }

    getProgrammes() {
        return this.audioLibraryService.fetchPrograms().toPromise();
    }

    getAudioPlaylistFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(1).toPromise();
    }

    getVideoPlaylistFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(4).toPromise();
    }

    getProgrammeFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(2).toPromise();
    }

    updateProgramme(programme) {
        if (this.editModeController.isInEditMode()) {
            this.updatedProgramme = programme;
            this.detailView.generateSchedulerData(this.updatedProgramme);
        }
    }

    processProgrammeCollections(programmeFolders, programmes) {
        this.clearMasterViewList();
        this.masterViewListMediator.clear();
        this.masterViewList = processProgrammeCollection(programmeFolders, programmes);

    }

    setActiveProgram(program: Programme) {
        this.updatedProgramme = program;
        this.activeProgramTab = program;
    }

    handleDeleteAction(toolbarActionPopovers) {
        if (this.masterViewListMediator.isLastItemSelected()) {
            const listItem = this.masterViewListMediator.getLastSelectedItem();
            if (isFolderListItemModel(listItem.getInnerModel())) {
                this.popoverMediator.openPopover(toolbarActionPopovers, this.delFolderPopoverCtrl);
            } else {
                this.popoverMediator.openPopover(toolbarActionPopovers, this.delPopoverCtrl);
            }
        }
    }

    getToolbarPopovers() {
        return [
            this.addPopoverCtrl,
            this.addFolderPopoverCtrl,
            this.delPopoverCtrl,
            this.delFolderPopoverCtrl,
            this.editFolderPopoverCtrl
        ];
    }

    getClassNameForNotEmptyFolderListItem() {
        // const RULE = (
        //     this.masterViewListMediator.isLastItemSelected() &&
        //     this.masterViewListMediator.getLastSelectedItem().hasNestedItems() &&
        //     this.masterViewListMediator.getLastSelectedItem().getNestedItems().length > 0
        // );
        //
        // return (RULE) ? 'master-view-toolbar__toolbar-action__del--disabled' : '';
        return '';
    }

    getToolbarActions(): MasterViewToolbarAction[] {
        const toolbarActionPopovers = this.getToolbarPopovers();

        // TODO: Make this generic somewhere?
        const isEditFolderEnabled = (this.masterViewListMediator.isLastItemSelected()
            && isFolderListItemModel(this.masterViewListMediator.getLastSelectedItem().getInnerModel()));
        const editFolderStateStyle = (isEditFolderEnabled) ? 'master-view-toolbar__toolbar-action__edit--enabled' : 'master-view-toolbar__toolbar-action__edit--disabled';

        return [
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_add_plus.svg',
                'master-view-toolbar__toolbar-action__add',
                () =>
                    this.popoverMediator.openPopover(toolbarActionPopovers, this.addPopoverCtrl)),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_del_minus.svg',
                `master-view-toolbar__toolbar-action__del master-view-toolbar__toolbar-action__del ${this.getClassNameForNotEmptyFolderListItem()}`,
                () => this.handleDeleteAction(toolbarActionPopovers),
            ),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_folder.svg',
                'master-view-toolbar__toolbar-action__add-folder',
                () => this.popoverMediator.openPopover(toolbarActionPopovers, this.addFolderPopoverCtrl)
            ),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_pencil.svg',
                `master-view-toolbar__toolbar-action__edit ${editFolderStateStyle}`,
                () => this.popoverMediator.openPopover(toolbarActionPopovers, this.editFolderPopoverCtrl)
            )
        ];
    }
    getToolbarStateStyle(): string {
        return (!this.masterViewListMediator.isLastItemSelected()) ? 'master-view-toolbar--no-item-selected' : '';
    }

    listItemSelected(evPayload: { event: any; nestedDepth: number; }, listItem: any) {
        this.masterViewListMediator.setSelectedListItem(listItem, evPayload.nestedDepth);
        this.popoverMediator.closeActionPopovers(this.getToolbarPopovers());
        this.getDetailViewForListItem(listItem);
    }

    clearMasterViewList(): ListItem[] {
        const cachedList = this.masterViewList;
        this.masterViewList = [];
        return cachedList;
    }

    getMasterViewList(): ListItem[] {
        return this.masterViewList;
    }

    pushToMasterViewList(listItem: any): void {
        this.masterViewList.push(listItem);
    }

    createEmptyProgramme() {
        return new Programme(0, '', 0, 0, []);
    }

    getDetailViewForListItem(listItem: any): void {
        if (isFolderListItemModel(listItem.getInnerModel())) {

        } else if (!listItem.isSelected() && !isFolderListItemModel(listItem.getInnerModel())) {
            const emptyProgramme = this.createEmptyProgramme();
            this.updatedProgramme = emptyProgramme;
            this.activeProgramTab = emptyProgramme;
        } else {
            this.setActiveProgram(listItem.getInnerModel());
        }
    }

    // Form Delegation

    onAddProgrammeFolderSubmit(folder: AMPFolder) {
        this.audioLibraryService.createOrUpdateFolder(folder).subscribe(() => {
            this.addFolderPopoverCtrl.closeActionPopover();
            this.loadProgrammeCollection();
        }, this.handleServiceError);
    }

    onAddProgrammeFolderCancel(ev: any) {
        this.addFolderPopoverCtrl.closeActionPopover();
    }

    onAddProgrammeSubmit(programme: Programme) {
        this.audioLibraryService.createOrUpdateProgram(programme).subscribe(res => {
            this.addPopoverCtrl.closeActionPopover();
            this.loadProgrammeCollection();
        }, this.handleServiceError)
    }

    onAddProgrammeCancel(ev: any) {
        this.addPopoverCtrl.closeActionPopover();
    }

    onDelProgrammeCancel(ev: any) {
        this.delPopoverCtrl.closeActionPopover();
    }

    buildDeleteAPIErrorHandler(responseCode: number): (error) => void {
        return (error) => {
            if (error.status === 403 && error.hasOwnProperty('_body')) {
                const errorBody = JSON.parse(error._body);
                if (errorBody.responseCode === responseCode) {
                    this.alert.error(errorBody.responseData);
                }
            } else if (error.status === 400) {
                // 'Item is not known'
                this.alert.error(`Something went wrong. Please refresh the atmosphere web player.`)
            } else {
                this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            }
        }
    }

    onDelProgrammeConfirm(programme: Programme) {
        const delProgrammePromise = this.audioLibraryService.deleteProgram(programme).toPromise();
        delProgrammePromise.then(res => {
            this.programs = this.programs.filter(program => program.id !== programme.id);
            this.processProgrammeCollections(this.programFolders, this.programs);
        }).catch(this.buildDeleteAPIErrorHandler(310)).finally(() => {
            this.delPopoverCtrl.closeActionPopover();
        });
    }

    onDelProgrammeFolderCancel(ev: any) {
        this.delFolderPopoverCtrl.closeActionPopover();
    }

    onDelProgrammeFolderConfirm(folder: AMPFolder) {
        const deleteFolderPromise = this.audioLibraryService.deleteFolder(folder).toPromise();
        deleteFolderPromise.then(res => {
            this.delFolderPopoverCtrl.closeActionPopover();
            this.programFolders = res;
            return this.getProgrammes();
        }).then(res => {
            this.programs = res;
            this.processProgrammeCollections(this.programFolders, this.programs);
        }).catch(this.handleServiceError);
    }

    getLastListItemModel() {
        let model = {};
        if (this.masterViewListMediator.isLastItemSelected()) {
            model = this.masterViewListMediator.getLastSelectedItem().getInnerModel();
        }
        return model;
    }

    getActionMenuStyleState(): string {
        let className = '';

        if (this.masterViewListMediator.isLastItemSelected()) {
            if (isFolderListItemModel((this.masterViewListMediator.getLastSelectedItem().getInnerModel()))) {
                className = `${className} action-menu-disabled`
            }
        } else {
            className = `${className} action-menu-disabled`
        }

        return className;
    }
    onMoveProgrammeToFolder(programme: any) {
        this.audioLibraryService.createOrUpdateProgram(programme).subscribe(res => {
            this.loadProgrammeCollection();
        }, this.handleServiceError);
    }

    onCopyProgramme(programme: any) {
        this.audioLibraryService.copyProgram(programme).subscribe(res => {
            this.loadProgrammeCollection();
        }, this.handleServiceError);
    }

    saveEditMode(editObject: Programme) {
        this.audioLibraryService.createOrUpdateProgram(editObject).subscribe(res => {
            const latestProgramVersion = res.find((programme) => programme.id === this.activeProgramTab.id);
            this.activeProgramTab = latestProgramVersion;
        }, this.handleServiceError);
    }

    cancelEditMode(cachedObject: Programme) {
        this.updatedProgramme.name = cachedObject.name;
        this.updatedProgramme.sequences = [...cachedObject.sequences];
        this.updatedProgramme.startTime = cachedObject.startTime;
        this.detailView.generateSchedulerData(this.updatedProgramme);
        this.setActiveProgram(this.updatedProgramme);
        this.detailView.HOURS_OFFSET = cachedObject.startTime / 3600;
    }


    changeRange(event) {

        // TODO: Should this be fixed?
        // tslint:disable-next-line:radix
        switch (parseInt(event.target.value)) {
            case 1:
                this.detailView.timeScaleOptions.interval = 5;
                this.detailView.timeScaleOptions.slotCount = 1;
                break;

            case 2:
                this.detailView.timeScaleOptions.interval = 15;
                this.detailView.timeScaleOptions.slotCount = 1;
                break;

            case 3:
                this.detailView.timeScaleOptions.interval = 30;
                this.detailView.timeScaleOptions.slotCount = 1;
                break;

            case 4:
                this.detailView.timeScaleOptions.interval = 60;
                this.detailView.timeScaleOptions.slotCount = 1;
                break;

            default:
                break;
        }

        this.detailView.scheduleObj.refresh();
    }

    onEditFolderCancel(ev: any) {
        this.editFolderPopoverCtrl.closeActionPopover();
    }

    onEditFolderSubmit(folder: AMPFolder) {
        this.audioLibraryService.createOrUpdateFolder(folder).subscribe(res => {
            this.editFolderPopoverCtrl.closeActionPopover();
        }, this.handleServiceError);
    }

    updateStartTime(startTime) {
        this.updatedProgramme.startTime = startTime * 3600;
        this.detailView.HOURS_OFFSET = startTime;
    }

    getStartTime() {
        if (this.activeProgramTab) {
            return this.activeProgramTab.startTime / 3600
        } else {
            return 0;
        }
    }
}
