import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService} from "../_services/account.service";
import {Account} from "../_models/account";
import {Business} from "../_models/business";
import {ValidationService} from "../_services/validation.service";
import {AlertService} from "../_alert/alert.service";
import {AccountComponent} from "../account/account.component";
import {TranslateService} from "@ngx-translate/core";
import {AppComponent} from "../app.component";
import {Country} from "../_models/country";
import {BusinessDetailsFormParent} from "../business-details-form/business-details-form.parent";
import {Licence} from "../_models/licence";

@Component({
  selector: 'amp-business-details-form',
  templateUrl: './business-details-form.component.html'
})
export class BusinessDetailsFormComponent implements OnInit {

  form: FormGroup;
  isLoading = false;
  lang = 'en';
  selectedCountry: Country;

  @Input()
  parent: BusinessDetailsFormParent;

  @Input()
  isVenue: boolean;

  public countries: any;

  constructor(private app: AppComponent,
              private formBuilder: FormBuilder,
              private account: AccountService,
              private validation: ValidationService,
              private alert: AlertService,
              private accountComponent: AccountComponent,
              private translate: TranslateService) {
  }

  ngOnInit() {

    this.lang = this.translate.getBrowserLang();

    this.countries = this.app.countries;


    this.form = this.formBuilder.group({
      companyName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      contactName: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      address: this.formBuilder.group({
        address1: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        address2: ["", [Validators.minLength(2), Validators.maxLength(30)]],
        postcode: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        city: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
        state: ["", [Validators.required]],
        country: [null, [Validators.required]]
      }),
      phone: ["", [Validators.required]],
      businessNumber: [""],
      email: ["", [Validators.required, this.validation.emailValidator()]]
    });


    this.isLoading = false;
    if (this.parent.loadAccount()) {
      this.isLoading = true;
      this.account.fetchAccount()
        .finally(() => {
          this.isLoading = false;
        })
        .subscribe((account: Account) => {
            this.initialiseFromAccount(account);
          },
          error => {
            if (error.status == 0) {
              this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
              return;
            }
            let response = JSON.parse(error.text());
            this.alert.error(response.responseData);
          });
    } else {
      var account = this.parent.getAccount();
      this.initialiseFromAccount(account);
    }

  }

  private initialiseFromAccount(account: Account) {
    this.selectedCountry = this.app.countryForCode(account.country);

    this.form.patchValue({
      companyName: account.name,
      contactName: account.contactName,
      address: {
        address1: account.addressLine1,
        address2: account.addressLine2,
        postcode: account.postcode,
        city: account.city,
        state: account.state,
        // country: this.selectedCountry
      },
      businessNumber: account.businessNumber,
      phone: account.phone,
      email: account.contactEmail
    });

  }

  onSelectedCountry(event: any) {
    // console.log("Selected country:" +  event.target.value);
    // this.form.patchValue({ address: {
    //   country: country
    // }});
  }


  onSubmit(form) {
    let business: Business = new Business(
      form.companyName,
      form.contactName,
      form.address.address1,
      form.address.address2,
      form.address.city,
      form.address.state,
      form.address.country.code,
      form.address.postcode,
      form.businessNumber,
      form.phone,
      form.email
    );
    this.isLoading = true;
    this.parent.saveBusiness(business)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe(response => {
          this.parent.finished();
          if (form.email && this.parent.updateEmail()) {
            this.accountComponent.exitEditModes();
          }
          this.alert.success(this.translate.instant("account-details-have-been-updated"));
        },
        error => {
          this.parent.finished();
          // this.accountComponent.exitEditModes();
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          if (response.responseCode == 31) {
            this.alert.error(this.translate.instant("invalid-business-number"));
          } else if (response.responseCode == 32) {
            this.alert.error(this.translate.instant("business-number-check-unavailable"));
          } else {
            this.alert.error(response.responseData);
          }
          // this.accountComponent.exitEditModes();
          this.parent.finished();
        });
  }

  cancel() {
    this.parent.finished();
    // this.accountComponent.exitEditModes();
  }

  euNotGermany() {
    return this.selectedCountry.code !== 'DE' && this.app.isEuCountry(this.selectedCountry.code);
  }


  public getBusinessLabel(): string
  {
      if (this.isVenue) {
        return 'venue-name';
      }
      return 'company-name';
  }
}
