import {Movie} from "./movie";

export class Product {

  productImage: string = "";
  movies: Movie[] = [];

  format: string = "UHD; 3840 x 2160 pix; 16/9";
  cinematographer: string = "Maxx Hermann";
  sound: string = "Original ambient sound";
  codecs: string = "H264 / AAC";

  constructor(
    public id: number = 0,
    public productCode: string = "",
    public productName: string = "",
    public currency: string = "",
    public plusVAT : boolean = false,
    public price: number = 0,
    public resellerPrice: number = 0,
    public displayName: string = "",
    public productSummary: string = "",
    public productDescription: string = "",
    public licenced: boolean = false,
    public promoVideoId: string = "") {}


  priceToPay() {
    if (this.plusVAT)
      return this.price * 1.19;
    return this.price;
  }

}
