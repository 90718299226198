import {Component, Input} from '@angular/core';
import {MasterViewToolbarAction} from './master-view-toolbar-action';

@Component({
    selector: 'app-master-view-toolbar',
    templateUrl: './master-view-toolbar.component.html',
    styleUrls: ['./master-view-toolbar.component.scss']
})
export class MasterViewToolbarComponent {
    @Input() toolbarActions: Array<MasterViewToolbarAction>;
    @Input() className: string;
}
