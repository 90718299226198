import {ListItemFactory} from '../../common-components/master-view-list/interfaces/list-item.factory';
import {Playlist} from '../../_models/playlist';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';
import {List, ListItem} from '../../common-components/master-view-list/models/list-item';
import {AMPFolder} from '../../_models/folder';
import FolderListItemModel from '../../common-components/master-view-list/interfaces/folder-list-item-model';
import {Programme} from '../../_models/programme';
import {FLOATING_ITEM_ID, processFolderMap} from '../../common-components/util/common-collections-process';
import ListItemIconConfigFactory from '../../common-components/master-view-list/util/list-item-config-factory';

const getProgrammeListItemIcoConf = () => {
    const listItemIconConfigFactory = new ListItemIconConfigFactory();
    return listItemIconConfigFactory.buildListItemImageIconConfig(
        '../../assets/img/icons_ic_sequence_icon_uncolored.svg',
        '../../assets/img/icons_ic_sequence_icon.svg',
        {},
        'sequence-ico'
    );
};

const buildListItemForProgramme = (programme) => {
    const programmeListItemFactory = new ProgrammeListItemFactory();
    const icoConf = getProgrammeListItemIcoConf();

    return programmeListItemFactory.buildListItem(programme, new ListItemConfig({ leftHeading: icoConf, left: icoConf }))
};

const buildListItemForFolder = (folder, programmes) => {
    const programmeFolderListFactory = new ProgrammeFolderListItemFactory(() => folder.folderName);

    const listItemIconConfigFactory = new ListItemIconConfigFactory();
    const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
        '../../assets/img/icons_ic_folder.svg',
        '../../assets/img/icons_ic_folder.svg',
        {},
        'folder-ico'
    );

    const programmesLIConf = new ListItemConfig({ leftHeading: leftHeadingIcoConf, left: getProgrammeListItemIcoConf() });

    return programmeFolderListFactory.buildListItem(new ProgrammeFolderListItemModel(folder, programmes), programmesLIConf)
};

export const processProgrammeCollection = (programmeFolders, programmes) => {
    const programmeFolderMap = processFolderMap(programmeFolders, programmes);

    const masterViewList = [];

    programmeFolders.forEach((folder) => {
        masterViewList.push(buildListItemForFolder(folder, programmeFolderMap[folder.id]));
    });

    if (Array.isArray(programmeFolderMap[FLOATING_ITEM_ID])) {
        programmeFolderMap[FLOATING_ITEM_ID].forEach(programme => {
            masterViewList.push(buildListItemForProgramme(programme));
        });
    }
    return masterViewList;
};


const buildProgrammesMap = (config: ListItemConfig) => {
    return (programme: Programme) => {
        return new ListItem(config, () => { return programme.name }, programme);
    }
};

export class ProgrammeFolderListItemModel implements FolderListItemModel {
    private readonly _folder: AMPFolder;
    private readonly _programmes: Array<Programme>;
    isFolderListItemModel = true;

    constructor(folder, programmes) {
        this._folder = folder;
        this._programmes = programmes;
    }

    get folder(): AMPFolder {
        return this._folder;
    }

    get programmes(): Array<Programme> {
        return this._programmes;
    }
}

export class ProgrammeListItemFactory implements  ListItemFactory<Programme> {

    buildListItem(model: Programme, config: ListItemConfig): ListItem {
        return buildProgrammesMap(config)(model);
    }
}

export class ProgrammeFolderListItemFactory implements ListItemFactory<ProgrammeFolderListItemModel> {

    private readonly listHeading;

    constructor(listHeading: () => string) {
        this.listHeading = listHeading;
    }

    buildListItem(model: ProgrammeFolderListItemModel, config: ListItemConfig): ListItem {
        return new List(config, this.listHeading, model, model.programmes.map(buildProgrammesMap(config)))
    }

}

