import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AccountService} from "../../../_services/account.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../../../_services/validation.service";
import {AlertService} from "../../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {AdminService} from "../../../_services/admin.service";
import {OrderActionDelegate} from "../order-action.delegate";
import {Account} from "../../../_models/account";
import {Product} from "../../../_models/product";
import {ProductService} from "../../../_services/product.service";
import {AdminProduct} from "../../../_models/admin-product";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {NgbDateStructAdapter} from "@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter";
import {$} from "protractor";
import * as moment from 'moment';

@Component({
  selector: 'order-create',
  templateUrl: './order-create.component.html',
  styleUrls: [
    '../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
    './order-create.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})


export class OrderCreateComponent implements OnInit {

  form: FormGroup;
  isLoading = false;

  public resellers : Account[];
  public products : AdminProduct[];
  public formats : any[];
  public selectedFormat: any;

  public selectedReseller: Account;
  public selectedProduct: AdminProduct;
  public licenceType: string;
  public licenceCount: number;

  public durationMechanisms: any[];
  public selectedDurationMechanism: any;

  public durations: any[];
  public selectedDuration : any;

  public expiryDate : NgbDate;
  public startDate : NgbDateStruct;


  @Input()
  public delegate : OrderActionDelegate;

  constructor(private formBuilder: FormBuilder,
              private adminService: AdminService,
              private productService: ProductService,
              private validation: ValidationService,
              private alert: AlertService,
              private translate: TranslateService) {
  }




  ngOnInit() {

    let d = new Date (Date.now());
    this.startDate = {
      day: d.getDate(),
      month: d.getMonth() + 1,
      year: d.getFullYear()
    };


    this.durationMechanisms = [
      {
        name: "Time Boxed:",
        type: 1
      },
      {
        name: "Fixed Expiry:",
        type: 2
      }];

    this.durations = [
      {
        months: 1,
        days: 31
      },
      {
        months: 12,
        days: 365
      },
      {
        months: 24,
        days: 730
      },
      {
        months: 36,
        days: 1095
      },
      {
        months: 48,
        days: 1461
      },
      {
        months: 60,
        days: 1826
      }];

    this.form = this.formBuilder.group({
      reseller:[""],
      product:[""],
      description:["", [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      licenceCount:["10", [Validators.required, Validators.min(1), Validators.max(100)]],
      expiryDate:[""]
    });

    this.adminService.fetchResellers()
      .subscribe((resellers: Account[]) => {
         this.resellers = resellers;
         this.selectedReseller = resellers[0];
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });


    this.adminService.fetchProducts()
      .subscribe((products: AdminProduct[]) => {
          this.products = products;
          this.selectedProduct = this.products[0];
          this.productSelected();
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });



  }

  onSubmit(form) {
    this.isLoading = true;

    let renditionCode: string = null;


    let licenceDuration : number = 0;
    let licenceType : number = 1;

    if (this.selectedProduct.type == 2)
    {
       switch (this.selectedDurationMechanism.type) {
        case 1:
        licenceType = 2;
        licenceDuration = this.selectedDuration.days;
        break;
      case 2:
        licenceType = 3;
        let exp : Date = new Date(this.expiryDate.year, this.expiryDate.month-1, this.expiryDate.day);
        var expires = moment(exp);
        var now = moment();
        var days = expires.diff(now, 'days');
        licenceDuration = days + 1;
        break;
     }
    }


    this.adminService.createOrder(this.selectedFormat.code, this.selectedReseller.name, form.licenceCount, licenceType, licenceDuration, form.description)
      .finally(() => {
        this.delegate.finished(true)
      })
      .subscribe(response => {
           this.alert.success("Order was created.");
        },
        error => {
          if(error.status == 0) {
            this.alert.error("Order was created.");
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  public cancel() {
    this.delegate.finished(false);
  }


  public productSelected() {
    // Show licence type
    if (this.selectedProduct.type == 1)
      this.licenceType = "Perpetual";
    else {
      this.licenceType = "Subscription";
      this.selectedDurationMechanism = this.durationMechanisms[0];
      this.selectedDuration = this.durations[0];
    }
      // Select product rendition
    this.formats = [];
    let i:number = 0;
    for (let format of this.selectedProduct.renditions)
    {
      let name : string = this.renditionNameForId(format.renditionId);
      this.formats[i++] = {
        code: format.renditionCode,
        name: name
      };
    }
    this.selectedFormat = this.formats[0];
  }


  private renditionNameForId (renditionId : number) : string
  {
    switch (renditionId)
    {
      case 1:
        return  "4K4";
      case 2:
        return "4K1";
      case 3:
        return "UHD";
      case 4:
        return "HD";
      case 20:
        return "MP4";
      default:
        return "Unknown";
    }
  }


  isPasswordGroupInvalid() {
    return this.form.hasError('notEqual') && this.form.get('password1').touched && this.form.get('password2').touched
  }

  isPassword1Invalid() {
    return this.form.get('password1').invalid && this.form.get('password1').touched
  }

  isPassword2Invalid() {
    return this.form.get('password2').invalid && this.form.get('password2').touched
  }

}
