import {ListItemFactory} from '../../common-components/master-view-list/interfaces/list-item.factory';
import {List, ListItem} from '../../common-components/master-view-list/models/list-item';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';
import FolderListItemModel from '../../common-components/master-view-list/interfaces/folder-list-item-model';
import {AMPFolder} from '../../_models/folder';
import {Schedule} from '../../_models/schedule';

const buildSchedulesMap = (config: ListItemConfig) => {
    return (schedule: Schedule) => {
        return new ListItem(config, () => { return schedule.name }, schedule);
    }
};

export class ScheduleFolderListItemModel implements FolderListItemModel {
    private readonly _folder: AMPFolder;
    private readonly _schedules: Array<Schedule>;
    isFolderListItemModel = true;

    constructor(folder, schedules) {
        this._folder = folder;
        this._schedules = schedules;
    }

    get folder(): AMPFolder {
        return this._folder;
    }

    get schedules(): Array<Schedule> {
        return this._schedules;
    }
}

export class SchedulesListItemFactory implements ListItemFactory<Schedule> {

    buildListItem(model: Schedule, config: ListItemConfig): ListItem {
        return buildSchedulesMap(config)(model);
    }

}

export class ScheduleFolderListItemFactory implements ListItemFactory<ScheduleFolderListItemModel> {
    private readonly listHeading;

    constructor(listHeading) {
        this.listHeading = listHeading;
    }

    buildListItem(model: ScheduleFolderListItemModel, config: ListItemConfig): ListItem {
        return new List(config, this.listHeading, model, model.schedules.map(buildSchedulesMap(config)))
    }

}


