import {Component, Input} from '@angular/core';
import {EditModeController} from './edit-mode.controller';

@Component({
    selector: 'app-edit-mode-actions',
    templateUrl: './edit-mode-actions.component.html',
    styleUrls: ['./edit-mode-actions.component.scss']
})
export class EditModeActionsComponent {
    @Input() editableModel: any;
    @Input() controller: EditModeController<any>;
}
