import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ResourceDetails, TreeViewArgs, TimeScaleModel } from '@syncfusion/ej2-schedule';
import { ProgramSequence } from '../../_models/program-sequence';
import { SequenceAction } from '../../_models/sequence-action';
import { Playlist } from '../../_models/playlist';
import { Programme } from '../../_models/programme';
import {
    EventSettingsModel, ScheduleComponent, GroupModel, View, DayService,
    MonthService, WorkWeekService, TimelineViewsService, ResizeService, DragAndDropService, AgendaService, WeekService
} from '@syncfusion/ej2-angular-schedule';
import { Account } from '../../_models/account';
import { sequence } from '@angular/animations';

@Component({
    selector: 'amp-day-timeline-calender',
    templateUrl: './day-timeline-calender.component.html',
    providers: [DayService, AgendaService, ResizeService, DragAndDropService],
    styleUrls: [
        // '../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
        './day-timeline-calender.component.scss'
    ]
})



export class DayTimelineCalenderComponent implements OnInit {
    private static VIDEO_TYPE = 1;
    private static AUDIO_TYPE = 2;
    private static ACTION_TYPE = 3;

    private static SEQUENCE_TYPE_NAME = ['Video', 'Audio', 'Action'];

    private static EVENT_TYPE_PLAYLIST = 1;


    public resourceDataSource: Object[] = [
        { Text: 'Audio Sequence', Id: DayTimelineCalenderComponent.AUDIO_TYPE, Color: '#357cd2' },
        { Text: 'Video Sequence', Id: DayTimelineCalenderComponent.VIDEO_TYPE, Color: '#1aaa55' },
        { Text: 'Action Sequence', Id: DayTimelineCalenderComponent.ACTION_TYPE, Color: '#7fa900' }
    ];


    @Input()
    playlists: Array<Playlist>;

    @Input()
    activeProgram: Programme;

    @Output()
    updatedProgram: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    account: Account;


    @ViewChild('scheduleObj', { static: false })
    private scheduleObj: ScheduleComponent;
    private scheduleComponent: ScheduleComponent;

    public timeScale: TimeScaleModel = { enable: true, interval: 15, slotCount: 1 };

    public selectedDate: Date = new Date();
    public playlistFields: Object = { text: 'name', value: 'id' };
    public mediaTypeFields: Object = { text: 'name', value: 'id' };
    // public eventSettings: any;
    mediaType: Object[] = [{ 'name': 'audio', id: DayTimelineCalenderComponent.AUDIO_TYPE }, { 'name': 'video', id: DayTimelineCalenderComponent.VIDEO_TYPE }];

    private programData: Object[] = [];
    public eventSettings: EventSettingsModel = {
        dataSource: this.programData,
        fields: {
            subject: { title: 'Name', name: 'Name' },
            // description: { title: 'Summary', name: 'Description' },
            startTime: { title: 'From', name: 'StartTime' },
            endTime: { title: 'To', name: 'EndTime' }
        }
    };

    public group: GroupModel = { allowGroupEdit: false, resources: ['Programme'] };


    private static startTimeFromEvent(startTime: Date) {
        const startTimeInSeconds = startTime.getHours() * 3600 + startTime.getMinutes() * 60 + startTime.getSeconds();
        return startTimeInSeconds;
    }


    constructor(
    ) { }

    ngOnInit(): void {
    }

    getSequenceName(value: ResourceDetails | TreeViewArgs): string {
        const s = ((value as ResourceDetails).resourceData) ?
            (value as ResourceDetails).resourceData[(value as ResourceDetails).resource.textField] as string
            : (value as TreeViewArgs).resourceName;
        return s;
    }

    getSequenceImage(value: ResourceDetails | TreeViewArgs): string {
        const resourceName: string = this.getSequenceName(value);
        return resourceName.replace(' ', '-').toLowerCase();
    }

    public dateParser(data: string) {
        return new Date(data);
    }



    onClear(): void {
        // document.getElementById('EventLog').innerHTML = '';
    }


    onCreate(): void {
        console.log('create');
        this.scheduleComponent = this.scheduleObj;
    }


    onActionBegin(event): void {
    }


    onActionComplete(event): void {
        if (event.requestType !== 'eventCreated' && event.requestType !== 'eventRemoved' && event.requestType !== 'eventChanged') {
            return;
        }


        const sequenceIndex = 0;


        if (event.requestType === 'eventCreated') {
            const startTimeInSeconds = DayTimelineCalenderComponent.startTimeFromEvent(event.data[0].StartTime);
            // Create a sequence if one exists
            if (this.activeProgram.sequences === undefined || this.activeProgram.sequences.length === 0) {
                const sequenceType = event.data[0].type;
                const sequenceName = this.activeProgram.name + '-' + DayTimelineCalenderComponent.SEQUENCE_TYPE_NAME[sequenceType - 1];
                const sequence = new ProgramSequence(0, sequenceName, sequenceType, 0, []);
                this.activeProgram.sequences = [];
                this.activeProgram.sequences.push(sequence);
            }
            const duration = Math.abs(event.data[0].EndTime - event.data[0].StartTime) / 1000;

            const playlist: Playlist = this.findPlaylist(event.data[0].playlist);
            const sequenceAction = new SequenceAction(0, playlist.name, DayTimelineCalenderComponent.EVENT_TYPE_PLAYLIST, startTimeInSeconds, duration,
                '' + playlist.id, 0, true, false);
            this.activeProgram.sequences[sequenceIndex].actions.push(sequenceAction);
        }

        else if (event.requestType === 'eventRemoved') {
            const startTimeInSeconds = DayTimelineCalenderComponent.startTimeFromEvent(event.data[0].StartTime);
            let index = -1;
            for (let i = 0; i < this.activeProgram.sequences[sequenceIndex].actions.length; i++) {
                const action: SequenceAction = this.activeProgram.sequences[sequenceIndex].actions[i];
                if (action.startSeconds === startTimeInSeconds) {
                    index = i;
                    break;
                }
            }
            if (index !== -1) {
                this.activeProgram.sequences[0].actions.splice(index, 1);
            }
        }

        else if (event.requestType === 'eventChanged') {
            const startTimeInSeconds = DayTimelineCalenderComponent.startTimeFromEvent(event.data.StartTime);
            const duration = Math.abs(event.data.EndTime - event.data.StartTime) / 1000;
            const parts = event.data.EventId.split(':');
            const seqIndex = parts[0];
            const actionIndex = parts[1];
            const sequenceAction = this.activeProgram.sequences[seqIndex].actions[actionIndex];
            sequenceAction.startSeconds = startTimeInSeconds;
            sequenceAction.duration = duration;
            const playlist: Playlist = this.findPlaylist(event.data.playlist);
            sequenceAction.name = playlist.name;
            sequenceAction.shuffle = false;
            sequenceAction.identifier = '' + playlist.id;
        }

        this.setActiveProgram(this.activeProgram);
    }


    onActionFailure(): void {
        // console.log('action failure');
    }
    onCellDoubleClick(): void {
        // console.log('double click');
    }
    onCellClick(): void {
        // console.log('cell click');
    }
    onNavigating(): void {
        // console.log('on navigating');
    }
    onDestroyed(): void {
        console.log('on destroyed');

    }
    onEventClick(): void {
        // console.log('on event click');
    }
    onPopupOpen(): void {
        console.log('on popup open');
    }
    printval(value) {
        console.log(value);
    }




    // KC

    findPlaylist(playlistId): Playlist {
        for (let i = 0; i < this.playlists.length; i++) {
            if (this.playlists[i].id === playlistId) {
                return this.playlists[i];
            }
        }
        return null;
    }


    /**
     * Update the programme when a new one is selected.
     *
     * @param programme
     */
    public setActiveProgram(programme: Programme) {
        this.activeProgram = programme;
        this.programData.length = 0;
        var id = 1;
        for (let i = 0; i < programme.sequences.length; i++) {
            const sequence = programme.sequences[i];
            for (let a = 0; a < sequence.actions.length; a++) {
                const action = sequence.actions[a];

                const actionStart = this.dateTimeForSeconds(action.startSeconds);
                const actionEnd = this.dateTimeForSeconds(action.startSeconds + action.duration);

                const eventId = '' + i + ':' + a;
                const event = this.createActionEvent(id, action.name, actionStart, actionEnd, sequence.type, eventId, action.identifier, action.shuffle);
                this.programData.push(event);

                id++;
            }
        }
        this.scheduleObj.refreshEvents();
        // this.scheduleObj.refresh();
        this.updatedProgram.emit(this.activeProgram);
    }

    private dateTimeForSeconds(seconds): Date {
        const t: Date = new Date();
        const hh = Math.floor(seconds / 3600);
        const mm = Math.floor(seconds - (hh * 3600)) / 60;
        const ss = seconds % 60;
        t.setHours(hh, mm, ss);
        return t;
    }

    private createActionEvent(id, name, startTime, endTime, type, eventId, identifier, shuffle): any {
        const columnId = type;
        const event = {
            Id: id,
            Name: name,
            StartTime: startTime,
            EndTime: endTime,
            ColumnId: columnId,
            playlist: identifier,
            EventId: eventId,
            shuffle: shuffle
        };
        return event;
    }

}
