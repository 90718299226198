import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { PlaylistComponent } from 'app/playlist/playlist.component';
import {AppComponent} from '../../app.component';


@Component({
  selector: 'amp-audio-preview-modal',
  templateUrl: './audio-preview-modal.component.html',
  styleUrls: ['./audio-preview-modal.component.scss']
})
export class AudioPreviewModalComponent implements OnInit {

  public contentItem: any;
  public url: SafeResourceUrl;
  public artistName: any;
  public duration: any;


  constructor(private activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {

      let folderPath = this.contentItem.runtimePath;
      const dirLevels = folderPath.split('/');
      let fileName = dirLevels.pop();
      fileName = fileName.replace('.amp', '.m4a');

        // Audio Sample instead of audio
      folderPath = folderPath.replace('Audio/', 'audio/sample/');
      const url = 'https://www.atmosphere365.com/' + folderPath + '/' + fileName;
      const encodedURI = AppComponent.encodeURI(url);
      // console.log('audio url:' + encodedURI);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(encodedURI);
  }

  close() {
    this.activeModal.close(this);
  }

  getName(name) {
    return name.replace(PlaylistComponent.reIndex, '');
  }
}
