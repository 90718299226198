import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'amp-form-entry-errors',
  templateUrl: './form-entry-errors.component.html'
})
export class FormEntryErrorsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() entryName: string = "";

  @Input() minLength: number = 2;
  @Input() maxLength: number = 30;

  control: AbstractControl;

  constructor() { }

  ngOnInit() {
    this.control = this.form.get(this.entryName);
  }

}
