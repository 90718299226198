import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {AccountService} from '../../_services/account.service';
import {RenditionService} from '../../_services/rendition.service';

@Component({
    selector: 'amp-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {

    @Input() product: any;
    @Input() productImage: string;
    @Input() installationsCount: number;

    constructor(
        private account: AccountService,
        private renditions: RenditionService
    ) {
    }

    getDisplayName() {
        const productTranslation = this.product.translations.find(translation => translation.language === this.account.getLanguage());
        return (productTranslation) ? productTranslation.displayName : this.product.name;
    }

    getRenditions() {
        return this.product.renditions.map((productRendition) => {
            return this.renditions.get(productRendition.renditionId);
        });
    }
}

