import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import 'rxjs/add/operator/map';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {AccountService} from './account.service';
import {Tag} from '../_models/tag';
import {TagsResponse} from '../_rest/tags-response';
import {AccountListResponse} from '../_rest/account-list-response';
import {FilteredPlaylist, Playlist} from '../_models/playlist';
import {forkJoin} from 'rxjs';
import {Programme} from '../_models/programme';
import {Artist, Channel, Schedule} from '../_models/schedule';
import {ArtistListResponse} from '../_rest/artistlist-response';
import {ProductsResponse} from '../_rest/products-response';
import {ProductPayload} from '../_rest/payloads/product-payload';
import {AMPFolder} from '../_models/folder';
import {Category} from '../_models/category';
import {PlaylistFilter} from '../_models/playlist-filter';


@Injectable()
export class AudioLibraryService {

    constructor(private http: HttpClient,
                private router: Router,
                private account: AccountService) {
    }

    public fetchTags(productFilters = []): Observable<Tag[]> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/tags`;
        const productList = this.createProductList(productFilters);
        if (productList != null) {
            url += '?' + productList;
        }
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        return this.http.get(url, options)
            .map((response: HttpResponse<TagsResponse>) => response.body.responseData);
    }

    private createProductList(productFilters = []) : string
    {
        if (productFilters == null || productFilters.length === 0) {
            return null;
        }

        productFilters.sort();
        const ids = productFilters.join(',');
        return 'products=' + ids;
    }

    public fetchContentByCategoryTypeAndTagID(categoryType: number, tagid: string, productFilters = []): Observable<any> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/1/cattype/${categoryType}/tag/${tagid}`;
        const productList = this.createProductList(productFilters);
        if (productList != null) {
            url += '?' + productList;
        }
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
        // return this.http.get(url, options)
        //     .map((response: HttpResponse<any>) => {
        //         return response.body.responseData;
        //     });
    }


    public fetchContentByCategoryTypeAndArtistId(categoryType: number, artistId: number, productFilters = []): Observable<any> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/1/cattype/${categoryType}/artist/${artistId}`;
        const productList = this.createProductList(productFilters);
        if (productList != null) {
            url += '?' + productList;
        }

        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
        // return this.http.get(url, options)
        //     .map((response: HttpResponse<any>) => {
        //         return response.body.responseData
        //     });
    }

    public fetchContentByCategoryTypeAndCategoryId(categoryType: number, categoryId: string, productFilter = []): Observable<any> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/${categoryType}/category/${categoryId}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        const productList = this.createProductList(productFilter);
        if (productList != null) {
            url += '?' + productList;
        }

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public addOrUpdateContentToPlaylist(playlist: Playlist): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/playlist`;
        playlist.accountId = this.getAccountNumber(this.account.accountId);
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        const body = playlist;
        return this.http.post(url, body, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public deletePlaylist(playlist: Playlist): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/playlist/${playlist.id}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.delete(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public copyPlaylist(playlist: Playlist, playlistType: number): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/playlist/${playlist.id}/copy/${playlistType}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });

    }

    public createOrUpdateFolder(folder: AMPFolder): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/folder`;
        folder.accountId = this.getAccountNumber(this.account.accountId)
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        const body = folder;
        return this.http.post(url, body, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public deleteFolder(folder: AMPFolder): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/folder/${folder.id}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.delete(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public fetchFoldersByTypeIdentifier(typeIdentifier: number): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/folders/${typeIdentifier}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public promisifiedFetchPlaylistsWithProcessing(type: number = 0): Promise<Array<Playlist>> {
        const buildPlaylistFinder = (playlistId) => {
            return (playlist => playlist.id === playlistId)
        };
        return this.fetchPlaylists(type).toPromise().then(playlists => {
            const playlistsToProcess = playlists.filter(playlist => playlist.duration === 0 && playlist.movieIdentifiers.length > 0);
            const playlistProcessPromises = playlistsToProcess.map(playlist => this.addOrUpdateContentToPlaylist(playlist).toPromise());
            return Promise.all(playlistProcessPromises).then(processedPlaylists => {
                return playlists.map(playlist => {
                    const procPlaylist = processedPlaylists.find(buildPlaylistFinder(playlist.id));
                    return (procPlaylist) ? procPlaylist : playlist;
                });
            });
        });
    }

    public fetchPlaylists(type: number = 0): Observable<Playlist[]> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/playlists/${type}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public generatePlaylistMovies(playlist: FilteredPlaylist): Observable<any[]> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/playlist/movies/generate`;

        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        const body = {
            ...playlist,
            filters: playlist.filters.map(filter => {
                return {
                    ...filter,
                    filterIdentifier: filter.filterIdentifierAsString,
                    id: 0
                } as PlaylistFilter
            }),
            movieIdentifiers: [],
            movieIdentifiersAsStrings: []
        };

        return this.http.post(url, body, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }


    public fetchPlaylistMovies(playlistId: number): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/playlist/${playlistId}/movies`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }


    public fetchVideoContentByIdentifier(contentIdentifiers: Array<string>): Observable<any> {
        const responseList = [];
        for (const identifier of contentIdentifiers) {
            const url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitem/${identifier}/movies`;
            let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

            responseList.push(this.http.get(url, options).map((response: HttpResponse<any>) => {
                return response.body.responseData;
            }));
        }
        return forkJoin(responseList);
    }

    public fetchAudioLibrary(productFilters = []): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/1/cattype/${2}/recent/100`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        const body = JSON.stringify(productFilters);
        return this.http.post(url, body, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public fetchVideoLibrary(productFilters = []): Observable<any> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/1/cattype/${1}/recent/100`;
        const productList = this.createProductList(productFilters);
        if (productList != null) {
            url += '?' + productList;
        }

        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
         return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public fetchArtists(mediaType: number): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/artists/mediatype/${mediaType}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<ArtistListResponse>) => response.body.responseData)
            .map((artistPayloads: object[]) => {
                const artists: Artist[] = [];

                for (const artistPayload of artistPayloads) {
                    const artist = new Artist(artistPayload['id'], artistPayload['name']);
                    artists.push(artist);
                }
                return artists;
            });
    }

    public createOrUpdateProgram(program: Programme): Observable<any> {
        const re = new RegExp('[a-z A-Z]', 'g');
        // tslint:disable-next-line:radix
        program.accountId = parseInt(this.account.accountId.replace(re, ''));
        // program.sequences[i].accountId = program.accountId;
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/programme/${program.id}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        const body = program;
        return this.http.post(url, body, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public copyProgram(program: Programme): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/programme/${program.id}/copy`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public fetchPrograms(): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/programmes`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public deleteProgram(program: Programme): Observable<any> {
        const re = new RegExp('[a-z A-Z]', 'g');
        // tslint:disable-next-line:radix
        program.accountId = parseInt(this.account.accountId.replace(re, ''));
        // program.sequences[i].accountId = program.accountId;
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/programme/${program.id}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};


        return this.http.delete(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public createOrUpdateSchedule(schedule: Schedule): Observable<any> {
        const re = new RegExp('[a-z A-Z]', 'g');
        // tslint:disable-next-line:radix
        schedule.accountId = parseInt(this.account.accountId.replace(re, ''));
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/schedule/${schedule.id}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        const body = schedule;
        return this.http.post(url, body, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public fetchSchedules(): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/schedules`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public deleteSchedule(schedule: Schedule): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/schedule/${schedule.id}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.delete(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    private getAccountNumber(accountId: string): number {
        const accountNumber = accountId.substr(2);
        return +accountNumber;
    }

    public fetchCategories(type: number = 1): Observable<any> {
        const url = `${environment.server}/ampapi/account/${this.account.accountId}/categories/${type}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                const responseData = response.body.responseData;
                return responseData.map(category => {
                    return new Category(
                        category.id,
                        category.idAsString,
                        category.name,
                        category.translations,
                        category.type);
                });
            });
    }

    public fetchChannels(productFilters = [], type = 1): Observable<any> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/5/cattype/${type}/recent/100`;
        const productList = this.createProductList(productFilters);
        if (productList != null) {
            url += '?' + productList;
        }

        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};

        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }

    public fetchSeries(productFilters = []): Observable<any> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/3/cattype/1/recent/0`;
        const productList = this.createProductList(productFilters);
        if (productList != null) {
            url += '?' + productList;
        }

        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
        // return this.http.get(url, options)
        //     .map((response: HttpResponse<any>) => {
        //         return response.body.responseData;
        //     });
    }

    public fetchAlbums(productFilters = [], type = 1): Observable<any> {
        var url = `${environment.server}/ampapi/account/${this.account.accountId}/contentitems/2/cattype/${type}/recent/100`;
        const productList = this.createProductList(productFilters);
        if (productList != null)
        {
            url += '?' + productList;
        }

        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }


    public searchServer(phrases: string, type: number): Observable<any> {
        let termsArr = phrases.split(" ");
        let terms = termsArr.join(",");

        var url = `${environment.server}/ampapi/account/${this.account.accountId}/search/1/cattype/${type}?terms=${terms}`;
        let options = {headers: this.account.getHeaders(), observe: 'response' as 'response'};
        return this.http.get(url, options)
            .map((response: HttpResponse<any>) => {
                return response.body.responseData;
            });
    }


}
