import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AudioLibraryService} from '../_services/audio-library.service';
import {AlertService} from '../_alert/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Artist, Channel} from '../_models/schedule';
import {Playlist} from '../_models/playlist';
import {Tag} from '../_models/tag';

import {ListItem} from '../common-components/master-view-list/models/list-item';
import {AudioLibraryGenreListItemFactory} from './util/audio-lib-genre-listitem-factory';
import {AudioLibraryArtistListItemFactory} from './util/audio-lib-artist-listitem-factory';
import {MasterViewListItemEventDelegate} from '../common-components/master-view-list/interfaces/master-view-list-item-event-delegate';
import {MasterViewListItemController} from '../common-components/master-view-list/interfaces/master-view-list-item';
import {ListItemConfig} from '../common-components/master-view-list/models/list-item-config';
import ListItemIconConfigFactory from '../common-components/master-view-list/util/list-item-config-factory';
import {
    MasterViewListMediator,
    NestedMasterViewListMediator
} from '../common-components/master-view-list/interfaces/master-view-list-mediator';
import {StaticListItemComparator} from '../common-components/master-view-list/util/static-list-item-comparator';
import {GenericStaticListItemFactory, GenericTreeListItemFactory, StaticListItemModel} from '../video-library/util/generic-listitem-factories';
import {MultiSelectListMediator} from 'app/common-components/master-view-list/interfaces/mulit-select-list';
import {LibraryModalDelegate} from './library-action-menu/library-action-menu.component';
import {processPlaylistCollections} from '../playlist/iplaylist-tab-view';
import {AMPFolder} from '../_models/folder';
import {toPromise} from "rxjs-compat/operator/toPromise";


@Component({
    selector: 'amp-audio-library',
    templateUrl: './audio-lib.component.html',
    styleUrls: ['./audio-lib.component.scss']
})

export class AudioLibraryComponent implements OnInit, MasterViewListItemController, MasterViewListItemEventDelegate, LibraryModalDelegate {

    // List Control & Management
    masterViewListMediator: MasterViewListMediator;
    masterViewList: Array<ListItem>;
    multiSelectListMediator: MultiSelectListMediator;

    private loadingArtists: boolean;
    activeTab: string;
    mood: Array<object>;
    genre: Array<object>;
    type: Array<object>;
    currentTab: string;
    tabContent: Array<object> = [];
    filteredTabContent: Array<object> = [];
    artists: Array<Artist>;
    artistsMap: Map<number, Artist> = new Map<number, Artist>();
    playlists: Array<Playlist> = [];
    playlistFolders: Array<AMPFolder> = [];
    playlistListItems: Array<ListItem> = [];
    genres: Array<Tag> = [];

    public searchFilter = '';
    public productFilters = [];
    public productFiltersVisible = false;
    public serverSearchPhrase = '';


    constructor(private router: Router,
                private alert: AlertService,
                private translate: TranslateService,
                private audioLibraryService: AudioLibraryService) {
        this.masterViewList = [];
        this.masterViewListMediator = new NestedMasterViewListMediator();
        this.multiSelectListMediator = new MultiSelectListMediator();
    }


    ngOnInit(): void {
        this.getPlaylists()
            .then(() => this.loadAudioLibraryCollection())
            .then(() => this.loadAudioPlaylistFolders())
            .finally(() => this.buildPlaylistList());
    }


    loadAudioLibraryCollection(): Promise<void> {
        return Promise.all(
            [
                this.getRecentSongs(),
                this.getAllArtists(),
                this.getChannels(),
                this.getTags(),
            ])
            // .catch(this.handleServiceError)
            .then(listItemResults => {
                listItemResults.forEach(listItem => {
                    if (listItem.hasOwnProperty('tags')) {
                        listItem.tags.forEach(item => {
                            this.pushToMasterViewList(item)
                        })
                    } else {
                        this.pushToMasterViewList(listItem)
                    }
                });
                // this.listItemSelected({}, listItemResults[0]);
            })
            .catch(this.handleServiceError);
    }

    loadProductOrientedLibraryCollection() {
        //const old = this.clearMasterViewList();
        const idx_offset = 0;
        Promise.all(
            [
                this.getRecentSongs(),
                this.getAllArtists(),
                this.getChannels(),
                this.getTags(),
            ]
        ).then(listItemResults => {
            listItemResults.forEach((listItem, i) => {
                if (listItem.hasOwnProperty('tags')) {
                    listItem.tags.forEach((item, j) => {
                        this.masterViewList[i + idx_offset + j] = item
                    })
                } else {
                    this.masterViewList[i + idx_offset] = listItem
                }
            });
            this.listItemSelected({}, listItemResults[0]);
        }).catch(this.handleServiceError).finally(() => {
            this.getDetailViewForListItem(this.masterViewList[0]);
        });
    }

    handleServiceError(error) {
        if (error.status === 0) {
            this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            return;
        }
        try {
            const response = JSON.parse(error.text());
            this.alert.error(response.responseData);
        } catch (e) {
            this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
        }
    }

    getTags(): Promise<any> {
        return this.audioLibraryService.fetchTags(this.productFilters).toPromise()
            .then((res) => {
                const relevantGenreTags = ['hits', 'type', 'genre', 'language', 'mood'];
                var result = {'tags': []}
                res.forEach(tagGroup => {
                    relevantGenreTags.forEach((rTag => {
                        if (tagGroup.name.toLowerCase() === rTag) {
                            result.tags.push(this.getGenresList(tagGroup.name, tagGroup.children));
                        }
                    }));

                    if (tagGroup.name.toLowerCase() === 'mood') {
                        this.mood = tagGroup.children;
                    }

                    if (tagGroup.name.toLowerCase() === 'type') {
                        this.type = tagGroup.children;
                    }

                    if (tagGroup.name.toLowerCase() === 'genre') {
                        this.genres = this.flattenGenres(tagGroup.children);
                    }
                });
                return result
            });
    }

    getPlaylists() {
        return this.audioLibraryService.fetchPlaylists(2).toPromise().then(res => {
            this.playlists = res;
        }, error => {
            if (error.status === 0) {
                this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
                return;
            }
            const response = JSON.parse(error.text());
            this.alert.error(response.responseData);
        });
    }

    loadAudioPlaylistFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(1).toPromise().then(res => {
            return this.playlistFolders = res;
        });
    }

    getContentByTag(tag) {
        this.currentTab = tag.name;
        if (tag instanceof Artist) {
            this.audioLibraryService.fetchContentByCategoryTypeAndArtistId(2, tag.id, this.productFilters)
                .subscribe(content => {
                    this.tabContent = content;
                    this.filteredTabContent = content;
                });
        } else {
            this.audioLibraryService.fetchContentByCategoryTypeAndTagID(2, tag.idString, this.productFilters)
                .subscribe(content => {
                    this.tabContent = content;
                    this.filteredTabContent = content;
                });
        }
    }

    getContentByChannel(channel) {
        this.currentTab = channel.name;
        this.audioLibraryService.fetchVideoContentByIdentifier([channel.id])
            .subscribe(content => {
                this.tabContent = content[0];
                this.filteredTabContent = content[0];
            });
    }

    loadContentForSIdentifier(sIdentifier: string): Promise<any> {
        return this.audioLibraryService.fetchVideoContentByIdentifier([sIdentifier]).toPromise();
    }

    getRecentSongs(): Promise<ListItem> {
        return this.audioLibraryService.fetchAudioLibrary(this.productFilters).toPromise()
            .then(recentsongs => {
                const recentSongsTab = 'Recent Songs';
                return this.buildRecentlyPlayedItem(recentsongs, recentSongsTab);
            });
    }

    getChannels(): Promise<ListItem> {
        return this.audioLibraryService.fetchChannels(this.productFilters, 2)
            .toPromise()
            .then(channels => {
                const arr: Channel[] = channels.map(channel => {
                    return new Channel(
                        channel.id,
                        channel.name,
                    );
                });
                return this.buildChannelsList(arr);
            });
    }


    getAllArtists(): Promise<ListItem> {
        if (this.loadingArtists) {
            return;
        }

        this.loadingArtists = true;
        return this.audioLibraryService.fetchArtists(2).toPromise()
            .then((artists: Artist[]) => {
                this.artists = artists;
                for (const artist of artists) {
                    this.artistsMap[artist.id] = artist;
                }
                return this.buildArtistsList(this.artists);
            })
            .finally(() => {
                this.loadingArtists = false;
            })
    }

    buildRecentlyPlayedItem(recentSongs, tabName): ListItem {
        const staticListItemFactory = new GenericStaticListItemFactory();

        const listItemIconConfigFactory: ListItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_sequence_uncolored.png',
            '../../assets/img/icons_ic_sequence.png',
            {},
            'sequence-ico'
        );

        const config = new ListItemConfig({
            left: leftHeadingIcoConf,
            leftHeading: leftHeadingIcoConf
        });
        const listItem = staticListItemFactory.buildListItem(
            new StaticListItemModel(tabName, recentSongs),
            config
        );
        this.listItemSelected({event: {}, nestedDepth: 1}, listItem);
        return listItem;
    }

    pushRecentlyPlayedAsList(recentSongs, tabName) {
        const listItem = this.buildRecentlyPlayedItem(recentSongs, tabName);
        this.pushToMasterViewList(listItem);
        this.listItemSelected({}, listItem);
    }

    getGenresList(heading, genres) {
        const listItemIconConfigFactory: ListItemIconConfigFactory = new ListItemIconConfigFactory();
        const genreListFactory = new AudioLibraryGenreListItemFactory(heading);

        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_sequence_uncolored.png',
            '../../assets/img/icons_ic_sequence_uncolored.png',
            {},
            'sequence-ico'
        );

        const leftIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_sequence_uncolored.png',
            '../../assets/img/icons_ic_sequence.png',
            {},
            'sequence-ico'
        );

        const genresListConfig = new ListItemConfig({
            leftHeading: leftHeadingIcoConf,
            left: leftIcoConf
        });

        return genreListFactory.buildListItem(genres, genresListConfig);
    }


    buildArtistsList(artists: Artist[]) {
        const artistListFactory = new AudioLibraryArtistListItemFactory();
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/ic_artist.svg',
            '../../assets/img/ic_artist_selected.svg',
            {},
            'audio-artist-ico'
        );
        const leftIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/ic_artist.svg',
            '../../assets/img/ic_artist_selected.svg',
            {},
            'audio-artist-ico'
        );

        const artistsListConfig = new ListItemConfig({
            leftHeading: leftHeadingIcoConf,
            left: leftIcoConf
        });
        return artistListFactory.buildListItem(artists, artistsListConfig);
    }

    buildChannelsList(channels: Channel[]) {
        const listFactory = new GenericTreeListItemFactory('Channels', 'children');
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_channel_uncolored.png',
            '../../assets/img/icons_ic_channel_colored.png',
            {},
            'channel-ico'
        );

        const listConfig = new ListItemConfig({
            leftHeading: leftIcoConf,
            left: leftIcoConf
        });
        return listFactory.buildListItem(channels, listConfig)
    }

    isStaticListItem(listItem) {
        const innerModel = listItem.getInnerModel();
        return innerModel instanceof StaticListItemModel && innerModel.isStaticItem
    }

    listItemSelected(eventPayload, listItem) {
        this.masterViewListMediator.setSelectedListItem(listItem, eventPayload.nestedDepth)
        this.getDetailViewForListItem(listItem);
    }

    getMasterViewList(): ListItem[] {
        return this.masterViewList;
    }

    pushToMasterViewList(listItem: any): void {
        this.masterViewList.push(listItem);
        this.masterViewList = this.masterViewList.sort(StaticListItemComparator);
    }

    flattenGenres(genres): Array<Tag> {
        return genres.concat(genres.flatMap(e => e.children));
    }

    getGenresFromTags(tags) {
        const result = [];

        for (const tag of tags) {
            const genreIndex = this.genres.map(e => e.idString).indexOf(tag.idString);
            if (genreIndex > -1) {
                result.push(this.genres[genreIndex].name);
            }
        }

        return result.join(', ');
    }

    clearMasterViewList(): Array<ListItem> {
        const cachedList = this.masterViewList;
        this.masterViewList = [];
        return cachedList;
    }

    getDetailViewForListItem(listItem): void {
        this.multiSelectListMediator.clearSelection();

        if (this.isStaticListItem(listItem)) {
            this.tabContent = listItem.getInnerModel().tabContent;
            this.filteredTabContent = listItem.getInnerModel().tabContent;
            this.currentTab = listItem.getInnerModel().tabName;
        } else if (listItem.getInnerModel() instanceof Channel) {
            // this.loadContentForSIdentifier(listItem.getInnerModel().id)
            this.getContentByChannel(listItem.getInnerModel())
        } else if (!(listItem.getInnerModel() instanceof Array)) {
            this.getContentByTag(listItem.getInnerModel());
        }
    }


    buildPlaylistList() {
        this.playlistListItems = processPlaylistCollections(this.playlistFolders, this.playlists.filter(playlist => {
            return playlist.playlistType === 1;
        }));
    }

    success() {
        Promise.all([this.getPlaylists(), this.loadAudioPlaylistFolders()]).then(res => {
            this.buildPlaylistList();
        });
    }

    cancelProductFilter() {
        this.productFiltersVisible = false;
    }

    setProductFilter(products) {
        this.productFilters = products;
        if (this.masterViewListMediator.isLastItemSelected()) {

            this.loadProductOrientedLibraryCollection()
        }
    }

    onSearchChange(event) {
        let listItems
        if (this.searchFilter !== '') {
            listItems = this.tabContent.filter(this.getFilterFunc(this.searchFilter));
        } else {
            listItems = this.tabContent;
        }
        this.filteredTabContent = listItems;
    }

    getFilterFunc(searchFilter) {
        return (item) => {
            return item.name.toLowerCase().includes(searchFilter.toLowerCase()) || this.artistsMap[item.artistId].name.toLowerCase().includes(searchFilter.toLowerCase());
        };
    }

    queryServerSearch() {
        if (this.serverSearchPhrase.length > 0) {
            this.audioLibraryService.searchServer(this.serverSearchPhrase, 2).toPromise()
                .then(res => {
                    if (this.masterViewListMediator.isLastItemSelected()) {
                        this.masterViewListMediator.setSelectedListItem(this.masterViewListMediator.getLastSelectedItem(), 0);
                    }
                    this.currentTab = 'Search results'
                    this.filteredTabContent = res;
                });
        }
    }
}
