import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { PlaylistComponent } from 'app/playlist/playlist.component';


@Component({
  selector: 'amp-video-preview-modal',
  templateUrl: './video-preview-modal.component.html',
  styleUrls: ['./video-preview-modal.component.scss']
})
export class VideoPreviewModalComponent implements OnInit {

  public contentItem: any;
  public url: SafeResourceUrl;
  public artistName: any;
  public duration: any;


  constructor(private activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.contentItem.promoVideoId);
  }

  close() {
    this.activeModal.close(this);
  }

  getName(name) {
    return name.replace(PlaylistComponent.reIndex, '');
  }
}
