import {ApplicationRef, Component, OnInit} from '@angular/core';
import {Licence} from "../../_models/licence";
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Installation} from "../../_models/installation";
import {ResellerLicences} from "../../_models/reseller-licences";

@Component({
  selector: 'admin-tabs',
  templateUrl: './admin-tabs.component.html',
  styleUrls: ['./admin-tabs.component.scss']
})

export class AdminTabsComponent implements OnInit {

  isLoading = false;

  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true
  }

}
