import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AlertService } from "../../_alert/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { AudioLibraryService } from '../../_services/audio-library.service';
import { Playlist } from '../../_models/playlist';
import Util from '../../Util';
import { PlaylistComponent } from '../playlist.component';
import { DragDroppableComponent } from '../../common-components/drag-drop/drag-drop';
import { MultiSelectListMediator } from "app/common-components/master-view-list/interfaces/mulit-select-list";
import { VideoPreviewModalComponent } from "app/video-library/video-preview-modal/video-preview-modal.component";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "app/app.component";
import { Artist } from "app/_models/schedule";
import { AudioPreviewModalComponent } from "app/audio-library/audio-preview-modal/audio-preview-modal.component";

@Component({
  selector: "amp-playlist-detail",
  templateUrl: "./playlist-detail.component.html",
  styleUrls: ['./playlist-detail.component.scss']
})
export class PlaylistTabContentComponent implements OnInit, DragDroppableComponent {

  @Input() isEditModeOn: boolean;
  @Input() tabContent: Array<object>;
  @Input() currentPlaylist: Playlist;
  @Input() selectedTabIdentifier: number;
  @Input() multiSelectListMediator: MultiSelectListMediator;
  @Output() removeContentIdentifier: EventEmitter<any> = new EventEmitter<any>();
  @Output() reorderPlaylistContentEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() artists: Array<Artist>;
  @Input() artistsMap: any;

  public searchFilter = '';

  constructor(private alert: AlertService,
    private translate: TranslateService,
    private audioLibraryService: AudioLibraryService,
    private modalService: NgbModal,
    private app: AppComponent
  ) { }

  public ngOnInit() {
  }

  getDuration(item) {
      let duration = item.mediaEnd - item.mediaStart;
      if (duration <= 0)
      {
        duration = item.duration;
      }
      const u = new Util();
      return u.millisToMinutesAndSeconds(duration);
  }

  getImgForContent(content): string {
      const img = PlaylistComponent.getImgForContentItem(content);
      return img;
  }

  public removeContent(content: any) {
    this.removeContentIdentifier.emit(content.sIdentifier);
  }

  handleDragoverEvent(event, model: any): void {
    event.preventDefault();
  }

  handleDragstartEvent(event, model: any): void {
    event.dataTransfer.setData('application/json', JSON.stringify(model));
  }

  isDraggable(): boolean {
    return this.isEditModeOn && this.currentPlaylist.playlistType === 1;
  }

  getIndexForPlaylistContent(content) {
    return this.tabContent.findIndex((item: { id }) => item.id === content.id);
  }

  handleDropEvent(event, targetModel: any): void {
    event.preventDefault();

    const droppedModel = JSON.parse(event.dataTransfer.getData('application/json'));
    const targetIndex = this.getIndexForPlaylistContent(targetModel);
    const payloadsIndex = this.getIndexForPlaylistContent(droppedModel);

    this.processListReorder(targetIndex, payloadsIndex);
  }

  processListReorder(targetIndex, payloadIndex) {
      if (!this.multiSelectListMediator.hasSelected()) {
          const playlistContent = [...this.tabContent];
          const offset = (targetIndex > payloadIndex) ? 0 : 1;

          playlistContent.splice(targetIndex, 0, playlistContent[payloadIndex]);
          playlistContent.splice((payloadIndex + offset), 1);

          this.reorderPlaylistContentEmitter.emit(playlistContent);
      }
  }

  selectItem(index) {
      console.log(this.tabContent);
    if (this.currentPlaylist.playlistType === 1 && this.isEditModeOn) {
      this.multiSelectListMediator.selectItem(index);
    }
  }

  isSelected(index) {
    return this.multiSelectListMediator.isSelected(index);
  }

  getSelectedItemStyle(index): string {
    return this.multiSelectListMediator.getSelectedItemStyle(index);
  }

  showVideoPreview(item) {
    // console.log("click");
    const options: NgbModalOptions = {
      size: 'lg'
    };
    const modalRef = this.modalService.open(VideoPreviewModalComponent, options);
    modalRef.componentInstance.contentItem = item;
    modalRef.componentInstance.artistName = this.getArtistForId(item.artistId).name;
    modalRef.componentInstance.duration = this.app.msToTime(item.duration);
  }

  showAudioPreview(contentItem) {

    const options: NgbModalOptions = {
      size: 'lg'
    };
    const modalRef = this.modalService.open(AudioPreviewModalComponent, options);
    modalRef.componentInstance.contentItem = contentItem;
    modalRef.componentInstance.artistName = this.getArtistForId(contentItem.artistId).name;
    modalRef.componentInstance.duration = this.app.msToTime(contentItem.duration);
  }

  getArtistForId(artistId) {
    const foundArtist = this.artists.find(artist => artist.id === artistId);
    return (foundArtist) ? foundArtist : new Artist(-1, '');
  }
    // Search
    getFilterFunc() {
        return (item) => {
            return item.name.toLowerCase().includes(this.searchFilter.toLowerCase()) || this.artistsMap[item.artistId].toLowerCase().includes(this.searchFilter.toLowerCase());
        };
    }

    visibleContent() {
        const content = this.tabContent;
        let listItems;

        if (this.searchFilter !== '') {
            listItems = content.filter(this.getFilterFunc());
        } else {
            listItems = content;
        }

        return listItems;
    }
}

/*

      <div>
          <input
              type="text" class="dropdown__search-bar"
              [(ngModel)]="searchFilter" [ngModelOptions]="{standalone: true}"
              [placeholder]="'Search media'"
              [tabIndex]="0"
          />
      </div>

   // Search
    getFilterFunc() {
        return (listItem: ListItem) => {
            const RULE_1 = !listItem.hasNestedItems() && listItem.getDisplayText().includes(this.searchFilter);
            const RULE_2  = listItem.hasNestedItems() && listItem.getNestedItems().filter(this.getFilterFunc()).length > 0;
            if (RULE_2) {
                listItem.setSelected(true);
            } else {
                listItem.setSelected(false);
            }
            const RULES = RULE_1 || RULE_2;
            return RULES;
        };
    }

    visibleListItems() {
        const masterList = this.getMasterViewList();
        let listItems;

        if (this.searchFilter !== '') {
            listItems = masterList.filter(this.getFilterFunc());
        } else {
            listItems = masterList;
        }

        return listItems;
    }


 */
