import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ValidationService } from "../../_services/validation.service";
import {AMPFolder} from '../../_models/folder';
import {AddProgrammeFormDelegate} from './add-programme-form.delegate';
import {Programme} from '../../_models/programme';

@Component({
    selector: 'amp-add-programme-form',
    templateUrl: './add-programme-form.component.html',
    styleUrls: ['./add-programme-form.component.scss']
})
export class AddProgrammeFormComponent implements OnInit, OnChanges {

    @Input() eventDelegate: AddProgrammeFormDelegate;
    @Input() programmeFolders: Array<AMPFolder>;

    form: FormGroup;

    constructor(
        private validation: ValidationService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            shouldAddToFolder: [false],
            selectedFolder: [{}]
        });
    }

    close() {
        // Update state to default for form grp
        this.reset();
        this.eventDelegate.onAddProgrammeCancel({});
    }

    reset() {
        this.form.reset();
        this.form.controls.name.setValue('');
        this.form.controls.shouldAddToFolder.setValue(false);
        this.form.controls.selectedFolder.setValue(this.programmeFolders[0]);
    }

    onSubmit(form) {
        const folderId = (form.shouldAddToFolder) ? form.selectedFolder.id : 0;

        const program  = new Programme(
            0,
            form.name,
            0,
            folderId,
            []
        );

        this.reset();
        this.eventDelegate.onAddProgrammeSubmit(program);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.programmeFolders.isFirstChange() && changes.programmeFolders.currentValue.length > 0) {
            this.form.controls.selectedFolder.setValue(changes.programmeFolders.currentValue[0]);
        }
    }
    isFoldersAvailable() {
        return (this.programmeFolders && this.programmeFolders.length > 0);
    }
}
