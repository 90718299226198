import { Component, OnInit } from '@angular/core';
import { AccountService } from "../../_services/account.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'app/_services/validation.service';
import { EditModeController, GenericEditModeController } from 'app/common-components/edit-mode/edit-mode.controller';

@Component({
  selector: 'amp-login-details',
  styleUrls: ['./login-details.component.scss'],
  templateUrl: './login-details.component.html'
})
export class LoginDetailsComponent {

  form: FormGroup;

  // Edit Mode Management
  editModeController: EditModeController<Account>;

  constructor(public account: AccountService,
    private formBuilder: FormBuilder,
    private validation: ValidationService) {

    this.editModeController = new GenericEditModeController(
      (obj) => this.saveEditMode(obj),
      (obj) => this.cancelEditMode(obj));

    this.form = this.formBuilder.group({
      password1: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
      password2: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
    }, { validator: this.validation.equalityValidator("password1", "password2") }
    );
  }

  saveEditMode(editObject: Account) {
    console.log(editObject, this.form.valid);

    if (this.isPasswordGroupInvalid()) {
      alert("Form input invalid")
      this.editModeController.startEditMode(editObject)
    } else {
    this.account.updateAccountPassword(this.form.controls['password1'].value)
      .subscribe(response => {
          console.log(response);
        },
        error => {
          console.log(error);
        });
    }
  }

  cancelEditMode(cachedObject: Account) {
    this.form.controls['password1'].setValue("");
    this.form.controls['password2'].setValue("");
  }

  updateForm(account: Account) {
    this.form.controls['companyName'].setValue(account.name);
  }


  isPasswordGroupInvalid() {
    return this.form.hasError('notEqual') && this.form.get('password1').touched && this.form.get('password2').touched
  }

  isPassword1Invalid() {
    return this.form.get('password1').invalid && this.form.get('password1').touched
  }

  isPassword2Invalid() {
    return this.form.get('password2').invalid && this.form.get('password2').touched
  }



}
