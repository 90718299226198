import {ApplicationRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Licence} from "../../_models/licence";
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {AccountComponent} from "../../account/account.component";
import {Account} from "../../_models/account";
import {BusinessDetailsFormComponent} from "../../business-details-form/business-details-form.component";
import {Observable} from "rxjs";
import {Business} from "../../_models/business";
import {BusinessDetailsFormParent} from "../../business-details-form/business-details-form.parent";
import {ActivatedRoute, Route, Router} from "@angular/router";

@Component({
  selector: 'group-venues',
  templateUrl: './group-venues.component.html',
  styleUrls: ['./group-venues.component.scss']
})

export class GroupVenuesComponent implements OnInit, BusinessDetailsFormParent {

    @Output() businessDetailsModelUpdate = new EventEmitter();

  groupVenues: Account[];
  isLoading = false;
  totalVenueCount = 0;
  editingVenue: boolean = false;
  venue: Account = null;
  businessDetailsForm: BusinessDetailsFormComponent;

  constructor(private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute,
              private alert: AlertService,
              private translate: TranslateService)
  {
  }

  ngOnInit() {
    this.isLoading = true
    this.accountService.fetchGroupVenues()
      .subscribe((venues: Account[]) => {
          this.initialiseFromVenues(venues);
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }


  private initialiseFromVenues(venues: Account[]) {
    this.groupVenues = venues;
    this.totalVenueCount = venues.length;
  }


  public addVenue() {
    this.venue = null;
    this.editingVenue = true;
  }

  public editVenue(venue: Account) {
    this.editingVenue = true;
    this.venue = venue;
  }


  public loadAccount() {
    return false;
  }

  public finished() {
    if (this.editingVenue)
    {
      this.editingVenue = false;
      this.ngOnInit();
      return;
    }
    this.editingVenue = false;
    this.venue = null;
  }

  public updateEmail(): boolean {
    return false;
  }

  public getAccount() : Account
  {
    if (this.venue != null)
      return this.venue;

     return new Account(
        0,
        8,
        2,
        "",
        "",
        "",
        "",
        "",
        "",
        null,
        "",
        "",
        "",
        0,
        "",
        "",
        "",
        "",
        "",
        "");

  }





  public saveBusiness(business: Business): Observable<any>
  {
    if (this.venue != null)
      business.organisationId = this.venue.id;
    return this.accountService.updateAccountVenue(business);
  }
}
