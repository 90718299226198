import {ListItem} from '../common-components/master-view-list/models/list-item';
import {Playlist} from '../_models/playlist';
import {AMPFolder} from '../_models/folder';
import {PlaylistFolderListItemFactory, PlaylistFolderListItemModel, PlaylistListItemFactory} from './util/playlist-listitem-factory';
import {ListItemConfig} from '../common-components/master-view-list/models/list-item-config';
import ListItemIconConfigFactory from '../common-components/master-view-list/util/list-item-config-factory';
import {processFolderMap} from '../common-components/util/common-collections-process';

export interface ITabableMasterView<T> {
    getTabIdentifier(): number;
    getMasterViewList(): Array<ListItem>;
    getMasterViewModel(): Array<T>;
}


const getPlaylistIcoConf = (type) => {
    const listItemIconConfigFactory = new ListItemIconConfigFactory();

    let normStateAsset = '../../assets/img/icons_ic_playlist_type.svg';
    let selStateAsset = '../../assets/img/icons_ic_playlist_type.svg';

    if (type === 1) {
        normStateAsset = '../../assets/img/ic_video.png';
        selStateAsset = '../../assets/img/ic_video.png';
    }

    return listItemIconConfigFactory.buildListItemImageIconConfig(
        normStateAsset,
        selStateAsset,
        {},
        'playlist-ico'
        );
};

const buildListItemForPlaylist = (playlist) => {
    const playlistListFactory = new PlaylistListItemFactory();
    const icoConf = getPlaylistIcoConf(playlist.mediaType);
    const config = new ListItemConfig({leftHeading: icoConf, left: icoConf});

    return playlistListFactory.buildListItem(playlist, config);
};

const buildListItemForFolder = (folder, playlists) => {
    const playlistFolderListFactory = new PlaylistFolderListItemFactory(() => folder.folderName);

    const listItemIconConfigFactory = new ListItemIconConfigFactory();
    const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
        '../../assets/img/icons_ic_folder.svg',
        '../../assets/img/icons_ic_folder.svg',
        {},
        'playlist-ico'
    );

    let type = 2;
    if (!!playlists && playlists.length > 0) {
        type = playlists[0].mediaType;
    }

    const playlistsConfig = new ListItemConfig({leftHeading: leftHeadingIcoConf, left: getPlaylistIcoConf(type)});

    return playlistFolderListFactory.buildListItem(new PlaylistFolderListItemModel(folder, playlists), playlistsConfig);
};

export const buildPlaylistMediaTypeFilter = (mediaType) => {
    return (playlist: Playlist) => {
        return playlist.mediaType === mediaType;
    }
};

export const processPlaylistCollections = (playlistFolders: Array<AMPFolder>, playlists: Array<Playlist>) => {

    const playlistsFolderMap = processFolderMap(playlistFolders, playlists);

    const listItems = [];

    playlistFolders.forEach((folder) => {
        listItems.push(buildListItemForFolder(folder, playlistsFolderMap[folder.id]));
    });

    if (playlistsFolderMap[0]) {
        playlistsFolderMap[0].forEach(playlist => {
            listItems.push(buildListItemForPlaylist(playlist));
        });
    }

    return listItems;
};

// 1 vid, 2 aud
export class PlaylistMasterViewTab implements ITabableMasterView<Playlist> {

    private _tabIdentifier: number;

    private _playlists: Array<Playlist>;

    private _playlistCollections: Array<AMPFolder>;

    private _masterViewList: Array<ListItem>;

    constructor(tabIdentifier: number,
                playlistCollections: Array<AMPFolder>,
                playlists: Array<Playlist>) {
        this._tabIdentifier = tabIdentifier;
        this._playlists = playlists;
        this._playlistCollections = playlistCollections;
        this._masterViewList = [];
        this.processPlaylistCollections(playlistCollections, playlists);
    }


    getTabIdentifier(): number {
        return this._tabIdentifier;
    }

    getMasterViewList(): Array<ListItem> {
        return this._masterViewList;
    }

    getMasterViewModel(): Array<Playlist> {
        return this._playlists;
    }

    pushToMasterViewList(listItem): void {
        this._masterViewList.push(listItem);
    }

    processPlaylistCollections(playlistFolders, playlists) {
        this._masterViewList = processPlaylistCollections(playlistFolders, playlists);
    };


}

