import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import DelPlaylistConfirmDelegate from './del-playlist-confirm.delegate';
import {Playlist} from '../../_models/playlist';

@Component({
    selector: 'amp-del-playlist-confirm',
    templateUrl: './del-playlist-confirm.component.html',
    styleUrls: ['./del-playlist-confirm.component.scss']
})
export class DelPlaylistConfirmComponent {

    @Input() eventDelegate: DelPlaylistConfirmDelegate;
    @Input() playlist: Playlist;

    constructor(
    ) { }

    onCancel() {
        this.eventDelegate.onDelPlaylistCancel({});
    }

    onConfirm() {
        this.eventDelegate.onDelPlaylistConfirm(this.playlist);
    }

}
