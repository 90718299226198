import { Injectable } from '@angular/core';
import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";

@Injectable()
export class ValidationService {

  constructor() { }

  public equalityValidator(control1: string, control2: string): ValidatorFn {
    return (group: FormGroup): {[key: string]: any} => {
      let value1 = group.controls[control1];
      let value2 = group.controls[control2];
      if (value1.value !== value2.value) {
        return {'notEqual': true };
      }
      return null;
    }
  }

  public emailValidator(): ValidatorFn {
    var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    var regExp: RegExp = EMAIL_REGEXP;
    return (control: AbstractControl): {[key: string]: any} => {
      if (control.value.length <= 5 ||!regExp.test(control.value)) {
        return {"invalidEmail": true};
      }
      return null;
    }
  }

  public accountIdValidator(): ValidatorFn {
    var ACCOUNT_ID_REGEXP = /^AM\d*$/;
    var regExp: RegExp = ACCOUNT_ID_REGEXP;
    return (control: AbstractControl): {[key: string]: any} => {
      if (!regExp.test(control.value)) {
        return {"invalidIdentifier": true};
      }
      return null;
    }
  }

}
