import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AudioLibraryService } from '../../_services/audio-library.service';
import { AlertService } from '../../_alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Playlist } from '../../_models/playlist';
import { ModalComponent } from '../../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectListMediator } from 'app/common-components/master-view-list/interfaces/mulit-select-list';
import {AMPFolder} from '../../_models/folder';
import {ListItem} from '../../common-components/master-view-list/models/list-item';
import {processPlaylistCollections} from '../../playlist/iplaylist-tab-view';


@Component({
  selector: 'library-action-menu',
  templateUrl: './library-action-menu.component.html',
  styleUrls: ['./library-action-menu.component.scss']
})

export class LibraryActionMenuComponent implements OnInit {

  @Input() contentItems: Array<any>;
  @Input() mediaType: number;
  @Input() multiSelectListMediator: MultiSelectListMediator;
  @Input() modalDelegate: LibraryModalDelegate;
  @Input() showCreateWithAll: Boolean

  @Input() playlistListItems: Array<ListItem> = [];

  constructor(private audioLibraryService: AudioLibraryService,
    private alert: AlertService,
    private translate: TranslateService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  addToPlaylist(playlist: Playlist) {


    if (!this.multiSelectListMediator || this.multiSelectListMediator.getSelected().length == 0) {
      for (const ci of this.contentItems) {
        playlist.movieIdentifiersAsStrings.push(ci.sIdentifier);
      }
    } else {
      var selectedItems = [];
      this.multiSelectListMediator.getSelected().forEach(index => { selectedItems.push(this.contentItems[index]) })
      for (const ci of selectedItems) {
        playlist.movieIdentifiersAsStrings.push(ci.sIdentifier);
      }
    }

    this.audioLibraryService.addOrUpdateContentToPlaylist(playlist).subscribe(res => {
      this.multiSelectListMediator.clearSelection();
    }, error => {
      console.log(error);
      if (error.status == 0) {
        this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
        return;
      }
    });
  }

  handlePlaylistDropdownClick(listItem) {
      this.addToPlaylist(listItem.getInnerModel());
  }

  open(contentName) {
    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.name = contentName;
    modalRef.componentInstance.playlistType = 1;
    modalRef.componentInstance.mediaType = this.mediaType;
    modalRef.componentInstance.delegate = this.modalDelegate;
    if (!this.multiSelectListMediator || this.multiSelectListMediator.getSelected().length == 0) {
      modalRef.componentInstance.contentItems = this.contentItems;
    } else {
      var selectedItems = [];
      this.multiSelectListMediator.getSelected().forEach(index => { selectedItems.push(this.contentItems[index]) });
      modalRef.componentInstance.contentItems = selectedItems;
    }
  }
}

export interface LibraryModalDelegate {
  success();
}
