import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AMPFolder} from '../../_models/folder';
import {Programme} from '../../_models/programme';
import {ProgrammeActionMenuDelegate} from './programme-action-menu.delegate';

@Component({
    selector: 'amp-programme-action-menu',
    templateUrl: './programme-action-menu.component.html',
    styleUrls: ['./programme-action-menu.component.scss']
})
export class ProgrammeActionMenuComponent {

    @Input() programme: Programme;
    @Input() programmeFolders: Array<AMPFolder>;
    @Input() delegate: ProgrammeActionMenuDelegate;

    form: FormGroup;

    constructor(
    ) { }


    moveToFolder(folder: AMPFolder) {
        this.programme.folderId = folder.id;

        this.delegate.onMoveProgrammeToFolder(this.programme);
    }

    copyProgramme() {
        this.delegate.onCopyProgramme(this.programme);
    }
}
