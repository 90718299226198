import {OrderLicence} from "./order-licence";

export class Order {

  constructor(public idString: string,
              public creatorId: number,
              public resellerId: number,
              public dateCreated: Date,
              public resellerName: string,
              public username: string,
              public description: string,
              public licenses: OrderLicence[],
              public productName: string,
              public renditionId: number
              )
  {

  }

}
