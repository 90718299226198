import {Component} from '@angular/core';
import {AlertService} from '../_alert/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'amp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    constructor(private alert: AlertService,
                private translate: TranslateService) {}
    getYear() {
      return new Date().getFullYear();
    }
}
