import {ListItem} from './list-item';

export enum ListItemIconType {
    IMAGE_ASSET,
    ICON
}

export enum ListItemIconConfigStates {
    NORMAL,
    SELECTED
}

export class ListItemIconConfig {

    private readonly iconStateMap;
    private readonly iconType: ListItemIconType;
    private readonly className: string;

    constructor(iconType: ListItemIconType, className: string) {
        this.iconType = iconType;
        this.className = className;
        this.iconStateMap = {
            [ListItemIconConfigStates.NORMAL] : '',
            [ListItemIconConfigStates.SELECTED]: ''
        };
    }

    pushIconState(state, asset) {
        this.iconStateMap[state] = asset;
    }

    getAssetForIconState(state) {
        return this.iconStateMap[state];
    }

    isImage() {
        return this.iconType === ListItemIconType.IMAGE_ASSET;
    }

    isIcon() {
        return this.iconType === ListItemIconType.ICON;
    }

    getClassName(state) {
        return `${this.className}--${state}`
    }
}

export class ListItemConfig {

    private readonly _leftHeadingIconConfig: ListItemIconConfig;
    private readonly _leftIconConfig: ListItemIconConfig;

    constructor(iconConfig) {
        this._leftHeadingIconConfig = iconConfig.leftHeading;
        this._leftIconConfig = iconConfig.left;
    }

    get leftHeadingIconConfig(): ListItemIconConfig {
        return this._leftHeadingIconConfig;
    }

    get leftIconConfig(): ListItemIconConfig {
        return this._leftIconConfig;
    }

    getIcons() {
        return {
            leftHeading: this._leftHeadingIconConfig,
            left: this._leftIconConfig
        }
    }
}
