import {OrderLicence} from "../../../_models/order-licence";
import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {AdminService, LicenceAction} from "../../../_services/admin.service";
import {OrderActionDelegate} from "../order-action.delegate";
import {Venue} from "../../../_models/venue";
import {Installation} from "../../../_models/installation";
import {AlertService} from "../../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {LicenceStatus} from "../../../Util";

@Component({
  selector: 'licence-renew',
  templateUrl: './licence-renew.component.html',
  styleUrls: ['../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css', './licence-renew.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LicenceRenewComponent implements OnInit, OnChanges {

  public venue : Venue;
  public installation: Installation;

  @Input()
  public expire : boolean;


  @Input()
  public venues : Venue[];

  @Input()
  delegate: OrderActionDelegate;

  @Input()
  public licence: OrderLicence;


  constructor(private adminService: AdminService,
              private alert: AlertService,
              private translate: TranslateService) {}

  ngOnInit() {
  }

  ngOnChanges()
  {
    if (this.venues != null)
    {
      for (let venue of this.venues) {
        for (let installation of venue.installations) {
          if (installation.id == this.licence.installationId) {
            this.venue = venue;
            this.installation = installation;
            return;
          }
        }
      }
    }
  }


  public cancel()
  {
    this.delegate.finished(false);
  }

  public licenceAction()
  {
    let action : LicenceAction;
    if (this.expire)
      action = LicenceAction.Expire;
    else if (this.licence.status == LicenceStatus.Expired)
      action = LicenceAction.Renew;
    else
      action = LicenceAction.Extend

    this.adminService.executeLicenceAction(this.licence.code, action)
      .finally(() => {
        this.delegate.finished(true);
       })
      .subscribe(response => {
          let key: string = (this.expire ? "licence-was-assigned" : "licence-was-renewed");
          this.alert.success(this.translate.instant(key));
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  public actionText() : string
  {
    if (this.expire)
      return "Expire Licence";
    if (this.licence.status == LicenceStatus.Expired)
      return "Renew Licence";
    return "Extend Licence";
  }

}

