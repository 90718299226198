import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from "../../_services/validation.service";
import { AMPFolder } from '../../_models/folder';
import { AddPlaylistFormDelegate } from './add-playlist-form.delegate';
import { Playlist } from '../../_models/playlist';
import { AppComponent } from 'app/app.component';

@Component({
    selector: 'amp-add-playlist-form',
    templateUrl: './add-playlist-form.component.html',
    styleUrls: ['./add-playlist-form.component.scss']
})
export class AddPlaylistFormComponent implements OnInit, OnChanges {

    @Input() eventDelegate: AddPlaylistFormDelegate;
    @Input() mediaType: number;
    playlistFolders: Array<AMPFolder> = [];

    form: FormGroup;

    constructor(
        private validation: ValidationService,
        private formBuilder: FormBuilder,
        public app: AppComponent
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            mediaType: [this.app.mediaTypes[0]],
            playlistType: [this.app.playlistTypes[0]],
            shouldAddToFolder: [false],
            selectedFolder: [{}],
        });
        this.setFoldersForType(this.app.mediaTypes[0].id);
        this.onFormChanges();
    }

    setFoldersForType(type) {
        this.playlistFolders = this.eventDelegate.getFoldersForType(type);
        if (this.playlistFolders.length > 0) {
            this.form.controls.selectedFolder.setValue(this.playlistFolders[0]);
        } else {
            this.form.controls.shouldAddToFolder.setValue(false);
            this.form.controls.selectedFolder.setValue({});
        }
        console.log(type, this.playlistFolders);
    }

    onFormChanges(): void {
        this.form.get('mediaType').valueChanges.subscribe(val => {
            console.log('Detected change for media type: ', val);
            if (!!val && val.hasOwnProperty('id')) {
                this.setFoldersForType(val.id);
            }
        });
    }

    close() {
        // Update state to default for form grp
        this.reset();
        this.eventDelegate.onAddPlaylistCancel({});
    }

    onSubmit(form) {
        const folderId = (form.shouldAddToFolder) ? form.selectedFolder.id : 0;

        const playlist = new Playlist(
            0,
            form.name,
            form.mediaType.id,
            form.playlistType.id, // Standard or Filter
            folderId,
            [],
            [],
            0,
            false,
            0,
            1
        );
        this.reset();
        this.eventDelegate.onAddPlaylistSubmit(playlist);
    }

    reset() {
        this.form.reset();
        this.form.controls.name.setValue('');
        this.form.controls.mediaType.setValue(this.app.mediaTypes[0]);
        this.form.controls.playlistType.setValue(this.app.playlistTypes[0]);
        this.form.controls.shouldAddToFolder.setValue(false);
        this.form.controls.selectedFolder.setValue(this.playlistFolders[0]);
    }

    ngOnChanges(changes: SimpleChanges): void {

            if (
                changes.mediaType &&
                !changes.mediaType.isFirstChange() &&
                changes.mediaType.currentValue !== -1) {
                this.form.controls.mediaType.setValue(this.app.mediaTypes.find(type => type.id === changes.mediaType.currentValue));
            }
    }

    isFoldersAvailable() {
        return (this.playlistFolders && this.playlistFolders.length > 0);
    }
}
