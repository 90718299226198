
export class SequenceAction {
  id: number;
  name: string;
  type: number; // playlist 1, series 2, album 3, attribute 4, channel 5
  startSeconds: number;
  duration: number;
  identifier: string; // string version of playlist id
  sequenceId: number;
  shuffle: boolean;
  networkMode?: boolean;
  mute?: boolean;
  // loop: boolean;
  constructor(id?: number, name?: string, type?: number, startSeconds?: number,
              duration?: number, identifier?: string, sequenceId?: number, shuffle?: boolean, networkMode?: boolean)
  {
    this.id = id;
    this.name = name;
    this.type = type;
    this.startSeconds = startSeconds;
    this.duration = duration;
    this.identifier = identifier;
    this.sequenceId = sequenceId;
    this.shuffle = shuffle;
    if (networkMode !== undefined && networkMode !== null) {
        this.networkMode = networkMode;
    }
    // this.loop = loop;
  }
}
