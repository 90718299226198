import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ListItem} from '../master-view-list/models/list-item';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-dropdown-list',
    templateUrl: './dropdown-list.component.html',
    styleUrls: ['./dropdown-list.component.scss']
})
export class DropdownListComponent implements OnInit, OnDestroy {
    @Input() dropdownButtonContent: string;
    @Input() listItems: Array<ListItem>;
    @Input() searchable: boolean;
    @Input() classNames: string;
    @Input() btnClassNames: string;
    @Input() buttonType: string;

    @Input() dropdownEvents: Observable<any>;
    @Output() listItemClick: EventEmitter<ListItem> = new EventEmitter<ListItem>();

    @ViewChild('dropdownToggle', {static: false}) dropdownView: ElementRef;

    private eventsSub: Subscription;
    public searchFilter = '';

    ngOnInit() {
        if (this.dropdownEvents) {
            this.eventsSub = this.dropdownEvents.subscribe((eventType) => {
                if (eventType === 'close') {
                    this.closeDropdown()
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.dropdownEvents && this.eventsSub) {
            this.eventsSub.unsubscribe();
        }
    }

    getFilterFunc() {
        return (listItem: ListItem) => {
            const RULE_1 = !listItem.hasNestedItems() && listItem.getDisplayText().includes(this.searchFilter);
            const RULE_2  = listItem.hasNestedItems() && listItem.getNestedItems().filter(this.getFilterFunc()).length > 0;
            if (RULE_2) {
                listItem.setSelected(true);
            } else {
                listItem.setSelected(false);
            }
            const RULES = RULE_1 || RULE_2;
            return RULES;
        };
    }

    visibleListItems() {
        let listItems;

        if (this.searchable && this.searchFilter !== '') {
            listItems = this.listItems.filter(this.getFilterFunc());
        } else {
            listItems = this.listItems;
        }

        return listItems;
    }

    closeDropdown(callback = () => {}) {
        if (window.hasOwnProperty('$')) {
            window['$'](this.dropdownView.nativeElement).dropdown('toggle');
            this.listItems.forEach(dropdownListItem => {
                dropdownListItem.setSelected(false);
            });
            callback();
        }
    }

    handleListItemSelected(listItem) {
        this.closeDropdown(() => {
            this.listItemClick.emit(listItem);
        })
    }
}
