import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterViewListItemController } from '../common-components/master-view-list/interfaces/master-view-list-item';
import { MasterViewListItemEventDelegate } from '../common-components/master-view-list/interfaces/master-view-list-item-event-delegate';
import { ListItem } from '../common-components/master-view-list/models/list-item';
import { DashboardListItemModel, InstallationsListItemFactory, ProductsListItemFactory } from './util/dashboard-listitem-factory';
import ListItemIconConfigFactory from '../common-components/master-view-list/util/list-item-config-factory';
import { ListItemConfig } from '../common-components/master-view-list/models/list-item-config';
import {
    MasterViewListMediator,
    NestedMasterViewListMediator
} from '../common-components/master-view-list/interfaces/master-view-list-mediator';

@Component({
    selector: 'amp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, MasterViewListItemController, MasterViewListItemEventDelegate {

    // List Control & Management
    masterViewListMediator: MasterViewListMediator;
    masterViewList: Array<ListItem>;

    private routeHeaderMap = {
        '/venues': { 'headName': 'Overview', 'button': 'ADD VENUE' },
        '/licences': { 'headName': 'Licenses', 'button': '' }
    };
    activeTab: string;
    headerName = '';
    constructor(private router: Router) {
        this.masterViewList = [];
        this.masterViewListMediator = new NestedMasterViewListMediator();
    }

    ngOnInit(): void {
        this.buildMasterViewList();
        this.activeTab = this.router.url;
        console.log(this.router);
        this.mapHeaderName(this.activeTab);
    }

    mapHeaderName(activeRoute: string) {
        if (!activeRoute && this.routeHeaderMap.hasOwnProperty(activeRoute))
            this.headerName = this.routeHeaderMap[activeRoute];
    }
    openTab(tabName) {
        let i, tabcontent;
        tabcontent = document.getElementsByClassName("dashboard-detail-view-content");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        document.getElementById(tabName).style.display = "block";
    }

    buildMasterViewList() {
        const installationsListItemFactory = new InstallationsListItemFactory();
        const productsListItemFactory = new ProductsListItemFactory();

        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const installationsIconConfig = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_house_white.svg',
            '../../assets/img/icons_ic_house_colorful.svg',
            {},
            'house-ico'
        );
        const productsIconConfig = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_box.svg',
            '../../assets/img/icons_ic_box_coloured.svg',
            {},
            'box-ico'
        );

        // tslint:disable-next-line:max-line-length
        const overviewListItem = installationsListItemFactory.buildListItem(new DashboardListItemModel('overview'), new ListItemConfig({
            left: installationsIconConfig, leftHeader: installationsIconConfig
        }));
        this.pushToMasterViewList(overviewListItem);
        this.masterViewListMediator.setSelectedListItem(overviewListItem, 1);

        this.pushToMasterViewList(productsListItemFactory.buildListItem(new DashboardListItemModel('licenses'), new ListItemConfig({
            left: productsIconConfig, leftHeader: productsIconConfig
        })))
    }

    getMasterViewList(): Array<ListItem> {
        return this.masterViewList;
    }

    pushToMasterViewList(listItem): void {
        this.masterViewList.push(listItem);
    }

    listItemSelected(eventPayload: any, listItem: any) {
        this.masterViewListMediator.setSelectedListItem(listItem, eventPayload.nestedDepth);
        this.getDetailViewForListItem(listItem);
    }

    clearMasterViewList(): Array<ListItem> {
        const cachedList = this.masterViewList;
        this.masterViewList = [];
        return cachedList;
    }

    getDetailViewForListItem(listItem): void {
        this.openTab(listItem.getInnerModel().elementId);
    }

}

