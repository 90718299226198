import {ListItemFactory} from '../../common-components/master-view-list/interfaces/list-item.factory';
import {Playlist} from '../../_models/playlist';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';
import {List, ListItem} from '../../common-components/master-view-list/models/list-item';
import {AMPFolder} from '../../_models/folder';
import FolderListItemModel from '../../common-components/master-view-list/interfaces/folder-list-item-model';

const buildPlaylistsMap = (config: ListItemConfig) => {
    return (playlist: Playlist) => {
        return new ListItem(config, () => {return playlist.name}, playlist);
    }
};

export class PlaylistFolderListItemModel implements FolderListItemModel {
    private readonly _folder: AMPFolder;
    private readonly _playlists: Array<Playlist>;
    isFolderListItemModel = true;

    constructor(folder, playlists) {
        this._folder = folder;
        this._playlists = playlists;
    }

    get folder(): AMPFolder {
        return this._folder;
    }

    get playlists(): Array<Playlist> {
        return this._playlists;
    }
}

export class PlaylistListItemFactory implements  ListItemFactory<Playlist> {

    buildListItem(model: Playlist, config: ListItemConfig): ListItem {
        return buildPlaylistsMap(config)(model);
    }
}

export class PlaylistFolderListItemFactory implements ListItemFactory<PlaylistFolderListItemModel> {

    private readonly listHeading;

    constructor(listHeading: () => string) {
        this.listHeading = listHeading;
    }

    buildListItem(model: PlaylistFolderListItemModel, config: ListItemConfig): ListItem {
        return new List(config, this.listHeading, model, model.playlists.map(buildPlaylistsMap(config)))
    }

}

