

export interface MultiSelectList {
    selectItem(index);
    isSelected(index): boolean;
    getSelectedItemStyle(index): string;
    clearSelection();
    getSelected(): number[];
    hasSelected(): boolean;
}

export class MultiSelectListMediator implements MultiSelectList {

    selectedIndices: number[] = [];

    selectItem(index) {
        if (this.selectedIndices.includes(index)) {
            this.selectedIndices.splice(this.selectedIndices.indexOf(index), 1);
        } else {
            this.selectedIndices.push(index);
        }
    }

    hasSelected(): boolean {
        return this.selectedIndices.length > 0;
    }

    isSelected(index): boolean {
        return this.selectedIndices.includes(index)
    }

    getSelectedItemStyle(index): string {
        return this.isSelected(index) ? 'card-selected' : '';
    }

    clearSelection() {
        this.selectedIndices = [];
    }

    getSelected() {
        return this.selectedIndices;
    }


}
