import {Component, OnInit} from '@angular/core';
import {Licence} from '../../_models/licence';
import {AccountService} from '../../_services/account.service';
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import Util, {LicenceStatus} from "../../Util";

@Component({
  selector: 'amp-dashboard-license',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicenseComponent implements OnInit {
  productGroups: Array<Object>;
  productNames: Array<string>;
  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {}
  ngOnInit(): void {
    this.accountService.fetchLicences()
      .subscribe((licences: Licence[]) => {
          this.arrangeByProductType(licences);
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }

  arrangeByProductType(licences) {
    this.productGroups = licences.reduce((r, a) => {
      r[a.productName] = [...r[a.productName] || [], a];
      return r;
    }, []);
    this.productNames = Object.keys(this.productGroups);
  }

  arrangeByVenue(installations) {
    return Array.from(new Set(installations.map(({ venueName }) => venueName)));
  }

  getLicenseStatus(licenceStatus: Number) {
    let u = new Util();
    let status = u.licenceStatusDescriptor(licenceStatus);
    if (status == 'Active, re-activation enabled' || status == 'Expired, re-activation enabled') {
      status = 'Re-activation enabled';
    }
    return status;
  }

  setLicenseStatusButtonColor(status: string) {
    let licenceBtnClass = {
        'inActive': false,
        'awaiting-activation': false,
        'is-active': false,
        'has-expired': false,
        'active-re-activation-enabled': false,
        'expired-re-activation-enabled': false,
        'undefined-status': false
    };

    switch (status) {
      case 'InActive':  licenceBtnClass['inActive'] = true;
                        break;
      case 'Awaiting Activation':  licenceBtnClass['awaiting-activation'] = true;
                        break;
      case 'Active':  licenceBtnClass['is-active'] = true;
                        break;
      case 'Expired':  licenceBtnClass['has-expired'] = true;
                        break;
      case 'Re-activation enabled':  licenceBtnClass['active-re-activation-enabled'] = true;
                        break;
      case 'Undefined': licenceBtnClass['undefined-status'] = true;
                        break;
    }

    return licenceBtnClass

  }
}

