import {ApplicationRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Account} from "../../_models/account";
import {AdminService} from "../../_services/admin.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'admin-control',
  templateUrl: './admin-control.component.html',
  styleUrls: ['../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
    '../admin-orders/licence-assign/licence-assign.component.scss',
    './admin-control.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class AdminControlComponent implements OnInit {
    isLoading = false;


  constructor(private adminService: AdminService,
              private alert: AlertService,
              private translate: TranslateService) {

  }

  ngOnInit() {
    this.isLoading = true
  }


  public libraryUpdated()
  {
    this.adminService.libraryUpdated()
      .subscribe((c) => {
         },
        error => {
          if (error.status === 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          const response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });

  }
}

