import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterViewListItemController } from 'app/common-components/master-view-list/interfaces/master-view-list-item';
import { MasterViewListItemEventDelegate } from 'app/common-components/master-view-list/interfaces/master-view-list-item-event-delegate';
import { ListItem } from 'app/common-components/master-view-list/models/list-item';
import ListItemIconConfigFactory from 'app/common-components/master-view-list/util/list-item-config-factory';
import {
    ScheduleFolderListItemFactory,
    ScheduleFolderListItemModel,
    SchedulesListItemFactory,
} from './util/schedule-listitem-factory';
import { ListItemConfig } from 'app/common-components/master-view-list/models/list-item-config';
import { Schedule } from 'app/_models/schedule';
import { AudioLibraryService } from 'app/_services/audio-library.service';
import { AccountService } from 'app/_services/account.service';

import { MasterViewToolbarController } from '../common-components/master-view-toolbar/interfaces/master-view-toolbar-controller';
import { MasterViewToolbarAction } from '../common-components/master-view-toolbar/master-view-toolbar-action';
import { Programme } from 'app/_models/programme';
import {
    MasterViewListMediator,
    NestedMasterViewListMediator
} from '../common-components/master-view-list/interfaces/master-view-list-mediator';
import { EditModeController, GenericEditModeController } from '../common-components/edit-mode/edit-mode.controller';
import { ActionPopoverMediator, ActionPopoverController } from 'app/common-components/popovers/action-popover.parent';
import GenericActionPopoverMediator from 'app/common-components/popovers/generic-popover.mediator';
import GenericActionPopoverController from 'app/common-components/popovers/generic-popover.controller';
import { AddScheduleFormDelegate } from './add-schedule-form/add-schedule-form.delegate';
import { AMPFolder } from '../_models/folder';
import { AddScheduleFolderFormDelegate } from './add-schedule-folder-form/add-schedule-folder-form.delegate';
import DelScheduleFolderConfirmDelegate from './del-schedule-folder-confirm/del-schedule-folder-confirm.delegate';
import { isFolderListItemModel } from '../common-components/master-view-list/interfaces/folder-list-item-model';
import { ScheduleActionMenuDelegate } from './schedule-action-menu/schedule-action-menu.delegate';
import {EditFolderFormDelegate} from '../audio-collections/edit-folder-form/edit-folder-form.delegate';
import DelScheduleConfirmDelegate from './del-schedule-confirm/del-schedule-form.delegate';
import {AlertService} from '../_alert/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {FLOATING_ITEM_ID, processFolderMap} from '../common-components/util/common-collections-process';

@Component({
    selector: 'amp-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit,
    MasterViewToolbarController, MasterViewListItemController, MasterViewListItemEventDelegate,
    AddScheduleFormDelegate, AddScheduleFolderFormDelegate,
    DelScheduleFolderConfirmDelegate, DelScheduleConfirmDelegate,
    ScheduleActionMenuDelegate, EditFolderFormDelegate {

    masterViewListMediator: MasterViewListMediator;
    masterViewList: Array<ListItem> = [];
    lastSelectedListItem: ListItem;
    lastSelectedSchedule: Schedule;

    public scheduleFolders: Array<AMPFolder> = [];
    public schedules: Array<Schedule> = [];
    public programs: Array<Programme> = [];
    public programFolders: Array<AMPFolder> = [];

    // Edit Mode Management
    editModeController: EditModeController<Schedule>;

    // Popover Control
    popoverMediator: ActionPopoverMediator;
    addSchedulePopoverCtrl: ActionPopoverController;
    addFolderPopoverCtrl: ActionPopoverController;
    delPopoverCtrl: ActionPopoverController;
    delFolderPopoverCtrl: ActionPopoverController;
    editFolderPopoverCtrl: ActionPopoverController;


    constructor(
        private router: Router,
        private audioLibraryService: AudioLibraryService,
        private accountService: AccountService,
        private alert: AlertService,
        private translate: TranslateService
    ) {
        this.masterViewList = [];
        this.masterViewListMediator = new NestedMasterViewListMediator();
        this.editModeController = new GenericEditModeController<Schedule>(
            (obj) => this.saveEditMode(obj),
            (obj) => this.cancelEditMode(obj),
            (obj) => this.validateSchedule(obj)
        );

        this.popoverMediator = new GenericActionPopoverMediator();
        this.addSchedulePopoverCtrl = new GenericActionPopoverController();
        this.addFolderPopoverCtrl = new GenericActionPopoverController();
        this.delPopoverCtrl = new GenericActionPopoverController();
        this.delFolderPopoverCtrl = new GenericActionPopoverController();
        this.editFolderPopoverCtrl = new GenericActionPopoverController();

    }

    ngOnInit(): void {

        this.audioLibraryService.fetchFoldersByTypeIdentifier(2).subscribe(res => {
            this.programFolders = res;
            this.audioLibraryService.fetchPrograms().subscribe(res => {
                this.programs = res;
            });
        });

        this.loadScheduleCollections();
    }

    loadSchedules() {
        return this.audioLibraryService.fetchSchedules().toPromise();
    }

    getScheduleFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(3).toPromise();
    }

    loadScheduleCollections() {
        const audioLibPromises = [this.getScheduleFolders(), this.loadSchedules()];

        Promise.all(audioLibPromises).then(res => {
            const performDefaultSelection = (this.schedules.length === 0);

            this.scheduleFolders = res[0] || [];
            this.schedules = res[1];
            this.processScheduleCollections(this.scheduleFolders, this.schedules);
            if (performDefaultSelection) {
                const defaultListItem = this.masterViewList.find((listItem: ListItem) => {
                    return !isFolderListItemModel(listItem.getInnerModel());
                });

                this.masterViewListMediator.setSelectedListItem(defaultListItem, 1);
                this.getDetailViewForListItem(defaultListItem);
            }
        })
    }

    processScheduleCollections(scheduleFolders, schedules) {
        const scheduleFolderMap = processFolderMap(scheduleFolders, schedules);

        this.clearMasterViewList();
        this.masterViewListMediator.clear();

        scheduleFolders.forEach(folder => {
            this.pushToMasterViewList(this.buildListItemForFolder(folder, scheduleFolderMap[folder.id]));
        });

        scheduleFolderMap[FLOATING_ITEM_ID].forEach(schedule => {
            this.pushToMasterViewList(this.buildListItemForSchedule(schedule));
        })
    }

    getScheduleListItemIcoConf() {
        const schedulesListIconConfigFactory = new ListItemIconConfigFactory();
        return schedulesListIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_date.png',
            '../../assets/img/icons_ic_date.png',
            {},
            'date-ico'
        );
    }

    buildListItemForFolder(folder, schedules) {
        const scheduleFolderListFactory = new ScheduleFolderListItemFactory(() => folder.folderName);
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_folder.svg',
            '../../assets/img/icons_ic_folder.svg',
            {},
            'folder-ico'
        );

        const scheduleLIConf = new ListItemConfig({ leftHeading: leftHeadingIcoConf, left: this.getScheduleListItemIcoConf() });
        return scheduleFolderListFactory.buildListItem(new ScheduleFolderListItemModel(folder, schedules), scheduleLIConf)
    }

    buildListItemForSchedule(schedule) {
        const scheduleListItemFactory = new SchedulesListItemFactory();
        const icoConf = this.getScheduleListItemIcoConf();

        return scheduleListItemFactory.buildListItem(schedule, new ListItemConfig({ leftHeading: icoConf, left: icoConf }))
    }

    getMasterViewList(): ListItem[] {
        return this.masterViewList;
    }
    pushToMasterViewList(listItem: any): void {
        this.masterViewList.push(listItem);
    }

    listItemSelected(eventPayload: any, listItem: any) {
        this.masterViewListMediator.setSelectedListItem(listItem, eventPayload.nestedDepth);
        this.popoverMediator.closeActionPopovers(this.getToolbarPopovers());
        this.getDetailViewForListItem(listItem);
    }


    getToolbarPopovers() {
        return [
            this.addSchedulePopoverCtrl,
            this.addFolderPopoverCtrl,
            this.delFolderPopoverCtrl,
            this.editFolderPopoverCtrl
        ];
    }

    handleDeleteAction(toolbarActionPopovers) {
        if (this.masterViewListMediator.isLastItemSelected()) {
            const listItem = this.masterViewListMediator.getLastSelectedItem();
            if (isFolderListItemModel(listItem.getInnerModel())) {
                this.popoverMediator.openPopover(toolbarActionPopovers, this.delFolderPopoverCtrl);
            } else {
                this.popoverMediator.openPopover(toolbarActionPopovers, this.delPopoverCtrl);
            }
        }
    }

    getClassNameForNotEmptyFolderListItem() {
        // const RULE = (
        //     this.masterViewListMediator.isLastItemSelected() &&
        //     this.masterViewListMediator.getLastSelectedItem().hasNestedItems() &&
        //     this.masterViewListMediator.getLastSelectedItem().getNestedItems().length > 0
        // );
        //
        // return (RULE) ? 'master-view-toolbar__toolbar-action__del--disabled' : '';
        return '';
    }

    getToolbarActions(): MasterViewToolbarAction[] {
        const toolbarActionPopovers = this.getToolbarPopovers();

        const isEditFolderEnabled = (this.masterViewListMediator.isLastItemSelected()
            && isFolderListItemModel(this.masterViewListMediator.getLastSelectedItem().getInnerModel()));
        const editFolderStateStyle = (isEditFolderEnabled) ? 'master-view-toolbar__toolbar-action__edit--enabled' : 'master-view-toolbar__toolbar-action__edit--disabled';

        return [
            new MasterViewToolbarAction('../../assets/img/icons_ic_add_plus.svg',
                'master-view-toolbar__toolbar-action__add',
                () =>
                    this.popoverMediator.openPopover(toolbarActionPopovers, this.addSchedulePopoverCtrl))
            ,
            new MasterViewToolbarAction('../../assets/img/icons_ic_del_minus.svg',
                `master-view-toolbar__toolbar-action__del master-view-toolbar__toolbar-action__del ${this.getClassNameForNotEmptyFolderListItem()}`,
                () => this.handleDeleteAction(toolbarActionPopovers)),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_folder.svg',
                'master-view-toolbar__toolbar-action__add-folder',
                () => this.popoverMediator.openPopover(toolbarActionPopovers, this.addFolderPopoverCtrl)
            ),
            new MasterViewToolbarAction(
                '../../assets/img/icons_ic_pencil.svg',
                `master-view-toolbar__toolbar-action__edit ${editFolderStateStyle}`,
                () => this.popoverMediator.openPopover(toolbarActionPopovers, this.editFolderPopoverCtrl)
            )
        ];
    }

    clearMasterViewList(): Array<ListItem> {
        const cachedList = this.masterViewList;
        this.masterViewList = [];
        return cachedList;
    }

    getLastListItemModel() {
        let model = {};
        if (this.masterViewListMediator.isLastItemSelected()) {
            model = this.masterViewListMediator.getLastSelectedItem().getInnerModel();
        }
        return model;
    }

    getDetailViewForListItem(listItem): void {
        if (!listItem.isSelected() && !isFolderListItemModel(listItem.getInnerModel())) {
            this.lastSelectedSchedule = new Schedule(0, '', false, 0, 0, []);
        } else if (!isFolderListItemModel(listItem.getInnerModel())) {
            this.lastSelectedListItem = listItem;
            this.lastSelectedSchedule = listItem.getInnerModel();
        }
    }

    getToolbarStateStyle(): string {
        return (!this.masterViewListMediator.isLastItemSelected()) ? 'master-view-toolbar--no-item-selected' : '';
    }

    saveEditMode(editedObject: Schedule) {
        editedObject.name = this.lastSelectedSchedule.name;
        this.audioLibraryService.createOrUpdateSchedule(editedObject).subscribe(res => {
            this.lastSelectedSchedule.name = res.name;
            this.lastSelectedSchedule.programmes = [...res.programmes];
        }, (err) => {

        });
    }

    cancelEditMode(cachedObject: Schedule) {
        this.lastSelectedSchedule.name = cachedObject.name;
        this.lastSelectedSchedule.programmes = cachedObject.programmes;
    }

    validateSchedule(cachedObject: Schedule) {
         if (this.lastSelectedSchedule.programmes.length < 7) {
            alert(`7 Programmes required.`);
            return false;
         }
         for (var i = 0; i < this.lastSelectedSchedule.programmes.length; i++) {
             const sch = this.lastSelectedSchedule.programmes[i];
             if (sch === undefined) {
                 alert(`7 Programmes required.`);
                 return false;
             }
        }
        return true;
    }

    updateSchedule(schedule: Schedule) {
        this.lastSelectedSchedule.programmes = schedule.programmes;
    }

    getActionMenuStyleState(): string {
        let className = '';

        if (this.masterViewListMediator.isLastItemSelected()) {
            if (isFolderListItemModel((this.masterViewListMediator.getLastSelectedItem().getInnerModel()))) {
                className = `${className} action-menu-disabled`
            }
        } else {
            className = `${className} action-menu-disabled`
        }

        return className;
    }

    onAddScheduleSubmit(schedule: Schedule) {
        this.audioLibraryService.createOrUpdateSchedule(schedule).subscribe(res => {
            this.loadScheduleCollections();
            this.addSchedulePopoverCtrl.closeActionPopover();
        });
    }
    onAddScheduleCancel(ev: any) {
        this.addSchedulePopoverCtrl.closeActionPopover();
    }

    onAddScheduleFolderCancel(ev: any) {
        this.addFolderPopoverCtrl.closeActionPopover();
    }

    onAddScheduleFolderSubmit(folder: AMPFolder) {
        this.audioLibraryService.createOrUpdateFolder(folder).subscribe(res => {
            this.loadScheduleCollections();
            this.addFolderPopoverCtrl.closeActionPopover();
        });
    }

    buildDeleteAPIErrorHandler(responseCode: number): (error) => void {
        return (error) => {
            if (error.status === 403 && error.hasOwnProperty('_body')) {
                const errorBody = JSON.parse(error._body);
                if (errorBody.responseCode === responseCode) {
                    // this.alert.error(errorBody.responseData);
                    this.alert.error(`Schedule in use. Can't delete.`)
                }
            } else if (error.status === 400) {
                // 'Item is not known'
                this.alert.error(`Something went wrong. Please refresh the atmosphere web player.`)
            } else {
                this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            }
        }
    }

    onDelScheduleConfirm(schedule: Schedule) {
        const delSchedulePromise = this.audioLibraryService.deleteSchedule(schedule).toPromise();
        delSchedulePromise.then(res => {
            this.loadScheduleCollections();
        }).catch(this.buildDeleteAPIErrorHandler(311)).finally(
            () => { this.delPopoverCtrl.closeActionPopover(); }
        );
    }

    onDelScheduleCancel(ev: any) {
        this.delPopoverCtrl.closeActionPopover();
    }

    onDelScheduleFolderCancel(ev: any) {
        this.delFolderPopoverCtrl.closeActionPopover();
    }

    onDelScheduleFolderConfirm(folder: AMPFolder) {
        this.audioLibraryService.deleteFolder(folder).toPromise().then(res => {
            this.delFolderPopoverCtrl.closeActionPopover();
            this.scheduleFolders = res;
            return this.loadSchedules();
        }).then(res => {
            this.schedules = res;
            this.processScheduleCollections(this.scheduleFolders, this.schedules);
        })
    }

    onMoveScheduleToFolder(schedule) {
        this.audioLibraryService.createOrUpdateSchedule(schedule).subscribe(res => {
            this.loadScheduleCollections();
        })
    }

    onEditFolderCancel(ev: any) {
        this.editFolderPopoverCtrl.closeActionPopover();
    }

    onEditFolderSubmit(folder: AMPFolder) {
        this.audioLibraryService.createOrUpdateFolder(folder).subscribe(res => {
            this.editFolderPopoverCtrl.closeActionPopover();
        });
    }
}

