export class PlaylistFilter {

    public static AMP_FILTER_TYPE_ARTIST = 1;
    public static AMP_FILTER_TYPE_ALBUM = 2;
    public static AMP_FILTER_TYPE_SERIES = 3;
    public static AMP_FILTER_TYPE_CHANNEL = 4;
    public static AMP_FILTER_TYPE_ATTRIBUTE = 5;
    public static AMP_FILTER_TYPE_CATEGORY = 6;
    public static AMP_FILTER_TYPE_PLAYLIST = 7;

    constructor(public id: number,
                public playlistId: number,
                public filterType: number,
                public filterIdentifier: string,
                public filterIdentifierAsString: string
                ) {
    }
}
