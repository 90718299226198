import {Component, Input} from '@angular/core';
import {ListItem} from './models/list-item';
import {MasterViewListItemEventDelegate} from './interfaces/master-view-list-item-event-delegate';
import {ListItemIconConfig, ListItemIconConfigStates} from './models/list-item-config';

@Component({
    selector: 'app-master-view-list-item',
    templateUrl: './master-view-list-item.component.html',
    styleUrls: ['./master-view-list-item.component.scss']
})
export class MasterViewListItemComponent {
    @Input() listItem: ListItem;
    @Input() index: number;
    @Input() nestedDepth: number;
    @Input() eventDelegate: MasterViewListItemEventDelegate;

    listItemIsCollpaseOpen() {
        return this.listItem.hasNestedItems() && this.listItem.isSelected();
    }

    listItemOnClick(event) {
        event.nestedDepth = this.nestedDepth;
        this.eventDelegate.listItemSelected(event, this.listItem);
    }

    getListItemStyleState() {
        const listItemStyles = [];
        listItemStyles.push((this.listItemIsCollpaseOpen()) ? 'master-view-list__list-item--collapse-open' : '');
        listItemStyles.push((!this.listItem.hasNestedItems() && this.listItem.isSelected()) ? 'master-view-list__list-item--selected' : '');
        listItemStyles.push((!this.listItem.hasNestedItems() && !this.listItem.isSelected() && this.nestedDepth > 1) ? 'master-view-list__list-item--not-selected' : '');
        listItemStyles.push(!(this.index === 0 && this.nestedDepth === 1) ? 'master-view-list__list-item--margin-top' : '');
        return listItemStyles.join(' ');
    }

    getLeftIcon() {
        let leftIcon;
        if (this.listItem.hasNestedItems()) {
            leftIcon = this.listItem.getIcons().leftHeading;
        } else {
            leftIcon = this.listItem.getIcons().left;
        }
        return leftIcon;
    }

    getListItemPrefixIcon() {
        const leftIcon = this.getLeftIcon();
        // tslint:disable-next-line:max-line-length
        return (this.listItem.isSelected() && !this.listItem.hasNestedItems()) ? leftIcon.getAssetForIconState(ListItemIconConfigStates.SELECTED) : leftIcon.getAssetForIconState(ListItemIconConfigStates.NORMAL);
    }

    getIconClassName() {
        const leftIcon = this.getLeftIcon();
        // tslint:disable-next-line:max-line-length
        return (this.listItem.isSelected() && !this.listItem.hasNestedItems()) ? leftIcon.getClassName(ListItemIconConfigStates.SELECTED) : leftIcon.getClassName(ListItemIconConfigStates.NORMAL);
    }

    isListItemPrefixImage() {
        const leftIcon = this.getLeftIcon();
        return leftIcon.isImage();
    }

    isListItemPrefixIcon() {
        const leftIcon = this.getLeftIcon();
        return leftIcon.isIcon();
    }

    getListItemPostfixIcon() {
        let postFixIcon = '';

        if (this.listItemIsCollpaseOpen()) {
            postFixIcon = 'fa-chevron-up';
        } else if (this.listItem.hasNestedItems()) {
            postFixIcon = 'fa-chevron-down';
        } else if (this.listItem.isSelected()) {
            postFixIcon = 'fa-chevron-right';
        }

        return postFixIcon;
    }

    getListItemDepthPadding() {
        return {'padding-left': `${this.nestedDepth * 28}px`}
    }

    getNextNestedDepth() {
        return this.nestedDepth + 1;
    }

    getListItemContainerStyleState() {
        return (this.nestedDepth === 1) ? 'master-view-list__list-item__container--lg' : '';
    }

    getNestedContainerStyleState() {
        return (this.nestedDepth === 1) ? 'master-view-list__list-item__nested-container--scrolling' : '';
    }
}
