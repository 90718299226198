import {ApplicationRef, Component, OnInit} from '@angular/core';
import {Licence} from "../../_models/licence";
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Installation} from "../../_models/installation";
import {ResellerLicences} from "../../_models/reseller-licences";
import {Account} from '../../_models/account';

@Component({
  selector: 'client-tabs',
  templateUrl: './client-tabs.component.html',
  styleUrls: ['./client-tabs.component.scss']
})

export class ClientTabsComponent implements OnInit {

  isLoading = false;
  hasAudioLibrary = true;

  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true;
    /*
      this.accountService.fetchAccount()
          .finally(() => {
              this.isLoading = false;
          })
          .subscribe((account: Account) => {
              for (const cat of account.contentCategoryTypes) {
                  if (cat == 2) {
                      this.hasAudioLibrary = true;
                  }
                }
              },
              error => {
                  if (error.status == 0) {
                      this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
                      return;
                  }
                  let response = JSON.parse(error.text());
                  this.alert.error(response.responseData);
              });*/
  }

  public isTrialAccount() : boolean
  {
    var type: string = localStorage.getItem("accountType");
    var accountType = parseInt(type);
    return ((accountType & 65536) > 0);
  }

  public isTrialAccount1() : boolean
  {
    return true;
  }

  public hasAudioProduct() : boolean
  {
      return this.hasAudioLibrary;
  }

}
