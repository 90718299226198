import {ApplicationRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Order} from "../../../_models/order";
import {OrderLicence} from "../../../_models/order-licence";
import Util, {LicenceStatus} from "../../../Util";
import {Venue} from "../../../_models/venue";
import {OrderListComponent} from "../order-list/order-list.component";
import {LicenceAssignComponent} from "../licence-assign/licence-assign.component";
import {OrderActionDelegate} from "../order-action.delegate";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})

export class OrderDetailComponent implements OnInit, OnChanges, OrderActionDelegate  {

  public pageSize = 10;
  public page = 1;
  public selectedLicence : OrderLicence;
  public selectedForExpiry : OrderLicence;
  public highlightedLicence: OrderLicence;
  public editingOrder : boolean = false;

  @Input()
  public order: Order;

  @Input()
  public highlightedLicenceIndex: number;

  @Input()
  delegate: OrderActionDelegate;


  constructor(
    public orderList: OrderListComponent,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.selectedLicence = null;
  }



  ngOnChanges()
  {
    if (this.highlightedLicenceIndex >= 0 && this.order != null) {
      let pageIndex : number = Math.floor(this.highlightedLicenceIndex / this.pageSize);
      this.page = pageIndex+1;
      this.highlightedLicence = this.order.licenses[this.highlightedLicenceIndex];
    }
  }



  public formatDescription (order : Order)
  {
    switch (order.renditionId)
    {
      case 1:
        return "4K4";
        break;
      case 2:
        return "4K1";
        break;
      case 3:
        return "UHD";
        break;
      case 4:
        return "HD";
        break;

    }
  }


  public getLicenceStatus (licence : OrderLicence) : string
  {
    var u = new Util();
    return u.licenceStatusDescriptor (licence.status);
  }

  public getVenueInstallation (licence : OrderLicence) : string
  {
    if (licence.installationId == 0)
    {
      if (licence.accountId > 0)
        return 'AM' + licence.accountId;
      return "unassigned";
    }

    for (let venue of this.orderList.venues)
    {
        for (let installation of venue.installations)
        {
          if (installation.id == licence.installationId)
            return 'AM' + licence.accountId + '/' + venue.name + '/' + installation.installationName;
        }
    }
  }


  public highlightLicence(orderLicence: OrderLicence) {
    this.highlightedLicence = orderLicence;
  }

  public highlightClass(order: Order): string {
    if (order == null || this.highlightedLicence == null)
      return "";
    if (order.idString === this.highlightedLicence.orderIdString)
      return "table-dark";
    return "";
  }


  public assignLicence (licence : OrderLicence) : boolean
  {
    this.selectedLicence = licence;
    return false;
  }


  public expireLicence (licence : OrderLicence) : boolean
  {
    if (licence.status != 4)  // Active
      return;
    this.selectedForExpiry = licence;
    this.selectedLicence = licence;
    return false;
  }


  public finished(reload: boolean)
  {
    this.selectedLicence = null;
    this.selectedForExpiry = null;
    this.editingOrder = false;
    this.delegate.finished(reload);
  }



  public unassignedLicenceSelected () : boolean
  {
    return this.selectedLicence != null && this.selectedLicence.installationId == 0;
  }



  public assignedLicenceSelected () : boolean
  {
    return this.selectedLicence != null && this.selectedLicence.installationId != 0;
  }


  public isActive(licence : OrderLicence) : boolean
  {
    return licence.status == LicenceStatus.Active;
  }

  public editOrder()
  {
    this.editingOrder = true;
  }
}

