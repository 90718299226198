import {ApplicationRef, Component, OnInit} from '@angular/core';
import {Account} from "../../_models/account";
import {AdminService} from "../../_services/admin.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Installation} from "../../_models/installation";
import {BusinessDetailsFormParent} from "../../business-details-form/business-details-form.parent";
import {Business} from "../../_models/business";
import {Observable} from "rxjs";

@Component({
  selector: 'admin-resellers',
  templateUrl: './admin-resellers.component.html',
  styleUrls: ['./admin-resellers.component.scss']
})

export class AdminResellersComponent implements OnInit, BusinessDetailsFormParent  {

  editingAccount: boolean = false;
  account: Account = null;
  resellers: Account[];
  installations: Installation[] = [];
  isLoading = false;


  constructor(private adminService: AdminService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true
    this.adminService.fetchResellers()
      .subscribe((resellers: Account[]) => {
          this.initialiseFromResellers(resellers);
        },
        error => {
          if (error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          let response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });
  }





  public addReseller() {
    this.account = null;
    this.editingAccount = true;
  }

  public editReseller(reseller: Account) {
    this.editingAccount = true;
    this.account = reseller;
  }


  private initialiseFromResellers(resellers: Account[]) {
    this.resellers = resellers;
  }








  public loadAccount() {
    return false;
  }

  public finished() {
    if (this.editingAccount)
    {
      this.editingAccount = false;
      this.ngOnInit();
      return;
    }
    this.editingAccount = false;
    this.account = null;
  }

  public updateEmail(): boolean {
    return false;
  }

  public getAccount() : Account
  {
    if (this.account != null)
      return this.account;

    return new Account(
      0,
      8,
      2,
      "",
      "",
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      0,
      "",
      "",
      "",
      "",
      "",
      "");

  }





  public saveBusiness(business: Business): Observable<any>
  {
    if (this.account != null)
      business.organisationId = this.account.id;
    return this.adminService.updateResellerDetails(business);
  }

}

