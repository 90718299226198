import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import DelPlaylistConfirmDelegate from './del-playlist-folder-confirm.delegate';
import {Playlist} from '../../_models/playlist';
import DelPlaylistFolderConfirmDelegate from './del-playlist-folder-confirm.delegate';
import {AMPFolder} from '../../_models/folder';
import {PlaylistFolderListItemModel} from '../util/playlist-listitem-factory';

@Component({
    selector: 'amp-del-playlist-folder-confirm',
    templateUrl: './del-playlist-folder-confirm.component.html',
    styleUrls: ['./del-playlist-folder-confirm.component.scss']
})
export class DelPlaylistFolderConfirmComponent {

    private _folderItemModel;

    @Input() eventDelegate: DelPlaylistFolderConfirmDelegate;
    @Input()
    set folderItemModel(value: PlaylistFolderListItemModel) {
        if (value.playlists && value.folder) {
            this._folderItemModel = value;
        } else {
            this._folderItemModel = {playlists: [], folder: {}}
        }

    }

    get folderItemModel() {
        return this._folderItemModel;
    }

    constructor(
    ) { }

    onCancel() {
        this.eventDelegate.onDelPlaylistFolderCancel({});
    }

    onConfirm() {
        // if (this.folderItemModel && this.folderItemModel.playlists.length > 0) { return; }
        this.eventDelegate.onDelPlaylistFolderConfirm(this._folderItemModel.folder);
    }

}
