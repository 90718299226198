import {ActionPopoverController} from './action-popover.parent';
export default class GenericActionPopoverController implements ActionPopoverController {

    private _isOpen;

    constructor() {
        this._isOpen = false;
    }

    closeActionPopover() {
        this._isOpen = false;
    }

    isActionPopoverOpen() {
        return this._isOpen;
    }

    openActionPopover() {
        this._isOpen = true;
    }

}
