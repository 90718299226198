
export enum LicenceStatus {
  Created = 1,
  Owned,
  AwaitingActivation,
  Active,
  Expired,
  AwaitingReactivation,
  ReactivationEnabled
};

export default class Util {


  public licenceStatusDescriptor(licenceStatus: Number): string {
    switch (licenceStatus) {
      case LicenceStatus.Created:
      case LicenceStatus.Owned:
        return "InActive";

      case LicenceStatus.AwaitingActivation:
        return "Awaiting Activation";

      case LicenceStatus.Active:
        return "Active";

      case LicenceStatus.Expired:
        return "Expired";

      case LicenceStatus.AwaitingReactivation:
        return "Active, re-activation enabled";

      case LicenceStatus.ReactivationEnabled:
        return "Expired, re-activation enabled";

      default:
        return "Undefined";
    }
  }


  public millisToMinutesAndSeconds(duration: number) {
      /*
      https://stackoverflow.com/questions/19700283/how-to-convert-time-in-milliseconds-to-hours-min-sec-format-in-javascript
       */
      const milliseconds = Math.floor((duration % 1000) / 100),
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      const hoursStr = (hours < 10) ? '0' + hours : hours;
      const minutesStr = (minutes < 10) ? '0' + minutes : minutes;
      const secondsStr = (seconds < 10) ? '0' + seconds : seconds;

      let timeStr = `${minutesStr}:${secondsStr}`; //.${milliseconds}
      if (hoursStr !== '00') {
          timeStr = `${hoursStr}:${timeStr}`
      }
      return timeStr;
      // return new Date(millis).toISOString().substr(11, 8);
  }

}
