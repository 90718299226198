import {IPlaylistFilterView} from './iplaylist-filter-view';
import {ListItem} from '../../../common-components/master-view-list/models/list-item';
import {Category} from '../../../_models/category';
import {buildTreeObjToListMap, GenericTreeListItemFactory} from '../../../video-library/util/generic-listitem-factories';
import {ListItemConfig} from '../../../common-components/master-view-list/models/list-item-config';
import ListItemIconConfigFactory from '../../../common-components/master-view-list/util/list-item-config-factory';
import {PlaylistFilter} from '../../../_models/playlist-filter';

export class CategoryPlaylistFilterView implements IPlaylistFilterView<Category> {

    private _categories = new Array<Category>();
    private _categoriesMap = {};
    private _dropdownItems = new Array<ListItem>();

    buildDefaultFilter(playlistId): PlaylistFilter {
        return new PlaylistFilter(0, playlistId, this.getFilterId(), this._categories[0].idAsString, this._categories[0].idAsString);
    }

    getDropdownItems(): Array<ListItem> {
        return this._dropdownItems;
    }

    getFilterDisplayString(): string {
        return 'Category';
    }

    getFilterId(): number {
        return PlaylistFilter.AMP_FILTER_TYPE_CATEGORY;
    }

    getFilterImageIcoAsset(): string {
        return '../../assets/img/icons_ic_sequence_uncolored.png';
    }

    getReducedFilterContentOption(filterContent: string): { contentId: string; name: string } {
        const result = {contentId: '', name: ''};

        if (this._categoriesMap[filterContent]) {
            result.contentId = this._categoriesMap[filterContent].idAsString;
            result.name = this._categoriesMap[filterContent].name;
        }

        return result;
    }

    handleFilterUpdate(filter: PlaylistFilter, filterContent: Category) {
        filter.filterIdentifier = filterContent.idAsString;
        filter.filterIdentifierAsString = filterContent.idAsString;
    }

    isFilterIconAssetFont(): boolean {
        return false;
    }

    isFilterIconAssetImg(): boolean {
        return true;
    }

    setFilterContent(content: Array<Category>): void {
        this._categories = content;

        content.forEach(category => {
            this._categoriesMap[category.idAsString] = category;
        });


        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftIcoConf = listItemIconConfigFactory.buildListItemIcoIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            'sequence-ico'
        );
        const listConfig = new ListItemConfig({
            leftHeading: leftIcoConf,
            left: leftIcoConf
        });

        this._dropdownItems = content.map(buildTreeObjToListMap(listConfig, 'children'));
    }

    getFilterIconAssetClassName() {
        return '';
    }

}
