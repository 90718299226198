import { Component, OnInit } from '@angular/core';
import { Licence } from '../../_models/licence';
import { Installation } from '../../_models/installation';
import { AlertService } from "../../_alert/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from '../../_services/account.service';
import { LicenceStatus } from "../../Util";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../modal/modal.component';
import { VenueModalComponent, VenueModalMode } from '../venue-modal/venue-modal.component';
import { Account } from "../../_models/account";
import { Schedule } from '../../_models/schedule';
import { AudioLibraryService } from '../../_services/audio-library.service';
import { Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { DialogFormSubmitListener } from 'app/common-components/dialogs/modal-dialog-form.parent';
import { Business } from 'app/_models/business';
import { Observable } from 'rxjs';
import {ProductService} from '../../_services/product.service';
import {EditModeController, GenericEditModeController} from '../../common-components/edit-mode/edit-mode.controller';
import {DashboardDetailDelegate} from './dashboard-detail.delegate';
import {FLOATING_ITEM_ID, processFolderMap} from '../../common-components/util/common-collections-process';
import {
    ScheduleFolderListItemFactory,
    ScheduleFolderListItemModel,
    SchedulesListItemFactory
} from '../../schedule/util/schedule-listitem-factory';
import ListItemIconConfigFactory from '../../common-components/master-view-list/util/list-item-config-factory';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';
import {ProductFilterService} from '../../_services/product-filter.service';

@Component({
  selector: 'amp-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  styleUrls: ['./dashboard-detail.component.scss'],
})

export class DashboardDetailComponent implements OnInit, DialogFormSubmitListener, DashboardDetailDelegate {

  licences: Licence[];
  installations: Installation[] = [];
  isLoading = false;
  installationsMap = {};
  accountSchedules: Schedule[] = [];
  installationSchedule: Map<Number, Schedule>;
  accountScheduleMap: Map<Number, Schedule>;
  venues: Account[];
  venueMap = {};
  venueNames: String[];
  addingVenue = false;
  lastEditedInstallation: Installation;

  installationEditModeController: EditModeController<Installation>;

  products = [];
  scheduleList = [];
  venueCollapseState = {};

  constructor(
    private app: AppComponent,
    private accountService: AccountService,
    private audioLibraryService: AudioLibraryService,
    private productService: ProductService,
    private productFilterService: ProductFilterService,
    private alert: AlertService,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal) {
    this.installationSchedule = new Map<Number, Schedule>();
    this.accountScheduleMap = new Map<Number, Schedule>();
    this.installationEditModeController = new GenericEditModeController<Installation>(
        (obj) => this.saveInstEditMode(obj),
        (obj) => this.cancelInstEditMode(obj)
    );
  }

    getScheduleFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(3).toPromise();
    }

  saveInstEditMode(editedObject: Installation) {
      this.accountService.updateInstallationSchedule(editedObject.id, editedObject.scheduleId).subscribe(res => {
      });
  }

  cancelInstEditMode(cachedObject: Installation) {
      if (this.lastEditedInstallation) {
          this.lastEditedInstallation.scheduleId = cachedObject.scheduleId;
      }
    this.lastEditedInstallation = null;
  }


  ngOnInit(): void {
    this.getScheduleFolders().then(folders => {
        this.audioLibraryService.fetchSchedules().subscribe(res => {
            this.accountSchedules = res;
            this.accountScheduleMap = new Map<Number, Schedule>();
            for (const s of this.accountSchedules) {
                this.accountScheduleMap[s.id] = s;
            }
            this.scheduleList = this.processScheduleCollections(folders, this.accountSchedules);
        });

        this.productFilterService.loadProducts().then(() => {
            this.products = this.productFilterService.getProducts();
            //this.selectedProducts = this.productFilterService.getProductIdsForMediaType(this.mediaType);
        })
    });

    this.accountService.fetchGroupVenues().
      subscribe((venues: Account[]) => {
        this.processVenues(venues);
      },
        error => {
          if (error.status === 0) {
            this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            return;
          }
          const response = JSON.parse(error.text());
          this.alert.error(response.responseData);

        });
  }
    buildListItemForFolder(folder, schedules) {
        const scheduleFolderListFactory = new ScheduleFolderListItemFactory(() => folder.folderName);
        const listItemIconConfigFactory = new ListItemIconConfigFactory();
        const leftHeadingIcoConf = listItemIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_folder.svg',
            '../../assets/img/icons_ic_folder.svg',
            {},
            'folder-ico'
        );

        const scheduleLIConf = new ListItemConfig({ leftHeading: leftHeadingIcoConf, left: this.getScheduleListItemIcoConf() });
        return scheduleFolderListFactory.buildListItem(new ScheduleFolderListItemModel(folder, schedules), scheduleLIConf)
    }


    getScheduleListItemIcoConf() {
        const schedulesListIconConfigFactory = new ListItemIconConfigFactory();
        return schedulesListIconConfigFactory.buildListItemImageIconConfig(
            '../../assets/img/icons_ic_date.png',
            '../../assets/img/icons_ic_date.png',
            {},
            'date-ico'
        );
    }

    buildListItemForSchedule(schedule) {
        const scheduleListItemFactory = new SchedulesListItemFactory();
        const icoConf = this.getScheduleListItemIcoConf();

        return scheduleListItemFactory.buildListItem(schedule, new ListItemConfig({ leftHeading: icoConf, left: icoConf }))
    }

    processScheduleCollections(scheduleFolders, schedules) {
        const list = [];
        const scheduleFolderMap = processFolderMap(scheduleFolders, schedules);

        scheduleFolders.forEach(folder => {
            list.push(this.buildListItemForFolder(folder, scheduleFolderMap[folder.id]));
        });

        scheduleFolderMap[FLOATING_ITEM_ID].forEach(schedule => {
            list.push(this.buildListItemForSchedule(schedule));
        });

        return list;
    }

  public getData() {
      // const productP = this.productService.fetchProducts().toPromise();
      // const licenceP = this.accountService.fetchLicences().toPromise();
      // const groupVP = this.accountService.fetchGroupVenues().toPromise();
      //
      // Promise.all([productP, licenceP, groupVP]).then(res => {
      //     console.log('product', res[0]);
      //     console.log('license', res[1]);
      //     console.log('grpvp', res[2]);
      // })
  }

  private processVenues(accounts: Account[]) {
    this.venues = [];
    for (const venue of accounts) {
      const v: Account = new Account(venue.id, venue.type, venue.status, venue.name, venue.addressLine1, venue.addressLine2, venue.city,
        venue.state, venue.country, venue.countryRecord, venue.postcode, venue.phone, venue.businessNumber, venue.parentId,
        venue.accountId, venue.validationHash, venue.accountPassword, venue.contactName, venue.contactEmail, venue.preferredLanguage);
      this.venueMap[venue.name] = v;
      this.venues.push(v);
    }
    this.accountService.fetchLicences()
      .subscribe((licences: Licence[]) => {
          console.log('Licence count', licences.length);
        this.extractInstallationsToVenues(licences);
        // this.extractVenues();
      },
        error => {
          if (error.status === 0) {
            this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
            return;
          }
          const response = JSON.parse(error.text());
          this.alert.error(response.responseData);
        });

  }

  private extractInstallationsToVenues(licences: Licence[]) {
    for (const l of licences) {
      if (l.installationId === 0) {
        continue;
      }
      const venue: Account = this.venueMap[l.venueName];
      if (venue === undefined) {
        continue;
      }
      const installation = new Installation(l.installationId, l.installationName, venue.id, '', l.scheduleId);
      venue.addInstallation(installation);
      installation.addLicence(l);
      if (installation.scheduleId !== undefined && installation.scheduleId > 0) {
        this.installationSchedule[installation.id] = this.accountScheduleMap[installation.scheduleId];
      }
    }
  }


  private setLicenceSelectionState(licences: Licence[], installations: Installation[]) {
    for (var i = 0; i < licences.length; i++) {
      var l = licences[i];
      if (l.installationId !== 0) {
        l.selectedInstallation = this.installationsMap[l.installationName];
      }
      l.disabled = l.licenceStatus >= 4;
    }
  }

  private extractVenues(): any {
    this.venueNames = Array.from(new Set(this.licences.map(({ venueName }) => venueName)));
    const index = this.venueNames.indexOf('');
    if (index !== -1) {
      this.venueNames.splice(index, 1);
    }
  }


  setLicenseStatusButtonColor(installation: Installation) {
    const licenceBtnClass = {
      'in-active': false,
      'cls-awaiting-activation': false,
      'cls-is-active': false,
      'cls-has-expired': false,
      'cls-active-re-activation-enabled': false,
      'cls-expired-re-activation-enabled': false,
      'cls-undefined-status': false
    };

    switch (installation.licenceStatus) {
      case LicenceStatus.Created:
      case LicenceStatus.Owned:
        licenceBtnClass['in-active'] = true;
        break;
      case LicenceStatus.AwaitingActivation:
        licenceBtnClass['cls-awaiting-activation'] = true;
        break;
      case LicenceStatus.Active:
        licenceBtnClass['cls-is-active'] = true;
        break;
      case LicenceStatus.Expired:
        licenceBtnClass['cls-has-expired'] = true;
        break;
      case LicenceStatus.ReactivationEnabled:
        licenceBtnClass['cls-active-re-activation-enabled'] = true;
        break;
      default:
        licenceBtnClass['cls-undefined-status'] = true;
        break;
    }

    return licenceBtnClass

  }

  open(event, installation: Installation) {
    if (!event.isDropDownToggleEvent) {
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.name = 'device details';
      modalRef.componentInstance.installation = installation;
      const productCodes = installation.getLicences().map(l => l.productName);
      modalRef.componentInstance.products = this.products.filter(p => {
            return productCodes.includes(p.name);
      });
      modalRef.componentInstance.dashboardDelegate = this;
      modalRef.result.finally(() => {
          if (this.isInInstEditMode()) {
              console.log(installation);
              this.cancelInstEdit(installation);
              this.productFilterService.cancel();
          }
      })
    }
  }

  public updateInstallationSchedule(installation, schedule) {
    // this.installationSchedule[installation.id] = schedule;
      installation.scheduleId = schedule.id;
    // this.accountService.updateInstallationSchedule(installation.id, schedule.id).subscribe(res => {
    //   console.log(installation.id, res);
    //   installation.scheduleId = schedule.id;
    // });
  }

  public clearInstallationSchedule(installation) {
    console.log('clearInstallationSchedule');
    this.installationSchedule[installation.id] = 0;
    installation.scheduleId = 0;
    // this.accountService.updateInstallationSchedule(installation.id, 0).subscribe(res => {
    //   console.log(installation.id, res);
    //   installation.scheduleId = 0;
    // });
  }

    updateInstallations(venue) {
         this.accountService.updateSchedules(venue.id)
            .subscribe((c) => {
                },
                error => {
                    if (error.status === 0) {
                        this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
                        return;
                    }
                    const response = JSON.parse(error.text());
                    this.alert.error(response.responseData);
                });

    }

  addVenue() {
    const modalRef = this.modalService.open(VenueModalComponent);
    modalRef.componentInstance.countries = this.app.countries;
    modalRef.componentInstance.formListener = this;
    modalRef.componentInstance.modalMode = VenueModalMode.ADD;
    modalRef.componentInstance.app = this.app;

  }

  editVenue(account: Account) {
    console.log("edit");
    const modalRef = this.modalService.open(VenueModalComponent);
    modalRef.componentInstance.countries = this.app.countries;
    modalRef.componentInstance.formListener = this;
    modalRef.componentInstance.modalMode = VenueModalMode.EDIT;
    modalRef.componentInstance.account = account;
    modalRef.componentInstance.app = this.app;


  }

  viewVenue(account: Account) {
    console.log("viewVenue");
    const modalRef = this.modalService.open(VenueModalComponent);
    modalRef.componentInstance.countries = this.app.countries;
    modalRef.componentInstance.formListener = this;
    modalRef.componentInstance.modalMode = VenueModalMode.INFO;
    modalRef.componentInstance.account = account;
    modalRef.componentInstance.app = this.app;
  }

  toggleVenueCollapse(account: Account) {
      console.log("toggleVenueCollapse");
      const vId = account.id;
      if (this.venueCollapseState.hasOwnProperty(vId)) {
          this.venueCollapseState[vId] = !this.venueCollapseState[vId];
      } else if (account.installations.length > 0) {
          this.venueCollapseState[vId] = true;
      }
  }

  onSubmit(business: Business, modalMode: VenueModalMode): Observable<any> {
    return this.accountService.updateAccountVenue(business);
  }


  getScheduleNameById(scheduleId): string {
    // console.log(this.accountSchedules, scheduleId);
    if (scheduleId !== undefined && scheduleId > 0) {
      const s: Schedule = this.accountScheduleMap[scheduleId];
      // const s = this.accountSchedules.find(schedule => { schedule.id === scheduleId });
      if (s !== undefined) {
        return s.name;
      }
    }
    return '';
  }

  getAccSchedules() {
      return this.accountSchedules;
  }

  isInInstEditMode(): boolean {
        return this.installationEditModeController.isInEditMode();
    }

   cancelInstEdit(installation) {
      this.lastEditedInstallation = installation;
      this.installationEditModeController.cancelEditMode();
   }

    startInstEdit(installation) {
        this.installationEditModeController.startEditMode(installation);
    }

    saveInstEdit(installation) {
      this.installationEditModeController.saveEditMode(installation);
    }

    getAccSchedulesList() {
      return this.scheduleList;
    }

    getProductDisplayName(product) {
        const productTranslation = product.translations.find(translation => translation.language === this.accountService.getLanguage());
        return (productTranslation) ? productTranslation.displayName : product.name;
    }

    getProductImage(product) {
      return this.productService.posterImage(product.name);
    }

    isActiveProduct(product) {
        return this.productFilterService.isActiveProduct(product);
        // return this.selectedProducts.find(p => p === product.contentBundleId);
    }

    selectProductFilter(product) {
        this.productFilterService.addProductFilter(product);
        // if (this.isActiveProduct(product)) {
        //     this.selectedProducts = this.selectedProducts.filter(p => p !== product.contentBundleId)
        // } else {
        //     this.selectedProducts.push(product.contentBundleId);
        // }
    }

    applyFilters() {
      this.productFilterService.apply();
    }

}

