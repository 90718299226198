import {IPlaylistFilterView} from './iplaylist-filter-view';
import {PlaylistFilter} from '../../../_models/playlist-filter';
import {ListItem} from '../../../common-components/master-view-list/models/list-item';
import {buildTreeObjToListMap, GenericTreeListItemFactory} from '../../../video-library/util/generic-listitem-factories';
import ListItemIconConfigFactory from '../../../common-components/master-view-list/util/list-item-config-factory';
import {ListItemConfig} from '../../../common-components/master-view-list/models/list-item-config';


export class VideoSharedModelFilterView implements IPlaylistFilterView<any> {

    private _contentModel = [];
    private _contentMap = {};
    private _dropdownItems = new Array<ListItem>();


    private readonly _filterId;
    private readonly _displayString;
    private readonly _isImageAsset;
    private readonly _imageIcoAsset;
    private readonly _icoClassName;

    private readonly _childrenKey;

    constructor(filterId: number, displayString: string, isImageAsset: boolean, imageIcoAsset: string, childrenKey: string, icoClassName: string) {
        this._filterId = filterId;
        this._displayString = displayString;

        this._isImageAsset = imageIcoAsset;
        this._imageIcoAsset = imageIcoAsset;
        this._icoClassName = icoClassName;

        this._childrenKey = childrenKey;
    }

    buildDefaultFilter(playlistId): PlaylistFilter {
        return new PlaylistFilter(0, playlistId, this.getFilterId(), this._contentModel[0].id, this._contentModel[0].id);
    }

    getDropdownItems(): Array<ListItem> {
        return this._dropdownItems;
    }

    getFilterDisplayString(): string {
        return this._displayString;
    }

    getFilterId(): number {
        return this._filterId;
    }

    getFilterImageIcoAsset(): string {
        return this._imageIcoAsset;
    }

    getReducedFilterContentOption(filterContent: string): { contentId: string; name: string } {
        const result = {contentId: '', name: ''};

        if (this._contentMap[filterContent]) {
            result.contentId = this._contentMap[filterContent].id;
            result.name = this._contentMap[filterContent].name;
        }

        return result;
    }

    handleFilterUpdate(filter: PlaylistFilter, filterContent: any) {
        filter.filterIdentifier = filterContent.id;
        filter.filterIdentifierAsString = filterContent.id;
    }

    isFilterIconAssetFont(): boolean {
        return !this._isImageAsset;
    }

    isFilterIconAssetImg(): boolean {
        return this._isImageAsset;
    }

    setFilterContent(content: Array<any>): void {
        this._contentModel = content;

        const contentMapLoop = (child => {
            this._contentMap[child.id] = child;
            if (child.hasOwnProperty(this._childrenKey) && child[this._childrenKey].length > 0) {
                child[this._childrenKey].forEach(contentMapLoop);
            }
        });

        content.forEach(contentMapLoop);

        const listItemIconConfigFactory = new ListItemIconConfigFactory();

        const leftIcoConf = listItemIconConfigFactory.buildListItemIcoIconConfig(
            this.getFilterImageIcoAsset(),
            this.getFilterImageIcoAsset(),
            {},
            this._icoClassName
        );
        const listConfig = new ListItemConfig({
            leftHeading: leftIcoConf,
            left: leftIcoConf
        });

        this._dropdownItems = content.map(buildTreeObjToListMap(listConfig, this._childrenKey));
    }

    getFilterIconAssetClassName() {
        return '';
    }
}
