import {Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationService } from "../../_services/validation.service";
import {AMPFolder} from '../../_models/folder';
import {AddPlaylistFolderFormDelegate} from './add-playlist-folder-form.delegate';

@Component({
    selector: 'amp-add-playlist-folder-form',
    templateUrl: './add-playlist-folder-form.component.html',
    styleUrls: ['./add-playlist-folder-form.component.scss']
})
export class AddPlaylistFolderFormComponent implements OnInit {

    @Input() eventDelegate: AddPlaylistFolderFormDelegate;

    @Input() folderTypeForSelectedTab: number;

    form: FormGroup;

    constructor(
        private validation: ValidationService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            folderName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]]
        });
    }

    close() {
        this.eventDelegate.onAddPlaylistFolderCancel({});
    }

    onSubmit(form) {
        this.eventDelegate.onAddPlaylistFolderSubmit(new AMPFolder(
            0,
            form.folderName,
            this.folderTypeForSelectedTab
        ));
    }
}
