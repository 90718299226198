import { SequenceAction} from './sequence-action';

export class ProgramSequence {
  id: number;
  name: string;
  type: number;
  programmeId: number;
  actions: Array<SequenceAction> = [];
  constructor(id: number, name: string, type: number, programmeId: number, actions: Array<SequenceAction>) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.programmeId = programmeId;
    this.actions = actions;
  }

}
