import {ApplicationRef, Component, OnInit} from '@angular/core';
import {AccountService} from "../../_services/account.service";
import {AlertService} from "../../_alert/alert.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'client-home',
  styleUrls: ['../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',  './client-home.component.scss'],
  templateUrl: './client-home.component.html'
})
export class ClientHomeComponent implements OnInit {

  isLoading = false;

  constructor(private accountService: AccountService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.isLoading = true
  }


  public isTrialAccount() : boolean
  {
    var type: string = localStorage.getItem("accountType");
    var accountType = parseInt(type);
    return ((accountType & 65536) > 0);
  }

  public isTrialAccount1() : boolean
  {
    return true;
  }


}
