import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { ValidationService } from "../../_services/validation.service";
import { Playlist } from 'app/_models/playlist';
import { SequenceAction } from 'app/_models/sequence-action';
import { formatDate } from '@angular/common';
import { ProgrammeModalListener } from '../programme-detail/programme-detail.component';
import { ListItem } from '../../common-components/master-view-list/models/list-item';

const TIME_FORMAT_24 = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/
const TIME_FORMAT_24_NO_S = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/
@Component({
  selector: 'amp-add-new-event-modal',
  templateUrl: './add-new-event-modal.component.html',
  styleUrls: ['./add-new-event-modal.component.scss']
})
export class AddNewEventModalComponent implements OnInit {

  public modalType: ActionType;
  public formListener: ProgrammeModalListener;
  public eventTypes: Object[] = [
    { Text: 'Audio Event', ColumnId: 1, Color: '#357cd2' },
    { Text: 'Video Event', ColumnId: 2, Color: '#1aaa55' },
    // { Text: 'Action Event', ColumnId: 3, Color: '#7fa900' }
  ];
  public form: FormGroup;
  public startTime: Date;
  public endTime: Date;
  public selectedEventType: any;
  public selectedPlaylist: Playlist;
  public programmeName: string;
  public sequenceAction: SequenceAction;
  public newEventId: number;
  public hourOffset: number;
  public audioPlaylistListItems: Array<ListItem>;
  public videoPlaylistListItems: Array<ListItem>;
  public eventType: number;

  get actionTypes() { return ActionType }

  constructor(
    private validation: ValidationService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
  ) {
    this.form = this.formBuilder.group({
        timeStarts: ["", [Validators.required]],
        timeEnds: ["", [Validators.required]],
        checkShuffle: [false],
        checkNetworkMode: [false],
        checkMuteMode: [false]
    });
  }

  ngOnInit(): void {
    this.selectedEventType = this.eventTypes[(this.eventType - 1)];
    if (this.sequenceAction == undefined) {
      // new mode
      this.modalType = ActionType.NEW;
      this.sequenceAction = new SequenceAction();
      this.form.controls['timeStarts'].setValue(formatDate(this.startTime, "HH:mm:ss", "en-IE"));
      this.form.controls['timeEnds'].setValue(formatDate(this.endTime, "HH:mm:ss", "en-IE"));
    } else {
      // edit mode
      this.modalType = ActionType.EDIT;
      document.getElementById("labelTitle").innerHTML = "Edit Event";
      document.getElementById("submit-btn").innerHTML = "Save";
      const secondsOffset = this.hourOffset * 3600;
      this.form.controls['timeStarts'].setValue(this.secondsToHms(this.sequenceAction.startSeconds + secondsOffset));
      this.form.controls['timeEnds'].setValue(this.secondsToHms(this.sequenceAction.startSeconds + secondsOffset + this.sequenceAction.duration));
      this.form.controls['checkShuffle'].setValue(this.sequenceAction.shuffle);
      if (this.selectedEventType.ColumnId === 2) {
          this.form.controls['checkNetworkMode'].setValue(this.sequenceAction.networkMode);
          this.form.controls['checkMuteMode'].setValue(this.sequenceAction.mute);
      }
    }
  }

  displayEndTime() {
    var displayDate;

    if (this.modalType == ActionType.NEW) {
      displayDate = new Date(this.endTime);
      const secs = displayDate.getSeconds();
      if (secs == 59) {
        displayDate.setSeconds(displayDate.getSeconds() + 1);
      }

      return formatDate(displayDate, "HH:mm:ss", "en-IE");
    } else {
      const secondsOffset = this.hourOffset * 3600;
      displayDate = new Date((this.sequenceAction.startSeconds + secondsOffset + this.sequenceAction.duration) * 1000);

      const hmsTime = this.secondsToHms(this.sequenceAction.startSeconds + secondsOffset + this.sequenceAction.duration);
      const hmsArray = hmsTime.split(':');
      if (hmsArray[2] === "59" && hmsArray[0] !== "23") {
        const hmsSec = "00:00:01";
        const result = this.addTimes(hmsTime, hmsSec)
        return result;
      } else {
        return hmsTime;
      }
    }
  }

  close() {
    this.activeModal.close(this);
  }


  delete() {
    this.formListener.onSubmit(this.sequenceAction, ActionType.DELETE, 0, 0, 0);
    this.close();
  }

  selectEventType(eventTypeId) {
    this.selectedEventType = this.eventTypes[(eventTypeId - 1)];
    this.eventType = eventTypeId;
  }

  selectPlaylist(playlist: Playlist) {
    this.selectedPlaylist = playlist;
  }


  onSubmit(form) {
    if (this.selectedPlaylist == undefined) {
      alert("Playlist required");
      return;
    }
    if (this.isInputTimeValid(form.timeStarts) && this.isInputTimeValid(form.timeEnds)) {

      const startSeconds = this.adjustTime(form.timeStarts);
      const endSeconds = this.subtract1SecondFromFullMinuteEvent(this.adjustTime(form.timeEnds));

      if (startSeconds == endSeconds) {
        alert("Start time and end time cannot be the same");
      } else if (endSeconds < startSeconds) {
        alert("End time cannot be before the start time");
      } else if ((endSeconds - startSeconds) <= 0) {
        alert("Event too short");
      } else if (this.eventType == this.selectedPlaylist.mediaType) {
        // check if playlist type matches event type playlist.type=2(AUDIO), playlist.type=1(VIDEO)
        console.log("this.formListener.onSubmit", this.eventType, this.selectedPlaylist.mediaType);
        alert("Playlist type must match event type");
      } else {

        if (this.sequenceAction.id == undefined) {
          this.sequenceAction.id = this.newEventId;
        }

        this.sequenceAction.name = this.selectedPlaylist.name;
        this.sequenceAction.type = this.eventType;
        this.sequenceAction.identifier = this.selectedPlaylist.id.toString();
        this.sequenceAction.shuffle = form.checkShuffle;
        if (this.selectedEventType.ColumnId === 2) {
            this.sequenceAction.networkMode = form.checkNetworkMode;
            this.sequenceAction.mute = form.checkMuteMode;
        }

        const duration = endSeconds - startSeconds;

        this.formListener.onSubmit(this.sequenceAction, this.modalType, startSeconds, duration, this.selectedEventType.ColumnId);
        this.close();
      }

    } else {
      alert("Times must follow the following format HH:mm:ss");
    }
  }

  areTimesValid(startSeconds, endSeconds) {
    if (endSeconds <= endSeconds) {
      return false;
    }
    return true;
  }

  isInputTimeValid(timeString): boolean {
    if (!timeString.match(TIME_FORMAT_24) && !timeString.match(TIME_FORMAT_24_NO_S))
      return false;

    return true;
  }

  hmsToSecondsOnly(str): number {
    var p = str.split(':');
    var s = 0;
    var m = 1;
    if (p.length < 3) {
      m = 60;
    }

    while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
    }
    return s;
  }

  secondsToHms(seconds): string {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  }

  adjustTime(hms: string) {
    var seconds = this.hmsToSecondsOnly(hms);
    seconds -= this.hourOffset * 3600;

    if (seconds < 0) {
      seconds = 86400 + seconds;
    }

    return seconds;
  }

  subtract1SecondFromFullMinuteEvent(seconds) {
    const hmsTime = this.secondsToHms(seconds);
    const hmsArray = hmsTime.split(':');
    if (hmsArray[2] === "00") {
      seconds -= 1;
    }
    return seconds;
  }


  /**
  * Add two string time values (HH:mm:ss) with javascript
  *
  * Usage:
  *  > addTimes('04:20:10', '21:15:10');
  *  > "25:35:20"
  *  > addTimes('04:35:10', '21:35:10');
  *  > "26:10:20"
  *  > addTimes('30:59', '17:10');
  *  > "48:09:00"
  *  > addTimes('19:30:00', '00:30:00');
  *  > "20:00:00"
  *
  * @param {String} startTime  String time format
  * @param {String} endTime  String time format
  * @returns {String}
  */
  addTimes(startTime, endTime) {
    var times = [0, 0, 0]
    var max = times.length

    var a = (startTime || '').split(':')
    var b = (endTime || '').split(':')

    // normalize time values
    for (var i = 0; i < max; i++) {
      a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
      b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
    }

    // store time values
    for (var i = 0; i < max; i++) {
      times[i] = a[i] + b[i]
    }

    var hours = times[0]
    var minutes = times[1]
    var seconds = times[2]

    if (seconds >= 60) {
      var m = (seconds / 60) << 0
      minutes += m
      seconds -= 60 * m
    }

    if (minutes >= 60) {
      var h = (minutes / 60) << 0
      hours += h
      minutes -= 60 * h
    }

    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
  }

  handlePlaylistDropdownClick(dropdownItem) {
    this.selectPlaylist(dropdownItem.getInnerModel());
  }
}


export enum ActionType {
  NEW,
  EDIT,
  DELETE
}
