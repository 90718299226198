import {ListItemFactory} from '../../common-components/master-view-list/interfaces/list-item.factory';
import {ListItemConfig} from '../../common-components/master-view-list/models/list-item-config';
import {List, ListItem} from '../../common-components/master-view-list/models/list-item';
import {Tag} from '../../_models/tag';

const buildTagListItemMap = (config: ListItemConfig) => {
    return (childTag: Tag) => {
        return new ListItem(config, () => childTag.name, childTag);
    };
};

export class TagListItemFactory implements  ListItemFactory<Tag> {

    buildMapFromFactory(config: ListItemConfig) {
        return (childTag: Tag) => {
            let listItem;

            if (childTag.children.length > 0) {
                listItem = this.buildListItem(childTag, config);
            } else {
                listItem = buildTagListItemMap(config)(childTag);
            }

            return listItem;
        }
    }

    buildListItem(model: Tag, config: ListItemConfig): ListItem {
        return new List(config, () => model.name, model, model.children.map(this.buildMapFromFactory(config)))
    }
}


