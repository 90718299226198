import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AMPFolder} from '../../_models/folder';
import {Playlist} from '../../_models/playlist';
import {AudioLibraryService} from '../../_services/audio-library.service';
import {AlertService} from '../../_alert/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {PlaylistActionMenuDelegate} from './playlist-action-menu.delegate';

@Component({
    selector: 'amp-playlist-action-menu',
    templateUrl: './playlist-action-menu.component.html',
    styleUrls: ['./playlist-action-menu.component.scss']
})
export class PlaylistActionMenuComponent {

    @Input() playlist: Playlist;
    @Input() audioPlaylistFolders: Array<AMPFolder>;
    @Input() videoPlaylistFolders: Array<AMPFolder>;
    @Input() delegate: PlaylistActionMenuDelegate;

    form: FormGroup;

    constructor(
    ) { }

    getPlaylistFolders() {
        return (this.playlist.mediaType === 1) ? this.videoPlaylistFolders : this.audioPlaylistFolders;
    }

    moveToFolder(folder: AMPFolder) {
        this.playlist.folderId = folder.id;

        this.delegate.onMovePlaylistToFolder(this.playlist);
    }

    copyPlaylist(playlist, playlistType) {
        this.delegate.onCopyPlaylist(playlist, playlistType);
    }
}
