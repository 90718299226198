import {Component, Input} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'amp-btn-popover',
  templateUrl: './button-popover.component.html',
  styleUrls: ['./button-popover.component.scss']
})
export class ButtonPopoverComponent {
  @Input()contentId: string;
  profileForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
  });

}
