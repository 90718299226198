import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService} from "../_services/account.service";
import {AlertService} from "../_alert/alert.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../_services/validation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'amp-login',
  styleUrls: ['../../../node_modules/@ng-select/ng-select/themes/default.theme.css',  './login.component.scss'],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  isLoading = false;

  returnUrl: string;
  fromReg: false;
  fromReset: false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private account: AccountService,
              private validation: ValidationService,
              private alert: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {

    // reset login status
    this.account.logout();

    this.form = this.formBuilder.group({
      accountIdentifier: ["", [Validators.required, Validators.minLength(7), this.validation.accountIdValidator(), Validators.maxLength(12)]],
      password: ["", [Validators.required, Validators.minLength(7), Validators.maxLength(12)]],
    });

    this.fromReg = this.route.snapshot.queryParams["fromReg"];
    this.fromReset = this.route.snapshot.queryParams["fromReset"];
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
  }

  onSubmit(form) {
    this.isLoading = true;
    this.account.login(form.accountIdentifier, form.password)
      .finally(() => {
        this.isLoading = false;
      })
      .subscribe(
        response => {
          // alert("GO:" + this.returnUrl);
          this.router.navigate([this.returnUrl]);
        },
        error => {
          if(error.status == 0) {
            this.alert.error(this.translate.instant("unable-to-connect-to-the-server"));
            return;
          }
          // let r = JSON.parse(error);
          // if(r.responseCode == 106) {
          //   this.alert.error(this.translate.instant("invalid-account-identifier-or-password"));
          // }
          // else {
          //   this.alert.error(r.responseData);
          // }
        });
  }

  isAccountIdentifierInvalid() {
    return this.form.get('accountIdentifier').invalid && this.form.get('accountIdentifier').touched
  }

  isPasswordInvalid() {
    return this.form.get('password').invalid && this.form.get('password').touched;
  }

  isInvalidIdentifier() {
    return this.form.get('accountIdentifier').hasError('invalidIdentifier') && this.form.get('accountIdentifier').touched;
  }

}
