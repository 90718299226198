import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ListItem} from '../master-view-list/models/list-item';
import {ListItemIconConfigStates} from '../master-view-list/models/list-item-config';

@Component({
    selector: 'app-dropdown-list-item',
    templateUrl: './dropdown-list-item.component.html',
    styleUrls: ['./dropdown-list-item.component.scss']
})
export class DropdownListItemComponent {

    @Input() listItem: ListItem;
    @Input() index: number;
    @Input() nestedDepth: number;

    @Input() searchable: boolean;
    @Input() searchFilterFunc: () => {};

    @Output() listItemSelected: EventEmitter<ListItem> = new EventEmitter<ListItem>();

    visibleNestedItems() {
        let nestedItems;

        if (this.searchable) {
            nestedItems = this.listItem.getNestedItems().filter(this.searchFilterFunc);
        } else {
            nestedItems = this.listItem.getNestedItems();
        }

        return nestedItems;
    }

    getIcoImageAsset(icoAsset) {
        return this.listItem.getIcons()[icoAsset].getAssetForIconState(ListItemIconConfigStates.NORMAL)
    }

    handleDropDownHeaderOnClick(event) {
        event.preventDefault();
        if (this.listItem.getNestedItems().length > 0) {
            this.listItem.setSelected(!this.listItem.isSelected());
        }
    }

    handleDropDownItemSelected(event) {
        this.listItemSelected.emit(event);
    }

    getNextNestedDepth() {
        return this.nestedDepth + 1;
    }
}
