import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountService} from '../_services/account.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RenditionService} from '../_services/rendition.service';
import {ProductFilterService} from '../_services/product-filter.service';
import {ProductService} from '../_services/product.service';


@Component({
    selector: 'amp-product-filter',
    templateUrl: './product-filter.component.html',
    styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent implements OnInit {


    @Input() mediaType: number;
    @Output() selectedProductFilter: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelProductSelect: EventEmitter<any> = new EventEmitter<any>();

    isVisible = false;
    products: Array<{
        name: string,
        contentBundleId: any,
    }> = [];
    selectedProducts = [];

    constructor(
        private productService: ProductService,
        private productFilterService: ProductFilterService,
        private account: AccountService,
        private renditions: RenditionService
    ) {
    }

    ngOnInit(): void {
        this.getProducts();
    }

    getDisplayName(product) {
        const productTranslation = product.translations.find(translation => translation.language === this.account.getLanguage());
        return (productTranslation) ? productTranslation.displayName : product.name;
    }

    getProductImage(product) {
        return this.productService.thumbnailImage(product.name);
    }

    getProducts() {
        this.productFilterService.loadProducts()
            .then(() => {
                this.products = this.productFilterService.getProductsForMediaType(this.mediaType);
                this.selectedProducts = this.productFilterService.getProductIdsForMediaType(this.mediaType);
            })
            .then(() => {
                if (this.selectedProducts.length > 0) {
                    this.selectedProductFilter.emit(this.selectedProducts);
                }
            })
    }

    isActiveProduct(product) {
        return this.productFilterService.isActiveProduct(product);
    }

    public selectProductFilter(product) {
        this.productFilterService.addProductFilter(product);
        this.selectedProducts = this.productFilterService.getProductIdsForMediaType(this.mediaType);
    }

    public handleApply() {
        this.selectedProductFilter.emit(this.selectedProducts);
        this.cancelProductSelect.emit();
        this.productFilterService.apply();
        this.toggleVisibility();
    }

    public getButtonToggleDisplay() {
        return (this.isVisible) ? 'Hide' : 'Products';
    }

    public hide() {
        this.cancelProductSelect.emit();
        this.productFilterService.cancel();
        this.toggleVisibility();
    }

    public toggleVisibility() {
        this.isVisible = !this.isVisible;
    }
}
