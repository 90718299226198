import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { AudioLibraryService } from '../_services/audio-library.service';
import { Playlist } from '../_models/playlist';
import { AlertService } from '../_alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Installation } from '../_models/installation';
import Util from '../Util';
import { LibraryModalDelegate } from 'app/audio-library/library-action-menu/library-action-menu.component';
import {DashboardDetailDelegate} from '../dashboard/dashboard-detail/dashboard-detail.delegate';
import {Subject} from 'rxjs';

@Component({
  selector: 'amp-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() public name;
  @Input() public contentItems: [any];
  @Input() public mediaType: number;
  @Input() public playlistType: number;

  private dropdownEventsSubject: Subject<any> = new Subject<any>();

  public products: [];
  public installation: Installation;
  public delegate: LibraryModalDelegate;
  public dashboardDelegate: DashboardDetailDelegate;

    AddProgramForm = new FormGroup({
    programName: new FormControl(''),
  });

  ScheduleForm = new FormGroup({
    scheduleName: new FormControl(''),
    scheduleDuration: new FormControl('Choose the duration')
  });

  CreatePlaylistForm = new FormGroup({
    PlaylistName: new FormControl(''),
  });
  constructor(public activeModal: NgbActiveModal,
    public audioLibraryService: AudioLibraryService,
    public alert: AlertService,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }
  createPlaylist() {
    const items = [];
    for (const ci of this.contentItems) {
      items.push(ci.sIdentifier);
    }
    const newPlaylist = new Playlist(0, this.CreatePlaylistForm.controls['PlaylistName'].value, this.mediaType,
      this.playlistType, 0, [], items, 0, false, undefined, 1);

    this.audioLibraryService.addOrUpdateContentToPlaylist(newPlaylist).subscribe(res => {
      // alert('Succesfully added playlist');
      if (this.delegate != undefined) {
        this.delegate.success();
      }
      this.activeModal.close('Success');
    }, error => {
      if (error.status === 0) {
        this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
        return;
      }
      const response = JSON.parse(error.text());
      this.alert.error(response.responseData);
    })
  }
  showFormValue() {
    return this.AddProgramForm;
  }


  getInstallationStatus(): string {
    const u = new Util();
    return u.licenceStatusDescriptor(this.installation.licenceStatus);
  }

    handleScheduleDropdownClick(listItem) {
      this.dashboardDelegate.updateInstallationSchedule(this.installation, listItem.getInnerModel());
    }

    handleSchedClear() {
      this.dropdownEventsSubject.next('close');
      this.dashboardDelegate.clearInstallationSchedule(this.installation);
    }

    applyProducts() {
      this.dashboardDelegate.applyFilters();
      this.activeModal.close(this);
    }

  close() {
    this.activeModal.close(this);
  }
}
