import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MasterViewListComponent} from './master-view-list/master-view-list.component';
import {MasterViewListItemComponent} from './master-view-list/master-view-list-item.component';
import {MasterViewToolbarComponent} from './master-view-toolbar/master-view-toolbar.component';
import {ModalDialogComponent} from './dialogs/modal-dialog.component';
import {ActionPopoverComponent} from './popovers/action-popover.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {EditModeActionsComponent} from './edit-mode/edit-mode-actions.component';
import {BidiModule} from '@angular/cdk/bidi';

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        BidiModule
    ],
    exports: [
        MasterViewListComponent,
        MasterViewToolbarComponent,
        ModalDialogComponent,
        ActionPopoverComponent,
        EditModeActionsComponent
    ],
    declarations: [
        // Master View List
        MasterViewListComponent,
        MasterViewListItemComponent,
        // Master View Toolbar
        MasterViewToolbarComponent,
        // Dialogs
        ModalDialogComponent,
        // Popovers,
        ActionPopoverComponent,
        // Edit Mode
        EditModeActionsComponent
    ]
})
export class CommonComponentsModule { }
